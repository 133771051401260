import { CASE_NOUN } from 'constants'

const NOUN_ENDING = {
  [CASE_NOUN.dative]: 'у',
}

/**
 * Функция возвращает в строке профессию врача
 * @param {Boolean} isMedic
 * @param {String=} formNoun - падеж существительного, если не указан, то именительный
 * @return {String}
 */
function getProfession(isMedic, formNoun) {
  const ending = NOUN_ENDING[formNoun] || ''

  return isMedic ? `врач${ending}` : `специалист${ending}`
}

export default getProfession
