<template>
  <div>
    <component
      :is="LayoutComponent"
      v-show="isVisibleAuthorizationComponent"
      :is-visible="isVisible"
    >
      <component
        :is="UserAuthorizationComponent"
        ref="user-authorization-component"
        v-bind="{ ...$attrs, ...$props }"
        v-on="{ ...$listeners }"
      />
    </component>
  </div>
</template>

<script>
import { scrollLock } from 'utils'

export default {
  name: 'UserAuthorization',
  components: {
    UserAuthorizationByUserCall: () => import(/* webpackMode: "eager" */'components/common/UserAuthorization/components/UserAuthorizationByUserCall'),
    UserAuthorizationByCallOrSms: () => import(/* webpackMode: "eager" */'components/common/UserAuthorization/components/UserAuthorizationByCallOrSms'),
    UserAuthorizationModalLayout: () => import(/* webpackMode: "eager" */'components/common/UserAuthorization/components/common/UserAuthorizationModalLayout'),
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isRenderInModal: {
      type: Boolean,
      default: false,
    },
    isAuthorizationByCall: {
      type: Boolean,
      default: false,
    },
    isCountryAuthorization: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    LayoutComponent() {
      return this.isRenderInModal ? 'UserAuthorizationModalLayout' : 'div'
    },
    UserAuthorizationComponent() {
      return this.isAuthorizationByCall ? 'UserAuthorizationByUserCall' : 'UserAuthorizationByCallOrSms'
    },
    isVisibleAuthorizationComponent() {
      return !this.isRenderInModal
        || (this.isRenderInModal && this.isVisible)
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      async handler(isVisible) {
        this.changeBodyScrollLock(isVisible)
      },
    },
  },
  methods: {
    reset() {
      this.$refs['user-authorization-component'].$refs['authorization-form'].reset()
    },
    resetWithoutPhone() {
      this.$refs['user-authorization-component'].$refs['authorization-form'].resetWithoutPhone()
    },
    changeBodyScrollLock(isLock) {
      if (!navigator.cookieEnabled) {
        return
      }

      /**
             * Старая либа используется для того, чтобы можно было вручную проскроллиться до компонента
             * авторизации в bottomsheet на мобилке, после того как её перекроет виртуальная клавиатура
             * (данная проблема появлялась через раз на iphone 7 (IOS 15.8))
             * */
      if (isLock) {
        scrollLock.enable()
      } else {
        scrollLock.disable()
      }
    },
  },
}
</script>
