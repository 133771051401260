import { getGoalsList } from 'utils'

const MOBILE_CLUB_YANDEX_GOALS = getGoalsList({
  block: 'mobileClub',
  goals: [
    'appointmentNoticeShow', // Предложение записаться(bottom sheet) при клике на номер телефона
    'appointmentNoticeGoToApp', // Предложение записаться(bottom sheet), клик по кнопке 'записаться'
    'appointmentNoticeCall', // Предложение записаться(bottom sheet), клик по кнопке 'позвонить'
  ],
})

export default MOBILE_CLUB_YANDEX_GOALS
