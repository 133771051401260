import { REGEX } from 'utils/regex'

/**
 * @description
 *
 * Функция возвращает объект для валидации поля "password"
 *
 * @return { Object } - объект функций с валидацией поля по различным параметрам. Каждая функция принимает обязательное значение поля - value
 * */

const PASSWORD_LENGTH = {
  max: 32,
  min: 8,
}

const PASSWORD_MESSAGE = {
  minLength: `От ${PASSWORD_LENGTH.min} символов`,
  maxLength: `Должно быть не больше ${PASSWORD_LENGTH.max} символов`,
  onlyLettersAndDigits: `От ${PASSWORD_LENGTH.min} символов с хотя бы одной цифрой и одной заглавной буквой, только латиница`,
}

function getPasswordValidation() {
  return {
    required: val => !!val || 'Введите пароль',
    minLength: val => val.length >= PASSWORD_LENGTH.min || PASSWORD_MESSAGE.minLength,
    maxLength: val => val.length <= PASSWORD_LENGTH.max || PASSWORD_MESSAGE.maxLength,
    onlyLettersAndDigits: val => (
      /[A-Z]/.test(val) && /\d/.test(val) && !REGEX.invalidPassword.test(val)
    ) || PASSWORD_MESSAGE.onlyLettersAndDigits,
  }
}

export default getPasswordValidation
