var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "authorization-form" },
    [
      _c(
        "VForm",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "authorization-form__field" },
            [
              _c("VTextField", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.phoneField.theMask,
                    expression: "phoneField.theMask",
                  },
                ],
                ref: "phone-field-input",
                attrs: {
                  loading: _vm.phoneField.isLoading,
                  autofocus: _vm.isAutofocusField,
                  disabled: _vm.isPhoneDisabled,
                  "error-messages": _vm.errorMessage,
                  "hide-details": _vm.phoneField.isConfirmed,
                  "data-qa": "user_authorization_phone_input",
                  outlined: "",
                  type: "tel",
                  label: "Номер телефона",
                },
                on: {
                  input: _vm.handlePhoneInput,
                  focus: _vm.handlePhoneFocus,
                  blur: _vm.handlePhoneBlur,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.phoneField.isConfirmed
                      ? {
                          key: "append",
                          fn: function () {
                            return [
                              _c("div", {
                                staticClass:
                                  "ui-icon-check ui-kit-color-success",
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.phoneField.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.phoneField, "value", $$v)
                  },
                  expression: "phoneField.value",
                },
              }),
              _vm._v(" "),
              _vm.isVisiblePen
                ? _c("div", {
                    staticClass: "authorization-form__pen ui-icon-pen",
                    attrs: { "data-qa": "user_authorization_change_phone" },
                    on: { click: _vm.handleClickEditPhone },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isVisibleDefaultSlot ||
                  (_vm.isAuthorizationVk && _vm.isDefaultSlotAlwaysVisible),
                expression:
                  "isVisibleDefaultSlot || isAuthorizationVk && isDefaultSlotAlwaysVisible",
              },
            ],
            class: {
              "authorization-form__default-slot": true,
              "authorization-form__default-slot_disabled":
                _vm.phoneField.isLoading,
            },
          },
          [
            _vm._t("default", null, null, {
              isActiveSlot:
                _vm.isVisibleDefaultSlot && !_vm.phoneField.isLoading,
              isCodeInputVisible:
                _vm.isVisibleDefaultSlot || !_vm.isAuthorizationVk,
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.isVisibleActionSlot
        ? _vm._t("action", null, null, {
            onSubmitForm: _vm.onSubmitForm,
            loading: _vm.phoneField.isLoading,
            disabled: _vm.isDisabledAction,
            isVisible: !_vm.isVisibleDefaultSlot,
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }