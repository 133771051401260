import Sockets from 'modules/Sockets'
import { HOOKS } from 'modules/Sockets/constants'
import {
  AJAX_TIMEOUT,
  AJAX_METHODS,
} from 'modules/ServerListener/constants'

import {
  deepMerge,
  axiosClient,
  getCSRFToken,
  isEmptyObject,
  createDevNotice,
  testRequiredOptions,
} from 'utils'

class ServerListener {
  socketInstance = null

  isCanceledAxiosConnection = false

  constructor(options = {}) {
    this._createOptions(options)

    const testOptions = this._testRequiredOptions()

    if (!testOptions) {
      return
    }

    this._callSocketChain()
    this._callRequestChain()
  }

  disconnect() {
    this.isCanceledAxiosConnection = true

    if (this.socketInstance) {
      this.socketInstance.close()
    }
  }

  _callSocketChain() {
    this.socketInstance = new Sockets({
      url: this.options.socketData.url,
      messageHandler: ({ messageParsed, message }) => {
        this.options.socketData.receiveCallback({
          data: !isEmptyObject(messageParsed) ? messageParsed : message,
        })
      },
      hooks: {
        [HOOKS.errorConnection]: this.options.socketData.errorCallback,
      },
    })
  }

  _callRequestChain = () => {
    const {
      url,
      data,
      method,
      timeout,
      errorCallback,
      successCallback,
    } = this.options.requestData

    if (this.isCanceledAxiosConnection || !url) {
      return
    }

    axiosClient({
      url,
      data,
      method,
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(res => {
      successCallback({ data: res.data })

      setTimeout(() => {
        this._callRequestChain()
      }, timeout)

      return null
    }).catch(errorData => {
      createDevNotice({
        module: 'ServerListener',
        description: errorData.message,
      })

      errorCallback(errorData)
    })
  }

  _testRequiredOptions() {
    const { url } = this.options.socketData

    return testRequiredOptions({
      module: 'ServerListener',
      requiredOptions: {
        url,
      },
    })
  }

  _createOptions(options) {
    this.options = deepMerge({
      socketData: {
        url: null,
        errorCallback: () => {},
        receiveCallback: () => {},
      },
      requestData: {
        url: null,
        data: {},
        timeout: AJAX_TIMEOUT,
        method: AJAX_METHODS.get,
        errorCallback: () => {},
        successCallback: () => {},
      },
    }, options)
  }
}

export default ServerListener
