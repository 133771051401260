import axios from 'axios'
import Sockets from 'modules/Sockets'
import AuthService from 'services/AuthService'
import { getWsUri } from 'blocksMixins/auth'
import { createDevNotice, dispatchCustomEventGlobally } from 'utils'
import { showCommonErrorMessage } from 'components/mobile/PhoneConfirmation/functions'
import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'
import {
  requestCallConfirmationStart,
  requestCallConfirmationFinish,
  cancelRequestCallConfirmationFinish,
} from 'components/common/UserAuthorization/api'
import {
  loopAsyncRequest,
} from 'components/common/UserAuthorization/functions'
import {
  CALL_CREATE_REQUEST_ERRORS,
  DIALOG_ERROR_NETWORK_CONFIG,
} from 'components/common/UserAuthorization/constants'

/**
 * В данном миксине происходит обработка всех запросов на
 * подтверждение телефона звонком от пользователя
 * */

const authByUserCallSendFormMixin = {
  data: () => ({
    requestSendFormData: {
      uuid: '',
      phone: '',
      socketInstance: null,
      cancelLoopRequest: null,
    },
  }),
  methods: {
    handleCreateRequest({ phone }) {
      this.requestSendFormData.phone = phone

      this.removeListeners()

      return new Promise((resolve, reject) => {
        requestCallConfirmationStart({ phone })
          .then(response => { this.handleSuccessCreateRequest(response); resolve() })
          .catch(errorEvent => { this.handleErrorRequest(errorEvent); reject() })
      })
    },
    handleSuccessCreateRequest(response) {
      this.uuid = response.data.uuid

      this.addListeners()
    },
    addListeners() {
      this.createListenerAjax()
      this.createListenerWebSockets()
    },
    removeListeners() {
      if (this.requestSendFormData.socketInstance) {
        this.requestSendFormData.socketInstance.close()
        this.requestSendFormData.socketInstance = null
      }

      if (this.requestSendFormData.cancelLoopRequest) {
        this.requestSendFormData.cancelLoopRequest()
        this.requestSendFormData.cancelLoopRequest = null
      }

      cancelRequestCallConfirmationFinish()
    },
    createListenerAjax() {
      this.requestSendFormData.cancelLoopRequest = loopAsyncRequest(async () => {
        await this.handleFinishRequest({
          uuid: this.uuid,
          source: 'timer',
        })
      }, {
        timeout: 5 * 1000, // 5 сек.
        lifeTime: 15 * 60 * 1000, // 15 мин.
        hookAfterExpire: () => {
          this.removeListeners()
          this.$refs['authorization-form'].resetWithoutPhone()
        },
      })
    },
    createListenerWebSockets() {
      this.requestSendFormData.socketInstance = new Sockets({
        url: getWsUri({ uuid: this.uuid }),
        messageHandler: async () => {
          await this.handleFinishRequest({
            uuid: this.uuid,
            source: 'ws',
          })
        },
      })
    },
    async handleFinishRequest({ uuid, source }) {
      try {
        const response = await requestCallConfirmationFinish({
          uuid,
          source,
          phone: this.requestSendFormData.phone,
        })

        this.removeListeners()

        await this.handleUserLogin({
          source,
          authCode: response.data.authorization_code,
        })
      } catch (e) {
        if (source === 'timer') {
          return
        }

        this.removeListeners()

        /**
                 * Если запрос отменил пользователь (cancelRequestCallConfirmationFinish),
                 * то не обрабатываем это как ошибку
                 * */
        if (axios.isCancel(e)) {
          return
        }

        this.handleErrorRequest(e)
      }
    },
    async handleUserLogin({ source, authCode }) {
      try {
        await AuthService.login({
          source,
          authCode,
        })

        this.$emit('authorization:confirmed', this.requestSendFormData.phone)
        this.$emit('authorization:just-confirmed')
      } catch (e) {
        this.$refs['authorization-form'].resetWithoutPhone()

        if (!window.navigator.onLine || e.message === 'Network Error') {
          dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
            maxWidth: 304,
            persistent: true,
            noClickAnimation: true,
            title: 'Нет интернета',
            text: 'Проверьте соединение и обновите страницу.',
            confirmText: 'Обновить',
            beforeConfirm: () => window.location.reload(),
          })

          return
        }

        createDevNotice({
          module: 'authByUserCallSendFormMixin.js',
          method: 'handleUserLogin',
          description: `Что-то пошло не так. Описание ошибки: ${JSON.stringify(e?.response)}`,
        })

        dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
          maxWidth: 304,
          persistent: true,
          noClickAnimation: true,
          title: 'Что-то пошло не так',
          text: 'Попробуйте немного подождать и обновите страницу.',
          confirmText: 'Обновить',
          beforeConfirm: () => window.location.reload(),
        })
      }
    },
    handleErrorRequest(errorEvent) {
      const { response, message } = errorEvent || {}
      const errorData = response?.data?.phone
      const errorCode = errorData?.code

      this.$refs['authorization-form'].resetWithoutPhone()

      if (errorCode === CALL_CREATE_REQUEST_ERRORS.userHasPhone.code) {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
          maxWidth: 304,
          title: CALL_CREATE_REQUEST_ERRORS.userHasPhone.message,
          closeText: 'Закрыть',
          confirmText: 'Обновить',
          beforeConfirm: () => window.location.reload(),
        })

        return
      }

      if (errorCode === CALL_CREATE_REQUEST_ERRORS.invalid.code) {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
          maxWidth: 304,
          title: CALL_CREATE_REQUEST_ERRORS.invalid.message,
          closeText: 'Закрыть',
        })

        return
      }

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      createDevNotice({
        module: 'authByUserCallSendFormMixin.js',
        method: 'handleErrorRequest',
        description: message,
      })

      showCommonErrorMessage()
    },
  },
}

export default authByUserCallSendFormMixin
