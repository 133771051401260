import { createDevNotice } from 'utils'
import AuthService from 'services/AuthService'
import {
  isExceededLoginAttempt,
  resetLoginAttemptStorage,
  updateLoginAttemptStorage,
} from 'www/global.js.blocks/common/auto-login/functions'

if (window.USER.isRealPatient || window.USER.isAnonymous) {
  (async () => {
    try {
      const response = await AuthService.pullAuth()
      const { authorization_code: authCode, uuid } = response.data

      if (authCode) {
        try {
          updateLoginAttemptStorage(uuid)

          if (isExceededLoginAttempt(uuid)) {
            return
          }
        } catch (error) {
          resetLoginAttemptStorage(uuid)

          // Если ошибка не связана с невалидными данными и `JSON`, то пробрасываем её дальше
          if (error.name !== 'SyntaxError' || error.message.indexOf('JSON') === -1) {
            throw error
          }
        }

        await AuthService.login({ authCode })
        window.location.reload()
      }

      // Если юзер залогинен на ПД и является пациентом, но не залогинен на МТ
      if (uuid === null && window.USER.isRealPatient) {
        try {
          const redirectResponse = await AuthService.redirectToMt()
          const { url } = redirectResponse.data

          await AuthService.loginAfterRedirect(url)
        } catch (e) {
          // Если интернета нет, то ошибка возникла из-за его отсутствия (такую ошибку логировать не нужно).
          if (!window.navigator.onLine || !e.status) {
            return
          }

          createDevNotice({
            mode: 'error',
            module: 'auto-login',
            method: 'async () -> AuthService.redirectToMt() && AuthService.loginAfterRedirect()',
            description: e.message,
          })
        }
      }
    } catch (e) {
      // Если интернета нет, то ошибка возникла из-за его отсутствия (такую ошибку логировать не нужно).
      if (!window.navigator.onLine || !e.status) {
        return
      }

      createDevNotice({
        mode: 'error',
        module: 'auto-login',
        description: `${e.message} | ${JSON.stringify(e.response?.data)}`,
      })
    }
  })()
}
