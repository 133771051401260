var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        _vm.LayoutComponent,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisibleAuthorizationComponent,
              expression: "isVisibleAuthorizationComponent",
            },
          ],
          tag: "component",
          attrs: { "is-visible": _vm.isVisible },
        },
        [
          _c(
            _vm.UserAuthorizationComponent,
            _vm._g(
              _vm._b(
                { ref: "user-authorization-component", tag: "component" },
                "component",
                { ..._vm.$attrs, ..._vm.$props },
                false
              ),
              { ..._vm.$listeners }
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }