<template>
  <div
    data-qa="card_getting_confirmation"
  >
    <div class="ui-text ui-text_h6 ui-kit-color-text">
      Получить клубную цену
    </div>
    <div class="ui-text ui-text_body-1 ui-kit-color-text mt-2 mb-6">
      Введите номер телефона — проверим,<br> в клубе ли вы
    </div>
    <UserAuthorization
      ref="user-authorization"
      v-on="{
        [events.userAuth.confirmed]: handleEventConfirmed,
        [events.userAuth.requested]: handleCodeRequestedOnce,
      }"
    />
    <VBtn
      text
      block
      class="mt-4"
      :data-qa="dataQaCancelButton"
      @click="handleClickButton"
    >
      {{ variationBtnText }}
    </VBtn>
  </div>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import UserAuthorization from 'components/common/UserAuthorization/UserAuthorization'
import {
  eventsData,
  getRedirectData,
} from 'components/common/Club/components/core/ClubCardGetting/interfaces'

import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import { CLUB_CARD_GETTING_YA_GOALS } from 'yandexGoals'

export default {
  name: 'ClubCardGettingConfirmation',
  components: {
    UserAuthorization,
  },
  mixins: [
    ManageYandexGoals,
  ],
  props: {
    redirectProps: {
      type: Object,
      default: getRedirectData,
    },
  },
  data: () => ({
    events: eventsData,
    isCodeRequested: false,
    yaGoalsList: CLUB_CARD_GETTING_YA_GOALS,
  }),
  computed: {
    variationBtnText() {
      return this.redirectProps.isRedirectViaBanner
        ? 'Отменить'
        : 'Пойду по обычной цене'
    },
    dataQaCancelButton() {
      return this.redirectProps.isRedirectViaBanner
        ? 'card_getting_confirmation_btn_cancel'
        : 'card_getting_confirmation_btn_go_default'
    },
  },
  methods: {
    handleEventConfirmed(userPhone) {
      this.sendYandexGoal('phoneConfirmed')
      this.$emit(this.events.userAuth.confirmed, userPhone)
      dispatchCustomEventGlobally(this.events.userAuth.confirmed, { userPhone })
    },
    handleCodeRequestedOnce() {
      if (!this.isCodeRequested) {
        this.isCodeRequested = true

        this.sendYandexGoal('phoneRequestCodeOnce')
      }
    },
    handleClickButton() {
      if (this.redirectProps.isRedirectViaBanner) {
        this.$refs['user-authorization'].reset()
      }

      this.$emit(this.events.club.cardGettingRedirectOrCancel)
    },
  },
}
</script>
