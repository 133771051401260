<template>
  <VAlert
    v-bind="$attrs"
    :color="alertColor"
    border="left"
    dense
    text
    v-on="$listeners"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <slot />
    <template #append>
      <slot name="append" />
    </template>
    <template #close="{ toggle }">
      <slot
        name="close"
        v-bind="{ toggle }"
      />
    </template>
  </VAlert>
</template>

<script>
export default {
  name: 'BaseAlert',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    typeColors: {
      success: 'uiKitTextSuccess',
      error: 'uiKitTextError',
      warning: 'uiKitTextWarning',
      info: 'uiKitTextSecondary',
      green: 'uiKitTextSuccess',
      red: 'uiKitTextError',
      yellow: 'uiKitTextWarning',
      grey: 'uiKitTextSecondary',
    },
  }),
  computed: {
    alertColor() {
      return this.typeColors[this.type]
                || this.color
                || this.typeColors.grey
    },
  },
}
</script>

<style lang="scss" scoped>
.v-alert::v-deep {
  margin-bottom: 0;

  .v-alert {
    &__content {
      line-height: initial;
    }

    &__wrapper > *:first-child {
      margin-top: -2px;
      margin-bottom: -2px;
      margin-right: 16px;
    }
  }
}

.v-alert--text::v-deep {
  &::before {
    opacity: 0.05;
  }

  .v-alert__border {
    &:not(.v-alert__border--has-color) {
      opacity: 0.4;
    }
  }
}
</style>
