<template>
  <div
    class="d-flex flex-direction-column align-items-center justify-content-center"
    data-qa="card_getting_preloader"
    :style="styleProps"
  >
    <VProgressCircular
      indeterminate
      :size="componentData.circleSize"
      :width="componentData.circleWidth"
      class="ui-text ui-kit-color-primary d-block mx-auto"
      data-qa="card_getting_preloader_progress"
    />
    <div
      v-if="useText"
      class="ui-text ui-text_body-1 ui-kit-color-text mt-6"
      data-qa="card_getting_preloader_text"
    >
      Проверяем...
    </div>
  </div>
</template>

<script>
import { getStyleData } from 'components/common/Club/components/core/ClubCardGetting/interfaces'

export default {
  name: 'ClubCardGettingPreloader',
  props: {
    styleProps: {
      type: Object,
      default: getStyleData,
    },
    useText: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    componentData: {
      circleWidth: window.MOBILE_VERSION ? 2 : 3,
      circleSize: window.MOBILE_VERSION ? 41 : 82,
    },
  }),
}
</script>
