<template>
  <div
    class="modal d-flex flex-column"
    :class="{ 'modal_has-footer': $slots.footer }"
  >
    <div
      class="modal__header uiKitText--text"
      :class="`px-${headerPaddingXStep}`"
    >
      <div
        v-if="backTo || closeTo"
        class="modal__header-button"
        @click="handleBack"
      >
        <VIcon
          v-if="backTo"
          color="uiKitText"
        >
          ui-icon-arrow-back
        </VIcon>
        <VIcon
          v-if="closeTo"
          color="uiKitText"
        >
          ui-icon-close-not-a-circle
        </VIcon>
      </div>
      <span
        v-if="title"
        :class="titleClassList"
      >
        {{ title }}
      </span>
      <slot name="titleIcon" />
    </div>

    <VDivider
      v-if="!hideDividers"
      class="modal__header-divider"
      :class="{ 'modal__header-divider_hidden': headerExtensionVisible }"
    />

    <div
      ref="body"
      v-scroll.self="onScroll"
      class="modal__body"
      :class="[
        classBody,
        {
          'modal__body_no-overflow-x': hideOverflowX,
          'modal__body_no-overflow-y': hideOverflowY,
          'modal__body_has-fixed-btn': hasFixedBtn,
          'modal__body_flex-basis-auto': bodyFlexBasisAuto,
          'modal__body_height-inherit': bodyHeightInherit,
        },
      ]"
    >
      <div
        v-if="$slots.header"
        class="modal__header-append"
      >
        <slot name="header" />
      </div>
      <VDivider
        v-if="!hideDividers"
        ref="divider"
        class="modal__header-divider"
        :class="{ 'modal__header-divider_hidden': visibleSingleDivider }"
      />

      <slot v-bind="{ bodyHeight }" />
    </div>

    <div
      v-if="$slots.footer"
      class="modal__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalLayout',
  props: {
    title: {
      type: String,
      default: null,
    },
    backTo: {
      type: Boolean,
      default: false,
    },
    closeTo: {
      type: Boolean,
      default: false,
    },
    limitTitle: {
      type: Boolean,
      default: false,
    },
    hideDividers: {
      type: Boolean,
      default: false,
    },
    visibleSingleDivider: {
      type: Boolean,
      default: false,
    },
    hideOverflowX: {
      type: Boolean,
      default: false,
    },
    hideOverflowY: {
      type: Boolean,
      default: false,
    },
    hasFixedBtn: {
      type: Boolean,
      default: false,
    },
    headerPaddingXStep: {
      type: Number,
      default: 2,
    },
    classBody: {
      type: String,
      default: '',
    },
    bodyFlexBasisAuto: {
      type: Boolean,
      default: false,
    },
    bodyHeightInherit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bodyHeight: 0,
      headerExtensionVisible: true,
    }
  },
  computed: {
    titleClassList() {
      const titleClassNamespace = ['ui-text', 'ui-text_h6']

      if (this.limitTitle) {
        titleClassNamespace.push('modal_limit-text')
      }

      return titleClassNamespace
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateBodyHeight()

      if (this.visibleSingleDivider) {
        this.headerExtensionVisible = false
      }
    })

    window.addEventListener('resize', this.updateBodyHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateBodyHeight)
  },
  methods: {
    handleBack() {
      this.$emit('modal:back')
    },
    updateBodyHeight() {
      this.bodyHeight = this.$refs.body.clientHeight
    },
    onScroll(e) {
      const bodyTop = e.target.getBoundingClientRect().top
      const dividerTop = this.$refs.divider.$el.getBoundingClientRect().top

      this.headerExtensionVisible = bodyTop < dividerTop
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.modal {
  background-color: white;
  min-height: 100%;

  &_limit-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &_has-footer {
    padding-bottom: 56px;
  }

  &__header {
    flex: 0 0 56px;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    background-color: white;
  }

  &__header-append {
    flex: 0 0 auto;
    margin: 0 0 8px;
    padding: 0 8px;
  }

  &__header-button {
    display: flex;  // нужно, чтобы иконка не съезжала при большом родительском 'font-size'
    width: 24px;
    height: 24px;
    margin: 0 32px 0 0;
    cursor: pointer;
  }

  &__header-divider_hidden {
    visibility: hidden;
  }

  &__body {
    flex: 1 0 0;
    overflow-y: auto;
  }

  &__body_flex-basis-auto {
    flex-basis: auto;
  }

  &__body_has-fixed-btn {
    // -1px от 72px, чтобы убрать нижнюю границу последнего элемента - (на margin не изменять - баг на iphone)
    padding-bottom: 71px;
    box-sizing: border-box;
  }

  &__body_no-overflow-x {
    overflow-x: hidden;
  }

  &__body_no-overflow-y {
    overflow-y: hidden;
  }

  &__body_height-inherit {
    height: inherit;
  }

  &__footer {
    height: 56px;
    padding: 0 8px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;

    @include elevation-8;
  }
}
</style>
