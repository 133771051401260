var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FullScreenDialog",
    {
      attrs: { value: _vm.value, title: _vm.title },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event)
        },
      },
    },
    _vm._l(_vm.projects, function (data, name) {
      return _c(
        "div",
        {
          key: name,
          staticClass: "mt-8",
          on: {
            click: function ($event) {
              return _vm.goToProject(data.url)
            },
          },
        },
        [
          _c("v-img", {
            attrs: {
              src: data.logo,
              height: "32",
              position: "left",
              contain: "",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "ui-text ui-text_caption ui-kit-color-text-info" },
            [_vm._v("\n      " + _vm._s(data.description) + "\n    ")]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }