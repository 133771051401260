const ClubAppointmentViewStates = {
  data: () => ({
    viewState: '',
    viewSteps: {
      payStart: 'paymentStart',
      appError: 'appointmentError',
      appProgress: 'appointmentProgress',
      appWithOffer: 'appointmentWithOffer',
      appWithoutOffer: 'appointmentWithoutOffer',
      paySuccess: 'succeeded',
      payFailed: 'failed',
    },
  }),
}

export default ClubAppointmentViewStates
