var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapperComponent.el,
    _vm._b(
      {
        tag: "component",
        model: {
          value: _vm.state.isEnabledWidget,
          callback: function ($$v) {
            _vm.$set(_vm.state, "isEnabledWidget", $$v)
          },
          expression: "state.isEnabledWidget",
        },
      },
      "component",
      _vm.wrapperComponent.props,
      false
    ),
    [
      _c(
        "VCard",
        {
          ref: "club-card-getting-body",
          staticClass: "px-4 pt-6 pb-4",
          attrs: { "data-qa": "club_card_getting" },
        },
        [
          _vm.state.step === _vm.steps.confirmation
            ? _c(
                "Confirmation",
                _vm._g(
                  { attrs: { "redirect-props": _vm.redirectData } },
                  {
                    [_vm.events.userAuth.confirmed]: _vm.handleEventConfirmed,
                    [_vm.events.club.cardGettingRedirectOrCancel]:
                      _vm.handleEventRedirectOrCancel,
                  }
                )
              )
            : _vm.state.step === _vm.steps.preloaderOnly ||
              _vm.state.step === _vm.steps.preloaderText
            ? _c("Preloader", {
                attrs: {
                  "style-props": _vm.styleData,
                  "use-text": _vm.state.step === _vm.steps.preloaderText,
                },
              })
            : _vm.state.step === _vm.steps.clubEnter
            ? _c(
                "ClubEnter",
                _vm._g(
                  { attrs: { "club-data": _vm.clubData } },
                  {
                    [_vm.events.club.cardGettingCancel]: _vm.closeWidget,
                    [_vm.events.club.cardGettingSubmit]:
                      _vm.handleCardGettingSubmit,
                  }
                )
              )
            : _vm.state.step === _vm.steps.emailEnter
            ? _c(
                "EmailEnter",
                _vm._g(
                  {},
                  {
                    [_vm.events.club.cardGettingEmailSent]:
                      _vm.handleEventEmailSent,
                  }
                )
              )
            : _vm.state.step === _vm.steps.paymentMade
            ? _c(
                "PaymentMade",
                _vm._g(
                  {
                    attrs: {
                      "club-data": _vm.clubData,
                      "user-phone": _vm.userPhone,
                      "component-props": _vm.paymentMadeData,
                    },
                  },
                  {
                    [_vm.events.club.cardGettingCancel]: _vm.closeWidget,
                    [_vm.events.club.cardGettingRedirect]:
                      _vm.redirectToNewAppointment,
                  }
                )
              )
            : _vm.state.step === _vm.steps.error
            ? _c(
                "Error",
                _vm._g(
                  { attrs: { "component-data": _vm.errorData } },
                  {
                    [_vm.events.club.cardGettingErrorSubmit]:
                      _vm.handleActionInError,
                    [_vm.events.club.cardGettingErrorCancel]:
                      _vm.handleActionInError,
                  }
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }