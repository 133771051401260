/**
 * @description
 *
 * Возвращает отступ элемента от левого края документа.
 *
 * @param { HTMLElement } el - элемент для вычисления
 *
 * @return { Number }
 */

function getElementOffsetLeft(el) {
  const rect = el.getBoundingClientRect()
  const docScrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const clientLeft = document.documentElement.clientLeft || 0

  return rect.left + docScrollLeft - clientLeft
}

export default getElementOffsetLeft
