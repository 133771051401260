/**
 * @description
 *
 * Последовательно вызываю каждую функция через нулевой таймаут с привязкой к контексту
 * Для вызова последовательной цепочки без нагрузки на основной поток
 * context - контекст функции, chain - массив с функциями. Элемент массива должен быть массивом, который содержит функцию и массив параметров.
 * Здесь нет и не может быть проверки на успешность исполнения. Вызывая цепочку, нужно внимательно проверять каждый метод, чтобы в случае зависимости 1 вызов не повесил скрипт в целом
 *
 * @example
 * callChain(
 *      this,                               - context
 *      [                                   - chain
 *          [method1],                      - arr fn
 *          [method2],                      - arr fn
 *          [method3, [opt1, opt2]],        - arr fn + arr opt
 *          [method4, [opt1]],              - arr fn + arr opt
 *      ],
 * );
 * @param { Object } options
 * @param { * } options.context - контекст функции
 * @param { Array<Array<function, Array>> } options.chain - массив с функциями для вызова
 */

function callChain({ context, chain }) {
  chain.forEach(current => {
    const [fn, args] = current

    setTimeout(() => fn.apply(context, args), 0)
  })
}

export default callChain
