import Vue from 'components'
import vuetify from 'modules/vuetify-plugin-without-reset-scss'
import TheHeader from 'components/common/TheHeader/components/common/TheHeader'
import { MOUNT_SELECTOR } from 'components/common/TheHeader/constants'
import 'components/common/TheHeader/index.scss'

const mountElement = document.querySelector(MOUNT_SELECTOR)

if (mountElement) {
  new Vue({
    vuetify,
    el: mountElement,
    components: {
      TheHeader,
    },
  })
}
