/**
 * @description
 *
 * Возвращает CSS-свойство DOM-элемента
 *
 * @param { HTMLElement } node - узел, свойство которого необходимо вернуть
 * @param { String } prop - название свойства
 *
 * @return { String } - значение указанного свойства
 */

function getCSSProp(node, prop) {
  if (node && prop) {
    return getComputedStyle(node)[prop]
  }
}

export default getCSSProp
