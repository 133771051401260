var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { "data-club-card-info": "" } },
    [
      _c(
        "VDialog",
        {
          attrs: {
            fullscreen: _vm.isMobile,
            width: _vm.isMobile ? undefined : 456,
          },
          model: {
            value: _vm.isDialogVisible,
            callback: function ($$v) {
              _vm.isDialogVisible = $$v
            },
            expression: "isDialogVisible",
          },
        },
        [
          _c(
            "ModalLayout",
            {
              staticClass: "club-card-info",
              attrs: {
                title: "Ваша клубная карта",
                "data-qa": "club_card_info",
                "close-to": "",
                "body-flex-basis-auto": !_vm.isMobile,
              },
              on: { "modal:back": _vm.handleCloseDialog },
            },
            [
              _c("div", { staticClass: "p-10" }, [
                _c("div", { staticClass: "club-card-info__card-container" }, [
                  _c("div", { staticClass: "w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "club-card-info__card ui-text ui-text_body-1 ui-kit-color-bg-gray-0",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center ui-text ui-text_h6",
                          },
                          [
                            _c("img", {
                              staticClass: "mr-2",
                              attrs: {
                                src: "/static/_v1/pd/icons/ui-kit/club/club-percent-white.svg",
                                width: "24",
                                height: "24",
                                alt: "Иконка Процент Клуба",
                              },
                            }),
                            _vm._v(
                              "\n                Клуб ПроДокторов\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-direction-column align-items-center",
                          },
                          [
                            _c("img", {
                              staticClass: "mb-2",
                              attrs: {
                                src: "/static/_v1/pd/icons/ui-kit/club/club-check-white.svg",
                                width: "72",
                                height: "72",
                                alt: "Иконка Галочка Клуба",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ui-text ui-text_h6 mb-1",
                                attrs: {
                                  "data-qa": "club_card_info_user_phone",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.formattedPhone) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "ui-text ui-text_body-2" },
                              [
                                _vm._v(
                                  "\n                  в клубе до " +
                                    _vm._s(_vm.clubData.cardDateEnd) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                Если возникнут вопросы:\n                "
                          ),
                          _c("br"),
                          _vm._v("8 800 707-31-29\n              "),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.isMobile
                  ? _c(
                      "div",
                      { staticClass: "mt-6" },
                      [
                        _c(
                          "VBtn",
                          {
                            attrs: {
                              color: "primary",
                              "data-qa": "club_card_info_fixed_close_button",
                              depressed: "",
                              block: "",
                            },
                            on: { click: _vm.handleCloseDialog },
                          },
                          [_vm._v("\n            Закрыть\n          ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isMobile
                ? _c("BottomPanel", {
                    attrs: { fixed: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "container",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "px-2 pt-2 pb-6" },
                                [
                                  _c(
                                    "VBtn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        "data-qa":
                                          "club_card_info_fixed_close_button",
                                        depressed: "",
                                        block: "",
                                      },
                                      on: { click: _vm.handleCloseDialog },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Закрыть\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      513512091
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }