import {
  GECKO,
  SAFARI,
  WEBKIT,
  TRIDENT,
  EDGEHTML,
} from 'constants/browserCores'

/**
 * @return { String } - имя браузерного движка
 */

function getBrowserCore() {
  const agent = window.navigator.userAgent.toLowerCase()

  if (agent.includes('trident')) {
    return TRIDENT
  }

  if (agent.includes('firefox')) {
    return GECKO
  }

  if (agent.includes('edge')) {
    return EDGEHTML
  }

  if (agent.includes('chrome') && agent.includes('safari')) {
    return SAFARI
  }

  if (agent.includes('webkit')) {
    return WEBKIT
  }
}

export default getBrowserCore
