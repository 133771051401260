/**
 * @description
 *
 * Вешаю слушатель на документ и при срабатывании события на переданных селекторах вызываю callback
 *
 * @params { Object } options
 * @params { String } options.eventName - событие, которое будет прослушиваться
 * @params { Array } options.selectors - массив селекторов, на которых событие отлавливается
 * @params { Function } options.handlePaste - слушатель, который обрабатывает событие. Функции передается объект с 2 полями:
 * 1. { Object } event - объект события
 * 2. { HTMLElement } targetNode - узел, на котором событие произошло
 * */

function createEventListener(options = {}) {
  const {
    selectors = [],
    eventName = 'click',
    handleEvent = () => {},
  } = options

  document.addEventListener(eventName, event => {
    selectors.some(selector => {
      const targetNode = event.target.closest(selector)

      if (targetNode) {
        handleEvent({
          event,
          targetNode,
        })
      }

      return !!targetNode
    })
  })
}

export default createEventListener
