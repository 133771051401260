import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import DraftReminder from 'components/common/DraftReminder/components/DraftReminder'
import { MOUNT_SELECTOR } from 'components/common/DraftReminder/constants'

const el = document.querySelector(MOUNT_SELECTOR)

if (el) {
  new Vue({
    vuetify,
    el,
    render: h => h(DraftReminder),
  })
}
