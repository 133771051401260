const ClubRedirectURLs = {
  props: {
    redirectUrlToPayment: {
      type: String,
      default: '',
    },
    detailAppointmentLink: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    clubPaymentUrl: window.location.origin,
  }),
}

export default ClubRedirectURLs
