import getCSSProp from 'utils/get/getCSSProp'

/**
 * @description
 *
 * Возвращает обрезанное CSS-значение DOM-элемента (например высоту без 'px')
 *
 * @param { HTMLElement } node - узел, свойство которого необходимо вернуть
 * @param { String } prop - название свойства
 * @param { Boolean } float - метод округления
 *
 * @return { Number | NaN } - значение указанного свойства
 */

function getCSSPropRound(node, prop, float) {
  const method = float ? parseFloat : parseInt

  return method(getCSSProp(node, prop))
}

export default getCSSPropRound
