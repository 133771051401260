export const REDIRECT_VALUES = {
  club: 'club',
  online: 'online',
  telemed: 'telemed',
  home: 'na_dom',
  telemedMedtochka: 'telemed_mt',
  default: 'default',
} as const

export type RedirectValue = typeof REDIRECT_VALUES[keyof typeof REDIRECT_VALUES]
