<template>
  <div
    data-qa="card_getting_club_enter"
  >
    <div
      class="ui-text ui-text_h6 ui-kit-color-text"
      data-qa="card_getting_club_enter_title"
    >
      Вы не в клубе
    </div>
    <div
      class="ui-text ui-text_body-1 ui-kit-color-text mt-3"
      data-qa="card_getting_club_enter_description"
    >
      Клубная карта даёт доступ к ценам ниже
      <br>на 30% на приёмы врачей и {{ discountOnServices }}% на услуги
      <br>в клиниках клуба
    </div>
    <div
      class="club-card-offer ui-text ui-text_body-1 ui-kit-color-text"
      data-qa="card_getting_club_enter_offer"
    >
      <div class="d-flex align-items-center justify-content-between mb-1">
        <span data-qa="card_getting_club_enter_offer_title">
          Карта на 6 месяцев
        </span>
        <span
          v-if="clubData.cardNextPrice"
          class="ui-text ui-text_subtitle-1"
          data-qa="card_getting_club_enter_offer_price"
        >
          {{ clubData.cardNextPrice }} {{ currency.symbol }}
        </span>
        <VImg
          v-else
          contain
          width="24"
          height="25"
          class="flex-grow-0"
          data-qa="card_getting_club_enter_offer_present"
          src="/static/_v1/pd/icons/emoji/wrapped-present.png"
          alt="Иконка подарок"
        />
      </div>
      <div
        class="ui-text ui-text_body-2 ui-kit-color-text-secondary"
        data-qa="card_getting_club_enter_offer_description"
      >
        Это не подписка. Вы сами решаете, покупать
        <br>новую клубную карту или нет.
      </div>
    </div>
    <VBtn
      block
      color="primary"
      class="mt-6 mb-2"
      :elevation="0"
      data-qa="card_getting_club_enter_submit"
      @click="handleClickSubmit"
    >
      {{ variationBtnText }}
    </VBtn>
    <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary text-center">
      Нажимая на кнопку, вы даете согласие <br>
      <a
        target="_blank"
        href="/info/privacy-policy/"
        class="ui-text ui-kit-color-primary ui-text_without-line"
      >на обработку персональных данных</a>,
      <a
        target="_blank"
        href="/info/terms-of-use/"
        class="ui-text ui-kit-color-primary ui-text_without-line"
      >принимаете пользовательское соглашение</a> и соглашаетесь <br> с
      <a
        target="_blank"
        href="/static/_v1/pd/documents/pdf/club/Правила_использования_купонов.pdf"
        class="ui-text ui-kit-color-primary ui-text_without-line"
      >офертой</a> и
      <a
        target="_blank"
        href="/static/_v1/pd/documents/pdf/club/Правила_участия_в_клубе_ПроДокторов.pdf"
        class="ui-text ui-kit-color-primary ui-text_without-line"
      >правилами клуба</a>.
    </div>
    <VBtn
      text
      block
      class="mt-2"
      data-qa="card_getting_club_enter_cancel"
      @click="$emit(events.club.cardGettingCancel)"
    >
      Отменить
    </VBtn>
  </div>
</template>

<script>
import {
  eventsData,
  getClubData,
} from 'components/common/Club/components/core/ClubCardGetting/interfaces'

import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import { CLUB_CARD_GETTING_YA_GOALS } from 'yandexGoals'
import CurrencyData from 'components/common/mixins/CurrencyData'

export default {
  name: 'ClubCardGettingClubEnter',
  mixins: [
    ManageYandexGoals,
    CurrencyData,
  ],
  props: {
    clubData: {
      type: Object,
      default: getClubData,
    },
  },
  data: () => ({
    events: eventsData,
    discountOnServices: window.USER.club.discountOnServices,
    yaGoalsList: CLUB_CARD_GETTING_YA_GOALS,
  }),
  computed: {
    variationBtnText() {
      return this.clubData.cardNextPrice ? `Оплатить ${this.clubData.cardNextPrice} ${this.currency.symbol}` : 'Вступить'
    },
  },
  methods: {
    handleClickSubmit() {
      this.$emit(this.events.club.cardGettingSubmit)

      if (this.clubData.cardNextPrice) {
        this.sendYandexGoal('clickPayClubCard')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.club-card-offer {
  margin-top: 24px;
  padding-left: 40px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: url('/static/_v1/pd/icons/ui-kit/club/club-percent.svg') no-repeat 50% 50% / contain;
  }
}
</style>
