export const EVENTS = {
  open: 'side-menu:open',
}

export const DIALOG_LOGOUT = {
  isDanger: true,
  title: 'Выйти из профиля?',
  text: 'Вы выйдете из профиля на всех устройствах',
  closeText: 'Отменить',
  confirmText: 'Выйти',
}

export const SNACKBAR_TOWN_SELECTED = {
  message: 'Ваш город ',
  actionText: 'Изменить',
}

export const MEDCARD_STATUS = {
  EMPTY: 'empty',
  PROTECTED: 'protected',
  NEED_CONFIRMATION: 'need_confirmation',
  EXPIRED: 'expired',
}
