import { axiosClient } from 'utils'

/**
 * Метод для отправки смс кода на номер телефона
 *
 * @param { String } phone
 *
 * @returns { Promise }
 */

function requestGetCodeForUserNoRegistration(phone) {
  return axiosClient.post(`${window.MEDTOCHKA_URL}/services/sso/phone_login/`, {
    phone,
  }, {
    withCredentials: true,
  })
}

export default requestGetCodeForUserNoRegistration
