import { getGoalsList } from 'utils'

const DRAFT_REMINDER_YANDEX_GOALS = getGoalsList({
  block: 'draftReminder',
  goals: [
    // Уведомление о черновике отзыва (все)
    'show', // Показ
    'notNow', // Нажатие на "Не сейчас"
    'goToReview', // Нажатие на "К отзыву"
  ],
})

export default DRAFT_REMINDER_YANDEX_GOALS
