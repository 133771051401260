var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VAlert",
    _vm._g(
      _vm._b(
        {
          attrs: { color: _vm.alertColor, border: "left", dense: "", text: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "prepend",
                fn: function () {
                  return [_vm._t("prepend")]
                },
                proxy: true,
              },
              {
                key: "append",
                fn: function () {
                  return [_vm._t("append")]
                },
                proxy: true,
              },
              {
                key: "close",
                fn: function ({ toggle }) {
                  return [_vm._t("close", null, null, { toggle })]
                },
              },
            ],
            null,
            true
          ),
        },
        "VAlert",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }