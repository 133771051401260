<template>
  <div class="authorization-check-code">
    <div
      v-if="checkCodeInputLabel"
      class="ui-text ui-text_body-2 ui-kit-color-text mb-5"
    >
      <span
        class="text-pre-wrap"
      >{{ checkCodeInputLabel }}</span>
    </div>
    <div
      v-show="isCodeInputVisible"
      data-qa="user_authorization_code_input"
    >
      <VOtpInput
        ref="authorization-otp-input"
        v-model="requestCheckCodeData.code"
        :disabled="requestCheckCodeData.isLoading"
        :error="!!requestCheckCodeData.errorMessage"
        class="authorization-check-code__otp-input"
        length="4"
        type="number"
        pattern="[0-9]"
        inputmode="numeric"
        @finish="handleCheckCode"
      />
      <VProgressLinear
        :class="{
          'authorization-check-code__progress': true,
          'authorization-check-code__progress_hidden': !requestCheckCodeData.isLoading,
        }"
        active
        rounded
        height="2"
        indeterminate
      />
      <VExpandTransition>
        <div
          v-if="requestCheckCodeData.errorMessage"
          class="ui-text ui-text_caption ui-kit-color-secondary text-center px-3"
        >
          {{ requestCheckCodeData.errorMessage }}
        </div>
      </VExpandTransition>
    </div>
  </div>
</template>

<script>
import { CHECK_CODE_INPUT_LABELS } from 'components/common/UserAuthorization/constants'
import { authByCallOrSmsCheckCodeMixin } from 'components/common/UserAuthorization/mixins'

export default {
  name: 'UserAuthorizationCheckCode',
  mixins: [
    authByCallOrSmsCheckCodeMixin,
  ],
  props: {
    isAutofocusField: {
      type: Boolean,
      default: false,
    },
    isCodeInputVisible: {
      type: Boolean,
      default: false,
    },
    confirmationMethods: {
      type: Object,
      default: () => ({
        isCall: false,
        isSms: false,
        isVk: false,
      }),
    },
    requestGetCodeData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    checkCodeInputLabel() {
      if (this.confirmationMethods.isVk && !this.requestGetCodeData.isVkIgnored) {
        return CHECK_CODE_INPUT_LABELS.vk
      }

      if (this.confirmationMethods.isCall) {
        return CHECK_CODE_INPUT_LABELS.call
      }

      return CHECK_CODE_INPUT_LABELS.sms
    },
  },
  watch: {
    isAutofocusField(isFocus) {
      if (isFocus) {
        this.$refs['authorization-otp-input'].focus()
      }
    },
    async isCodeInputVisible(newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$refs['authorization-otp-input'].focus()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.authorization-check-code {
  &__otp-input::v-deep .v-input__slot {
    margin-bottom: 0;
  }

  &__progress {
    width: 98%;
    margin: 0 auto 8px;
    transition: opacity 0.3s ease-in 0.3s;

    &_hidden {
      opacity: 0;
      transition: opacity 0.1s ease-in;
    }
  }
}
</style>
