/* eslint-disable */
import axios from 'axios';
import {
    axiosClient,
    getCSRFToken,
    createDevNotice,
    testRequiredOptions,
} from 'utils';
import LocationParser from 'modules/LocationParser';
import { REQUEST_ARRAY_NAME } from 'modules/Timetable/constants';

const locationParserHref = new LocationParser();

class AppointmentModules {
    /**
     * @description
     *
     * Получает ячейки с записью на прием на страницах услуг
     * Производит проверку обязательных полей данных запроса
     * Ниже пример данных для корректного запроса
     *
     * @example
     *
     * const request = {
     *     days: 14,
     *     day_start: "2021-04-01",
     *     only_free: true,
     *     town_timedelta: "5",
     *     services: [
     *         {
     *             device_id: 180,
     *             service_id: 160,
     *             lpu_id: 37347,
     *         }, {
     *             device_id: 180,
     *             service_id: 180,
     *             lpu_id: 37348,
     *             additional_service_ids: [209, 245, 231],
     *         },
     *     ],
     * };
     *
     * @params { Object } options
     * @params { String | Number } [options.days] - количество дней
     * @params { Boolean } [options.only_free] - присылать все слоты с МИС или только свободные ячейки
     * @params { String } [options.day_start] - дата с которой будут запрашиваться слоты
     * @params { String } [options.town_timedelta] - временная разница, поле необходимо для МИС, чтобы выбрать слоты правильно, учитывая разницу во времени
     *
     * @params { Array } [options.services] - массив с услугами
     *
     * @params { Object } [options.services.item] - объект с данными для конкретного LPU
     * @params { String | Number } [options.services.lpu_id] - id LPU
     * @params { String | Number } [options.services.device_id] - id оборудования
     * @params { String | Number } [options.services.service_id] - id услуги(синонима)
     * @params { Array } options.services.additional_service_ids - дополнительные данные(например МРТ с контрастом или без)
     *
     * @params { String | Number } [options.devices.lpu_id] - id LPU
     * @params { Array } [options.devices.devices_ids] - список id всего оборудования, которое есть в клинике
     *
     * @return { Promise }
     * */

    getAppointmentServicesSlots(options) {
        return new Promise((resolve, reject) => {
            const {
                days,
                day_start,
                only_free,
                town_timedelta,
            } = options;

            const requestFieldName = 'service_id';
            const requestPartPath = '/ajax/services/schedule/';

            const noticeOptions = {
                mode: 'throw',
                module: 'AppointmentModules',
                method: 'getAppointmentServicesSlots',
            };

            const getNoticeDescription = arrayName => `В запросе для слотов обязательное поле "${arrayName}" не может быть пустым`;

            const testBaseOptions = testRequiredOptions({
                ...noticeOptions,
                requiredOptions: {
                    days,
                    day_start,
                    only_free,
                    town_timedelta,
                },
            });

            if (!testBaseOptions) {
                return;
            }

            if (!options[REQUEST_ARRAY_NAME].length) {
                createDevNotice({
                    ...noticeOptions,
                    description: getNoticeDescription(REQUEST_ARRAY_NAME),
                });

                return;
            }

            for (const item of options[REQUEST_ARRAY_NAME]) {
                const testInnerOptions = testRequiredOptions({
                    ...noticeOptions,
                    requiredOptions: {
                        lpu_id: item.lpu_id,
                    },
                });

                const requestFieldType = typeof item[requestFieldName];

                if (!testInnerOptions || (requestFieldType !== 'string' && requestFieldType !== 'number')) {
                    createDevNotice({
                        ...noticeOptions,
                        description: getNoticeDescription(requestFieldName),
                    });

                    return;
                }
            }

            axios.post(window.location.origin + requestPartPath, options)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }
    /**
     * Получить ячейки с доступной записью на прием
     *
     * @param days - количество дней
     * @param dt_start - дата с которой будут запрашиваться слоты
     * @param doctors_lpus - массив с объектами id врача и id клиники
     * @param town_timedelta - разница во времени относительно сервера
     * @param user_timedelta - разница во времени относительно пользователя
     * @param {boolean} [all_slots] - присылать ли занятые слоты
     * @param {Array<Array<number>>} [lpu_timedelta] - если возможно много мест работы в разных городах,
     * то для каждого своя timedelta
     * @param [domain] - домен Продокторов
     * @returns {Promise}
     */
    getAppointmentDoctorsSlots ({ days, dt_start, doctors_lpus, town_timedelta, user_timedelta, all_slots, lpu_timedelta, domain }) {
        return new Promise((resolve, reject) => {
            if (!days) {
                return reject(new Error("Не передано количество дней"));
            }

            if (!dt_start) {
                return reject(new Error("Не передана стартовая дата"));
            }

            if (!doctors_lpus.length) {
                return reject(new Error("Не передан doctors_lpus"));
            }

            if (!(town_timedelta || user_timedelta) && !lpu_timedelta) {
                return reject(new Error("Не передан timedelta"));
            }

            if (town_timedelta && lpu_timedelta || town_timedelta && user_timedelta) {
                return reject(new Error("Передано несколько timedelta"));
            }

            const requestData = { days, dt_start, doctors_lpus, town_timedelta, all_slots, lpu_timedelta, user_timedelta };

            let host = '/';

            if (domain) {
                host = `//${domain}/`
            }

            $.ajax({
                url: `${host}ajax/schedule/slots_bulk/`,
                type: 'POST',
                data: JSON.stringify(requestData),
                async: true,
                crossDomain: true,
                processData: false,
                headers: {
                    'content-type': 'application/json',
                    'X-CSRFToken': getCSRFToken(),
                },
                success: function (res) {
                    resolve(res);
                }
            });
        });
    }

    /**
     * Получить ячейки с доступной записью на прием
     *
     * @param days - количество дней
     * @param dt_start - дата с которой будут запрашиваться слоты
     * @param doctors_lpus - массив с объектами id врача и id клиники
     * @param {boolean} [all_slots] - присылать ли занятые слоты
     * @param {Array<Array<number>>} [lpu_timedelta] - если возможно много мест работы в разных городах,
     * то для каждого своя timedelta
     * @returns {Promise}
     */

    getAppointmentLpusSlots ({ days, dt_start, lpus_specialities, town_timedelta }) {
        return new Promise((resolve, reject) => {
            if (!days) {
                return reject(new Error("Не передано количество дней"));
            }

            if (!dt_start) {
                return reject(new Error("Не передана стартовая дата"));
            }

            if (!lpus_specialities.length) {
                return reject(new Error("Не передан lpus_specialities"));
            }

            const data = { days, dt_start, lpus_specialities, town_timedelta };

            axiosClient.post('/ajax/schedule/slots_bulk_by_specialities/', data)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        })
    }

    /**
     * Переходит на страницу записи
     *
     * @param doctor - ID врача
     * @param lpu - ID клиники
     * @param slot - дата записи
     * @param source - инициатор записи e.g список врачей
     * @param filter - дополнительная фильтрация
     * @param type - тип приема (клиника, на дом, онлайн)
     * @param spec_placement - Переход с карточки спец. размещения (скорее всего, легаси)
     * @param doctorSpec - ID специальности врача
     * @param [domain] - домен (например, prodoctorov.ru)
     */
    redirectToNewAppointment ({ doctor, lpu, slot, source, filter, type, spec_placement, doctorSpec, domain }) {
        let restParams = '';
        let host = `${location.origin}/`;

        if (slot) {
            restParams += `&slot=${slot}`;
        }

        if (type && type !== 'default') {
            restParams += `&type=${type}`;
        }

        if (spec_placement) {
            restParams += `&spec_placement=${spec_placement}`;
        }

        if (lpu) {
            restParams += `&lpu=${lpu}`;
        }

        if (doctorSpec) {
            restParams += `&speciality=${doctorSpec}`;
        }

        if (filter) {
            restParams += `${filter}`;
        }

        if (domain) {
            host = `//${domain}/`
        }

        location.href = `${host}appointment/new/?doctor=${doctor}&source=${encodeURIComponent(locationParserHref.source)}${restParams}`;
    }

    redirectToNewAppointmentClinic (options = {}) {
        const {
            lpu,
            slot,
            source,
            targetDoctorId,
            dTypeId,
            dClassId,
            deviceId,
            synonymId,
            misServiceId, // id - услуги, загружаемой из МИС
            timedelta,
        } = options;

        let dParam = '';

        const noticeData = {
            module: 'appointment-modules',
            method: 'redirectToNewAppointmentClinic',
        };

        const noticeDescription = fieldName => `Если передано поле "slot", обязательно должно быть передано поле "${fieldName}"`;

        dParam += `&source=${encodeURIComponent(locationParserHref.source)}`;

        if (dTypeId) {
            dParam += `&dtype=${dTypeId}`;
        } else if (dClassId) {
            dParam += `&dclass=${dClassId}`;
        }

        if (deviceId) {
            dParam += `&device=${deviceId}`;
        }

        if (synonymId) {
            dParam += `&service=${synonymId}`;
        }

        if (misServiceId) {
            dParam += `&service=${misServiceId}`;
        }

        if (slot) {
            if (!synonymId) {
                createDevNotice({
                    ...noticeData,
                    description: noticeDescription('synonymId'),
                });

                return;
            }

            dParam += `&slot=${slot}`;
        }

        if (targetDoctorId) {
          dParam += `&targetDoctorId=${targetDoctorId}`
        }

        location.href = `${location.origin}/uslugi/appointment/new/?lpu=${lpu}${dParam}`;
    }
}

export { AppointmentModules };
export default AppointmentModules;
