export const BLOCK_NAME = 'b-hint'
export const WIDTH_HINT = {
  small: 260,
  large: 631,
}
/**
 * Символ используется как уникальное имя свойства для стороннего объекта,
 * позволяет избежать конфликтов между нашим и сторонними свойствами объекта
 * @type {symbol}
 */
export const IS_DECORATED_SYMBOL = Symbol('isDecorated')
