/**
 * @description
 *
 * Генерирует список целей для Яндекс.Метрики и возвращает объект целей
 *
 * @example
 *
 * const goals = getGoalsList({
 *     block: 'test_block',
       goals: ['a', 'b', 'c', 'd'],
 * });
 *
 * console.log(goals); -> {
 *     a: test_block__a,
 *     b: test_block__b,
 *     c: test_block__c,
 *     d: test_block__d,
 * }
 *
 * @params { Object } options
 * @params { String } options.block - название блока целей(какую группу будут охватывать)
 * @params { Array } options.list - массив со всеми целями
 *
 * @return { Object } - объект с целями
 * */

function getGoalsList({ block = '', goals = [] } = {}) {
  const data = {}

  goals.forEach(goal => {
    data[goal] = `${block}__${goal}`
  })

  return data
}

export default getGoalsList
