/**
 * @description
 *
 * Диспатчит событие и доп данные на window
 *
 * @param { String } name - название события
 * @param { Any= } detail - данные, передаваемые с событием
 *
 * @return { Void }
 */
import createDevNotice from 'utils/executors/createDevNotice'

function dispatchCustomEventGlobally(name, detail) {
  if (!name || typeof name !== 'string') {
    createDevNotice({
      module: 'dispatchCustomEventGlobally',
      method: 'dispatchCustomEventGlobally',
      description: 'Параметр "name" должен быть не пустой строкой',
    })
    return
  }

  window.dispatchEvent(new CustomEvent(name, { detail }))
}

export default dispatchCustomEventGlobally
