export const CLUB_EVENTS = {
  clubPrepayment: 'club:prepayment',
  clubSelectInfoModal: 'club:select-info-modal',
  clubDownloadCoupon: 'club:download-coupon',
  clubCountdownStarted: 'club:countdown-started',
  clubCountdownExpired: 'club:countdown-expired',
  clubMakeAppointment: 'club:make-appointment',
  clubTypePaymentSelected: 'club:type-payment-selected',
  clubSendEmailSuccess: 'club:send-email-success',
  clubRequestActiveCard: 'club:request-active-card',
  clubGoWithoutPrepayment: 'club:go-without-prepayment',
  clubRedirectToMedtochka: 'club:redirect-to-medtochka',
  clubReturnFromErrorView: 'club:return-from-error-view',
  clubCancellationEmailForm: 'club:cancellation-email-form',
  clubAppointmentTypeSelected: 'club:appointment-type-selected',
  clubGetResponseErrorDesktop: 'club:get-response-error-desktop',
  clubGetResponseAppointmentDesktop: 'club:get-response-appointment-desktop',
  clubSelectLpuChanged: 'club:select-lpu-changed',
  clubSelectSpecialityChanged: 'club:select-speciality-changed',
  clubClickShowNotice: 'club:click-show-notice',
  clubClickPayment: 'club:click-payment',
}

export const TIMEOUT_DATA = {
  preloader: 1000,
}

export const TYPES_PAYMENT = {
  online: 'type-payment-online',
  offline: 'type-payment-offline',
}

export const PAYMENT_STATUSES = {
  // backend statuses
  created: 'created',
  canceled: 'canceled',
  succeeded: 'succeeded',
  waitingForConfirmation: 'waiting_for_confirmation',
  // frontend statuses
  expired: 'expired',
  payment: 'payment',
  // socket answer
  reload: 'reload',
}

export const ERROR_MESSAGES = {
  non_field_errors: {
    timed_out: 'Истекло время для оплаты.',
    already_paid: 'Купон уже оплачен.',
    already_processing: 'Оплата купона в обработке.',
    appointment_canceled: 'Запись отменена. Попробуйте записаться заново.',
    coupon_request_canceled: 'Запрос на покупку купона аннулирован.',
  },
  email: {
    invalid: 'Введите корректный адрес электронной почты.',
  },
  appointmentReject: 'Ошибка. Попробуйте перезагрузить страницу.',
}

export const CLUB_PRIVILEGES = {
  isFree: 'isFree',
  withCard: 'withCard',
  withoutCard: 'withoutCard',
}

export const CLUB_DISCOUNT = 30
