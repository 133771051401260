export const DIALOG_ERROR_CONFIG = {
  title: 'Не получилось подтвердить номер',
  text: 'Обновите страницу и попробуйте ещё раз.',
  closeText: 'Закрыть',
  confirmText: 'Обновить',
  beforeConfirm: () => window.location.reload(),
}

export const CODE_INPUT_LABEL = {
  sms: 'Введите код из СМС',
  tel: 'Сейчас позвоним вам.\nВведите 4 последние цифры\nвходящего номера.',
}

export const REQUEST_CODE_USER_ERRORS = {
  error_limit: 'Отправить новый код на этот номер можно через сутки',
  error_limit_total: 'Отправить новый код можно через сутки',
}

export const REQUEST_CODE_STAFF_ERRORS = {
  error_limit: 'Достигнут лимит сообщений на заданный номер',
  error_limit_total: 'Достигнут лимит отправки сообщений',
  error_fields: 'Неверно заполненное поле',
}

export const CONFIRM_CODE_ERRORS = {
  bad_code: 'Неверный СМС код',
  code_expired: 'Лимит вводов превышен',
  attempts_out: 'Слишком много попыток. Повторите запрос',
}
