import createDevNotice from 'utils/executors/createDevNotice'

/**
 * Производит редирект по клику на переданный селектор
 * Обязательно нужно указывать в атрибут карточки "data-redirect-path" путь для редиректа.
 * @param { Object } options
 * @param { String } options.className - класс элемента, по клику на который происходит редирект
 * @param { 'absolute' | 'relative'= } options.pathType - тип пути
 */
function createRedirectListener({ className, pathType = 'relative' }) {
  if (!className) {
    createDevNotice({
      module: 'createRedirectListener',
      description: 'Отсутствует обязательное поле "className"',
    })

    return
  }

  document.addEventListener('click', ({ target }) => {
    const node = target.closest(`.${className}`)
    const path = node?.dataset.redirectPath

    if (!path) {
      return
    }

    if (pathType === 'relative') {
      window.location.assign(window.location.origin + path)
    } else if (pathType === 'absolute') {
      window.location.href = path
    }
  })
}

export default createRedirectListener
