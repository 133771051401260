import Vue from 'components'
import vuetify from 'modules/vuetify-plugin-without-reset-scss'
import SideMenu from 'components/mobile/SideMenu/SideMenu'

const mountElement = document.querySelector('.side-menu')

if (mountElement) {
  new Vue({
    vuetify,
    el: mountElement,
    components: {
      SideMenu,
    },
  })
}
