/**
 * @description
 *
 * Добавляет обработчик загрузки DOM.
 *
 * @param { Function } handler
 */

function DOMReady(handler) {
  document.addEventListener('DOMContentLoaded', handler)
}

export default DOMReady
