import { NAMESPACE } from 'components/common/Club/components/core/ClubCardGetting/constants'

const el = document.querySelector(NAMESPACE.mountSelector)

if (el && !window.USER.club.isInClub) {
  (async () => {
    const { default: Vue } = await import(/* webpackMode: "eager" */'components')
    const { default: vuetify } = await import(/* webpackMode: "eager" */'modules/vuetify-plugin')
    const { default: ClubCardGetting } = await import(/* webpackMode: "eager" */'components/common/Club/components/core/ClubCardGetting/ClubCardGetting')

    new Vue({
      el,
      vuetify,
      render: h => h(ClubCardGetting),
    })
  })()
}
