import {
  isProd,
  deepMerge,
  createDevLog,
  createDevNotice,
} from 'utils'
import { YANDEX_SCRIPT_SELECTOR } from 'modules/YandexGoals/constants'

class YandexGoals {
  moduleName = 'YandexGoals'

  customData = {}

  constructor(options = {}) {
    this._init(options)
  }

  _init(options = {}) {
    this._createOptions(options)
    this._hookBeforeInit()
    this._createInstanceData()

    if (!this.hasYandexScript()) {
      this._showError('На странице не установлен код счётчика Яндекс.Метрики')
      return
    }

    this._onLoadedYandexScript()
    this._onDocumentEventListeners()
    this._hookAfterInit()
  }

  send = ({ name } = {}) => {
    if (!name) {
      return
    }

    if (this.isLoadedYandexScript) {
      this._handleReachGoal(name)
    } else {
      this._setStoreFailedGoals(name)
    }
  }

  hasYandexScript = () => Boolean(document.querySelector(YANDEX_SCRIPT_SELECTOR))

  _createOptions = options => {
    this.options = deepMerge(this._getDefaultOptions(), options)
  }

  _createInstanceData = () => {
    this.isLoadedYandexScript = Boolean(window.ym)
    this.yaCounterId = window.YA_COUNTER_ID
    this.loadedYandexScriptEventName = `yacounter${this.yaCounterId}inited`
    this.dataHookInitedYandexScript = null
    this.dataHookBeforeInit = null
    this.dataHookAfterInit = null
    this.storeFailedGoals = []
  }

  _getDefaultOptions = () => ({
    events: ['click'],
    dataName: null,
    counterId: window.YA_COUNTER_ID,
    handleListeners: () => {},
    hookBeforeInit: () => {},
    hookAfterInit: () => {},
    hookInitedYandexScript: () => {},
  })

  _onLoadedYandexScript = () => {
    if (!this.isLoadedYandexScript) {
      /* see: https://yandex.ru/support/metrica/code/counter-initialize.html#counter-initialize__check-initialize */
      document.addEventListener(this.loadedYandexScriptEventName, () => {
        this._hookInitedYandexScript() // сработает только в том случае, если на момент создания экземпляра не был загружен скрипт метрики
        this._sendFailedReachGoals()
      })
    }
  }

  _onDocumentEventListeners = () => {
    if (this.options.dataName) {
      this.options.events.forEach(event => {
        document.addEventListener(event, this._handleEvent)
      })
    }
  }

  _handleEvent = event => {
    const { target } = event

    /**
         * Особенности кроссбраузерности
         * FireFox
         * */
    if (!target.closest) {
      return
    }

    const currentNode = target.closest(`[${this.options.dataName}]`)

    if (currentNode) {
      const currentValue = currentNode.getAttribute(this.options.dataName)

      if (currentValue) {
        this.send({ name: currentValue })
        return
      }
    }

    /**
         * В коллбэк передается объект, который имеет следующие поля:
         *
         * @param event - объект события
         * @param customData - глобальное хранилище пользовательских данных
         * @param dataHookBeforeInit - данные возвращаемые из коллбэка `hookBeforeInit`, если они есть, иначе undefined
         * @param dataHookAfterInit - данные возвращаемые из коллбэка `hookAfterInit`, если они есть, иначе undefined
         * @param dataHookInitedYandexScript - данные возвращаемые из коллбэка `hookInitedYandexScript`, если они есть, иначе undefined
         * @param send - метод отправки
         * */
    this.options.handleListeners({
      event,
      send: this.send,
      customData: this.customData,
      dataHookBeforeInit: this.dataHookBeforeInit,
      dataHookAfterInit: this.dataHookAfterInit,
      dataHookInitedYandexScript: this.dataHookInitedYandexScript,
    })
  }

  _hookBeforeInit = () => {
    this.dataHookBeforeInit = this.options.hookBeforeInit({ customData: this.customData })
  }

  _hookAfterInit = () => {
    this.dataHookAfterInit = this.options.hookAfterInit({ customData: this.customData })
  }

  _hookInitedYandexScript = () => {
    this.isLoadedYandexScript = true
    this.dataHookInitedYandexScript = this.options.hookInitedYandexScript({ customData: this.customData })
  }

  _sendFailedReachGoals = () => {
    this.storeFailedGoals.forEach(this._handleReachGoal)
    this.storeFailedGoals = []
  }

  _setStoreFailedGoals = name => {
    this.storeFailedGoals.push(name)
  }

  _handleReachGoal = name => {
    if (isProd) {
      window.ym(this.options.counterId, 'reachGoal', name)
    } else {
      createDevLog({
        module: this.moduleName,
        title: 'Цель:',
        message: name,
      })
    }
  }

  _showError = description => {
    if (!isProd) {
      createDevNotice({
        module: this.moduleName,
        description,
      })
    }
  }
}

export default YandexGoals
