/**
 * @description
 *
 * Меняет значение title у документа
 *
 * @param { String } title - новое значение title
 */

function setDocumentTitle(title) {
  document.title = title
}

export default setDocumentTitle
