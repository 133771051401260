import AuthService from 'services/AuthService'
import { createDevNotice } from 'utils'
import {
  REQUEST_CODE_ERRORS,
  CONFIRM_PHONE_ERRORS,
  CALL_CREATE_REQUEST_ERRORS,
} from './constants'

export * from './constants'
export * from './functions'

/**
 * Делает запрос к API для отправки СМС кода подтверждения, сообщает об ошибках в процессе.
 * @param {Object} options
 * @param {string} options.phone - телефон пользователя в формате `7XXXXXXXXXX`
 * @param {Function} options.resolvedCb - коллбэк, срабатывающий после успешной отправки запроса на отправку СМС на предоставленный телефон
 * @param {Function} options.rejectedCb - коллбэк, срабатывающий при ошибке
 * @param {Function} options.finallyCb - коллбэк, срабатывающий всегда после успешного или неудачного запроса
 * @param {Function} options.handleErrorMessage - ф-я обрабатывающая сообщение об ошибке
 * @param {Function} options.showReloadError - ф-я говорящая юзеру перезагрузить страницу
 * @returns {Promise<void>}
 */
export const requestCode = async ({
  phone,
  resolvedCb = () => {},
  rejectedCb = () => {},
  finallyCb = () => {},
  handleErrorMessage,
  showReloadError,
}) => {
  try {
    await AuthService.requestCode(phone)
    resolvedCb(phone)
  } catch ({ response, message }) {
    rejectedCb()

    if (!response) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'requestCode',
        description: message,
      })
      showReloadError()
      return
    }

    const { phone: phoneData } = response.data

    if (!phoneData) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'callCreateRequest',
        description: message,
      })
      showReloadError()
      return
    }

    switch (phoneData.code) {
      case REQUEST_CODE_ERRORS.invalid.code:
        handleErrorMessage(REQUEST_CODE_ERRORS.invalid.message)
        break
      case REQUEST_CODE_ERRORS.errorLimit.code:
        handleErrorMessage(REQUEST_CODE_ERRORS.errorLimit.message)
        break
      case REQUEST_CODE_ERRORS.errorLimitTotal.code:
        handleErrorMessage(REQUEST_CODE_ERRORS.errorLimitTotal.message)
        break
      default:
        createDevNotice({
          module: 'mixins/auth',
          method: 'requestCode',
          description: message,
        })
        showReloadError()
    }
  } finally {
    finallyCb()
  }
}

/**
 * Делает запрос к API для подтверждения телефона кодом, сообщает об ошибках в процессе.
 * @param {Object} options
 * @param {string} options.phone - телефон пользователя в формате `7XXXXXXXXXX`
 * @param {string} options.code
 * @param {Function} options.resolvedCb - коллбэк, срабатывающий после успешного подтверждения телефона; может быть асинхронным
 * @param {Function} options.rejectedCb - коллбэк, срабатывающий при ошибке
 * @param {Function} options.finallyCb - коллбэк, срабатывающий всегда после успешного или неудачного запроса
 * @param {Function} options.handleErrorMessage - ф-я обрабатывающая сообщение об ошибке
 * @param {Function} options.showReloadError - ф-я говорящая юзеру перезагрузить страницу
 * @returns {Promise<void>}
 */
export const confirmCode = async ({
  phone,
  code,
  resolvedCb = () => {},
  rejectedCb = () => {},
  finallyCb = () => {},
  handleErrorMessage,
  showReloadError,
}) => {
  try {
    const response = await AuthService.confirm(phone, code)
    const { authorization_code: authCode } = response.data

    await AuthService.login({ authCode })
    resolvedCb()
  } catch ({ response, message }) {
    rejectedCb()

    if (!response) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'confirmCode',
        description: message,
      })
      showReloadError()
      return
    }

    if (response.status === 403) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'confirmCode',
        description: `Ошибка 403. Ответ сервера: "${response.data.detail}"`,
      })
      showReloadError()
      return
    }

    const { secret_code: secretCode } = response.data

    if (!secretCode) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'callCreateRequest',
        description: message,
      })
      showReloadError()
      return
    }

    switch (secretCode.code) {
      case CONFIRM_PHONE_ERRORS.invalid.code:
        handleErrorMessage(CONFIRM_PHONE_ERRORS.invalid.message)
        break
      case CONFIRM_PHONE_ERRORS.errorCode.code:
        handleErrorMessage(CONFIRM_PHONE_ERRORS.errorCode.message)
        break
      case CONFIRM_PHONE_ERRORS.codeExpired.code:
        handleErrorMessage(CONFIRM_PHONE_ERRORS.codeExpired.message)
        break
      case CONFIRM_PHONE_ERRORS.userHasPhone.code:
        handleErrorMessage(CONFIRM_PHONE_ERRORS.userHasPhone.message)
        break
      default:
        createDevNotice({
          module: 'mixins/auth',
          method: 'confirmCode',
          description: message,
        })
        showReloadError()
    }
  } finally {
    finallyCb()
  }
}

/**
 * Запрос на подтверждение номера по звонку
 * @param {Object} options
 * @param {string} options.phone - телефон пользователя в формате `7XXXXXXXXXX`
 * @param {Function} options.resolvedCb - коллбэк, срабатывающий после успешного подтверждения телефона; может быть асинхронным
 * @param {Function} options.rejectedCb - коллбэк, срабатывающий при ошибке
 * @param {Function} options.handleErrorMessage - ф-я обрабатывающая сообщение об ошибке
 * @param {Function} options.showReloadError - ф-я говорящая юзеру перезагрузить страницу
 * @returns {Promise<void>}
 */
export const callCreateRequest = async ({
  phone,
  resolvedCb = () => {},
  rejectedCb = () => {},
  handleErrorMessage,
  showReloadError,
}) => {
  try {
    const { data } = await AuthService.callCreateRequest(phone)

    resolvedCb({ uuidReceived: data.uuid })
  } catch ({ response, message }) {
    rejectedCb()

    if (!response) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'callCreateRequest',
        description: message,
      })
      showReloadError()
      return
    }

    const { phone: phoneData } = response.data

    if (!phoneData) {
      createDevNotice({
        module: 'mixins/auth',
        method: 'callCreateRequest',
        description: message,
      })
      showReloadError()
      return
    }

    switch (phoneData.code) {
      case CALL_CREATE_REQUEST_ERRORS.invalid.code:
        handleErrorMessage(CALL_CREATE_REQUEST_ERRORS.invalid)
        break
      case CALL_CREATE_REQUEST_ERRORS.userHasPhone.code:
        handleErrorMessage(CALL_CREATE_REQUEST_ERRORS.userHasPhone)
        break
      default:
        createDevNotice({
          module: 'mixins/auth',
          method: 'callCreateRequest',
          description: message,
        })
        showReloadError()
    }
  }
}

/**
 * Выбирает элемент по дата-атрибуту с префиксом `data-auth`.
 * @param {string} attrBody
 * @returns {Element|null}
 */
export const selectWithPrefixedDataAttr = attrBody => document.querySelector(`[data-auth-${attrBody}]`)
