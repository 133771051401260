import { axiosClient } from 'utils'

/**
 * Получает информацию о клубной карте пользователя
 *
 * @example
 * response = {
 *     is_in_club: true,
 *     club_card: {
 *         id: 999,
 *         dt_start: '24.01.2023',
 *         dt_end: '24.07.2023',
 *         mt_link: '/api/medtochka/redirect/?next=%2Fprofile%2F'
 *     }
 *     next_club_card_price: 99,
 * }
 *
 * or
 *
 * response = {
 *     is_in_club: false,
 *     club_card: null,
 *     next_club_card_price: 0,
 * }
 *
 * @returns {Promise}
 * */

function getUserClubCardData() {
  return new Promise((resolve, reject) => {
    axiosClient.get('/club/ajax/user_data/')
      .then(({ data }) => {
        const {
          is_in_club: isInClub,
          club_card: clubCardData,
          next_club_card_price: cardNextPrice,
        } = data

        const userCLubCardData = {
          isInClub,
          cardNextPrice,
        }

        if (clubCardData) {
          userCLubCardData.cardDateStart = clubCardData.dt_start
          userCLubCardData.cardDateEnd = clubCardData.dt_end
          userCLubCardData.cardMtLink = clubCardData.mt_link
        }

        resolve(userCLubCardData)
      })
      .catch(({ response }) => reject(response))
  })
}

export default getUserClubCardData
