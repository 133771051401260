import { getGoalsList } from 'utils'

const APP_CALENDAR_QA_GOALS = getGoalsList({
  block: 'appointment-calendar',
  goals: [
    'button-slot', // слот
    'button-expand', // кнопка развернуть
    'button-collapse', // кнопка свернуть
    'button-prev', // кнопка переключения дня/недели вперед
    'button-next', // кнопка переключения дня/недели назад
  ],
})

export default APP_CALENDAR_QA_GOALS
