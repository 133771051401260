/**
 * @description
 * Производит поиск ближайшего родителя по узлу.
 * Аналогичен методу 'closest', за исключением того, что поиск производится по узлу и возвращается boolean значение
 *
 * @param { HTMLElement } child - узел, от которого необходимо начать поиск
 * @param { HTMLElement } parent - узел, который нужно найти
 *
 * @return { Boolean } - если в цепочке родителей есть совпадение вернется true, иначе - false
 * */

const isClosestNode = (child, parent) => {
  let node = child

  while (node) {
    if (node === parent) {
      return true
    }

    node = node.parentElement
  }

  return false
}

export default isClosestNode
