import { axiosClient } from 'utils'

/**
 * @description Запрос на создание WS для подтверждения номера по звонку
 *
 * @param { Object } options
 * @param { String } options.phone
 *
 * @returns { Promise }
 */

function requestCallConfirmationStart({ phone }) {
  return axiosClient.post(
    `${window.MEDTOCHKA_URL}/services/sso/calls/create_request/`,
    {
      phone,
    },
    {
      withCredentials: true,
    },
  )
}

export default requestCallConfirmationStart
