import axios from 'axios'
import { axiosClient, getCSRFToken } from 'utils'

let axiosCancelTokenSource

/**
 * @description Метод для проверки и подтверждения номера телефона при помощи смс кода для стаффов
 *
 * @param { Object } options
 * @param { String } options.phone - номер телефона (обязательный)
 * @param { String } options.code - смс код (обязательный)
 * @param { String } options.isJsonResponse- получить ответ в виде json (не обязательный)
 *
 * @returns { Promise }
 */

function requestCheckCodeForStaff({ phone, code, isJsonResponse = true }) {
  const formData = new FormData()

  formData.append('phone', phone)
  formData.append('code', code)
  formData.append('json_response', String(isJsonResponse))

  axiosCancelTokenSource = axios.CancelToken.source()

  return axiosClient({
    method: 'POST',
    url: '/ajax/phone/confirm/',
    data: formData,
    headers: {
      'X-CSRFToken': getCSRFToken(),
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: axiosCancelTokenSource.token,
  })
}

function cancelRequestCheckCodeForStaff() {
  if (axiosCancelTokenSource?.cancel) {
    axiosCancelTokenSource.cancel()
  }
}

export {
  requestCheckCodeForStaff,
  cancelRequestCheckCodeForStaff,
}
