import { TYPES_PAYMENT } from 'components/common/Club/constants'

const ClubGlobalData = {
  props: {
    offsetMinutes: {
      type: [String, Number],
      default: null,
    },
    dateCreatedCouponRequest: {
      type: String,
      default: '',
    },
    dateClubRegistration: {
      type: String,
      default: null,
      required: false,
    },
    isClubRegistration: {
      type: Boolean,
      default: false,
    },
    activeTypePayment: {
      type: String,
      required: false,
      default: TYPES_PAYMENT.online,
    },
    pdfData: {
      type: Object,
      default: () => ({}),
    },
    couponRequestId: {
      type: [String, Number],
      default: null,
    },
    couponRequestEmail: {
      type: String,
      default: '',
    },
    couponId: {
      type: [String, Number],
      default: null,
    },
    paymentStatus: {
      type: String,
      default: null,
    },
    serverTimedelta: {
      type: [String, Number],
      default: 0,
    },
    clubCardLifetimeMonths: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    couponRequest: {
      id: null,
      lifeTime: null,
      price: null,
      fullPrice: null,
      dateCreated: null,
      clubCouponPrice: null,
      clubLpuPrice: null,
      cardPrice: null,
      clubCardLifetimeMonths: null,
      email: '',
    },
  }),
  computed: {
    isRenderParticipationRulesLinks() {
      return this.pdfData.coupon && this.pdfData.participation
    },
    cardExpiryTime() {
      if (Number(this.clubCardLifetimeMonths) === 6) {
        return 'полгода'
      }
    },
  },
}

export default ClubGlobalData
