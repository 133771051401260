import getTypeObject from 'utils/get/getTypeObject'
import getBooleanFromString from 'utils/get/getBooleanFromString'

const getStringTyping = value => {
  if (!value.length) {
    return null
  }

  if (value.toLowerCase() === 'none') {
    return null
  }

  if (getBooleanFromString(value) !== undefined) {
    return getBooleanFromString(value)
  }

  if (!Number.isNaN(Number(value))) {
    return Number(value)
  }

  return value
}

/**
 * @description
 *
 * Производит преобразование всех строковых значений объекта.
 * Если встречается в качестве значения объект - он преобразуется рекурсивно.
 * Если встречается массив - каждое значение будет преобразовано.
 * Строки, если это возможно, преобразуются в число.
 * Строки "True"/"False" будут преобразованы в булевые значение
 * В остальных случая, если строку преобразовать не получается - возвращается null.
 *
 * @param { Object } object - объект для преобразования
 * @return { Object } - преобразованный объект(его копия), оригинал остается без изменений
 * */

const getObjectTyping = object => (
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      const valueType = getTypeObject(value)

      if (valueType === 'string') {
        return [key, getStringTyping(value)]
      }

      if (valueType === 'object') {
        return [key, getObjectTyping(value)]
      }

      if (valueType === 'array') {
        return [key, value.map(val => {
          const valType = getTypeObject(val)

          if (valType === 'object') {
            return getObjectTyping(val)
          }

          if (valType === 'string') {
            return getStringTyping(val)
          }

          return val
        })]
      }

      return [key, value]
    }),
  )
)

export default getObjectTyping
