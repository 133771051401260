<template>
  <div
    class="dropdown-item"
    :class="{ 'dropdown-item_selected': isSelectedItem }"
    @click="handleClick"
  >
    <img
      v-if="transformItemData.itemImg"
      :src="transformItemData.itemImg"
      alt="аватар"
      width="40"
      height="40"
      class="dropdown-item__img mr-4"
    >
    <span
      v-else-if="transformItemData.itemIcon"
      class="ui-kit-color-icon-secondary dropdown-item__icon mr-8"
      :class="transformItemData.itemIcon"
    />
    <div>
      <div>
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="ui-text ui-text_body-1"
          :class="{ 'ui-kit-color-primary': isItemShowMore }"
          v-html="titleWithMarkedQuery"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <div
        v-if="transformItemData.itemSubtitle"
        class="ui-text ui-text_body-2 ui-kit-color-text-secondary"
      >
        {{ transformItemData.itemSubtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { escapeStringRegexp, isFeatureFlagEnabledHTML } from 'utils'

export default {
  name: 'SearchSiteDropdownItem',
  props: {
    itemData: {
      type: Object,
      default: () => ({}),
    },
    searchQuery: {
      type: String,
      default: '',
    },
    isSelectedItem: {
      type: Boolean,
      default: false,
    },
    presentTown: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      transformItemData: {
        itemImg: '',
        itemIcon: '',
        itemURL: '',
        itemSubtitle: '',
        itemTitle: this.itemData.value,
        itemTranslit: this.itemData.translit,
        itemTown: this.itemData.town_translit ?? this.presentTown,
      },
    }
  },
  computed: {
    isItemShowMore() {
      return ['all', 'bolezny_all', 'consult_extended_search'].indexOf(this.itemData.obj_type) !== -1
    },
    titleWithMarkedQuery() {
      const query = new RegExp(`(${escapeStringRegexp(this.searchQuery)})`, 'ig')
      const { itemTitle } = this.transformItemData

      if (typeof itemTitle === 'string') {
        return itemTitle.replace(query, '<span class="ui-text ui-text_subtitle-1 ui-kit-color-primary">$1</span>')
      }

      const stringItemTitle = itemTitle.toString()

      return stringItemTitle.replace(query, '<span class="ui-text ui-text_subtitle-1 ui-kit-color-primary">$1</span>')
    },
  },
  watch: {
    isSelectedItem(val, oldVal) {
      if (val && val !== oldVal) {
        this.$emit('dropdown-item:selected-item', {
          transformItemData: {
            ...this.transformItemData,
            isItemShowMore: this.isItemShowMore,
          },
        })
      }
    },
    itemData: {
      immediate: true,
      handler(val) {
        return this.processItemData(val)
      },
    },
  },
  methods: {
    processItemData(itemData) {
      if (itemData.obj_type === 'doctor') {
        if (itemData.hide_avatar) {
          this.transformItemData.itemImg = '/static/_v1/pd/icons/ui-kit/photo/no-avatar-with-text.svg'
        } else if (isFeatureFlagEnabledHTML({ dataAttr: 'data-ff-doctors-use-only-new-avatars' })) {
          const itemImg = itemData.square_avatar_small || itemData.square_avatar

          this.transformItemData.itemImg = itemImg ? `/media/${itemImg}` : '/static/_v1/pd/icons/ui-kit/photo/no-avatar.svg'
        } else {
          const itemImg = itemData.square_avatar_small || itemData.square_avatar || itemData.img_100

          this.transformItemData.itemImg = itemImg ? `/media/${itemImg}` : '/static/_v1/pd/icons/ui-kit/photo/no-avatar.svg'
        }

        this.transformItemData.itemSubtitle = itemData.speclist ? itemData.speclist.toLowerCase() : ''
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/vrach/${itemData.translit}/`
      }

      if (itemData.obj_type === 'lpu') {
        const itemImg = itemData.cropped_logo || itemData.img_100 || itemData.img_50

        this.transformItemData.itemImg = itemImg ? `/media/${itemImg}` : '/static/_v1/pd/icons/ui-kit/photo/no-avatar-lpu.svg'
        this.transformItemData.itemSubtitle = itemData.address ? itemData.address : ''
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/lpu/${itemData.translit}/`
      }

      if (itemData.obj_type === 'lputype') {
        this.transformItemData.itemIcon = 'ui-icon-clinic'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/top/${itemData.translit}/`
      }

      if (itemData.obj_type === 'lpuset') {
        this.transformItemData.itemImg = itemData.img_100 ? `/media/${itemData.img_100}` : ''

        if (!(this.transformItemData.itemImg)) {
          this.transformItemData.itemIcon = 'ui-icon-clinic'
        }

        this.transformItemData.itemSubtitle = itemData.lpus ? `cеть клиник: ${itemData.lpus}` : ''
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/set/${itemData.translit}/`
      }

      if (itemData.obj_type === 'speciality') {
        this.transformItemData.itemIcon = 'ui-icon-doctor'

        if (itemData.doctors) {
          this.transformItemData.itemSubtitle = itemData.is_medical ? `врачей: ${itemData.doctors}` : `специалистов: ${itemData.doctors}`
        }

        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/${itemData.translit}/`
      }

      if (itemData.obj_type === 'serviceclass') {
        this.transformItemData.itemIcon = 'ui-icon-layers'
        this.transformItemData.itemSubtitle = itemData.addresses ? `услуг: ${itemData.addresses}` : ''
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/${itemData.group}/${itemData.translit}/`
      }

      if (itemData.obj_type === 'servicetype') {
        this.transformItemData.itemIcon = 'ui-icon-layers'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/${itemData.group}/${itemData.translit}/`
      }

      if (itemData.obj_type === 'servicecategory') {
        this.transformItemData.itemIcon = 'ui-icon-layers'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/${itemData.group}/${itemData.translit}/`
      }

      if (itemData.obj_type === 'manipulation') {
        this.transformItemData.itemIcon = 'ui-icon-disease'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/lechenie/${itemData.translit}/`
      }

      if (itemData.obj_type === 'subways') {
        this.transformItemData.itemIcon = 'ui-icon-metro-alt'

        const subwayType = itemData.value.split(' ')

        if (subwayType[subwayType.length - 1] === 'Врачи') {
          this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/vrach/metro-${itemData.translit}/`
        } else {
          this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/lpu/metro-${itemData.translit}/`
        }
      }

      if (itemData.obj_type === 'town') {
        this.transformItemData.itemIcon = 'ui-icon-map-pin'
        this.transformItemData.itemURL = `/${itemData.translit}/`
      }

      if (itemData.obj_type === 'article') {
        this.transformItemData.itemTitle = itemData.manipulationtype__name
        this.transformItemData.itemTranslit = itemData.manipulationtype__translit
        this.transformItemData.itemSubtitle = itemData.author__fio_short
        this.transformItemData.itemURL = `/${itemData.manipulationtype__translit}/`
      }

      if (itemData.obj_type === 'clinical_case') {
        this.transformItemData.itemTitle = itemData.name
        this.transformItemData.itemTranslit = itemData.translit
        this.transformItemData.itemURL = `/${itemData.disease__translit}/case-${itemData.id}/`
      }

      if (itemData.obj_type === 'drug') {
        this.transformItemData.itemImg = itemData.avatar ? `/media/${itemData.avatar}` : '/static/_v1/pd/icons/search-drug.png'
        this.transformItemData.itemSubtitle = itemData.rates_count_text ? itemData.rates_count_text : ''
        this.transformItemData.itemURL = `/${itemData.translit}/`
      }

      if (itemData.obj_type === 'consult_subject') {
        this.transformItemData.itemURL = `/${itemData.spec_group__translit}/${itemData.translit}/`
      }

      if (itemData.obj_type === 'consult_extended_search') {
        this.transformItemData.itemURL = itemData.url
        this.transformItemData.itemTitle = itemData.name
      }

      if (itemData.obj_type === 'question') {
        this.transformItemData.itemURL = `/question/${itemData.value}/`
      }

      if (itemData.obj_type === 'all_towns') {
        this.transformItemData.itemIcon = 'ui-icon-map-pin'
        this.transformItemData.itemURL = '/'
      }

      if (itemData.obj_type === 'vrach_all') {
        this.transformItemData.itemIcon = 'ui-icon-doctor'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/vrach/`
      }

      if (itemData.obj_type === 'lpu_all') {
        this.transformItemData.itemIcon = 'ui-icon-clinic'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/lpu/`
      }

      if (itemData.obj_type === 'all') {
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/find/?q=${encodeURIComponent(this.searchQuery)}`
      }

      if (itemData.obj_type === 'bolezny_all') {
        this.transformItemData.itemURL = `/?q=${encodeURIComponent(this.searchQuery)}`
      }

      if (itemData.obj_type === 'drugtag') {
        this.transformItemData.itemURL = `/${itemData.translit}/`
      }

      if (itemData.obj_type === 'all_spec') {
        this.transformItemData.itemIcon = 'ui-icon-doctor'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/vrach/#all_spec`
      }

      if (itemData.obj_type === 'all_lputypes') {
        this.transformItemData.itemIcon = 'ui-icon-clinic'
        this.transformItemData.itemURL = `/${this.transformItemData.itemTown}/lpu/`
      }
    },
    handleClick() {
      this.$emit('search-site:submit-form')

      window.location = this.transformItemData.itemURL
    },
  },
}
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

.dropdown-item {
  display: flex;
  align-items: center;
  min-height: 44px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover,
  &_selected {
    background-color: $ui-kit-bg-gray-40;
    transition: background-color 0.15s ease-in-out;
  }

  &__img {
    flex: 0 0 auto;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    object-fit: contain;
    margin-right: 24px;
  }
}
</style>
