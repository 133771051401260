import { getGoalsList } from 'utils'

const VIDEO_CARD_GOALS = getGoalsList({
  block: 'videoCardDoctor',
  goals: [
    'play', // Видеовизитки, Страница врача - Запустили просмотр видео
    'finish', // Видеовизитки, Страница врача - Досмотрели видео до конца
    'closeWithoutView', // Видеовизитки, Страница врача - Закрыли модалку с видео, не досмотрев видео
  ],
})

export default VIDEO_CARD_GOALS
