var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      staticClass: "base-toolbar",
      class: { "base-toolbar_borderless": _vm.borderless },
      attrs: { flat: "" },
    },
    [
      _c(
        "v-btn",
        {
          attrs: { size: "24", color: "uiKitText", icon: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("input", false)
            },
          },
        },
        [_c("v-icon", { class: _vm.iconClasses })],
        1
      ),
      _vm._v(" "),
      _vm.title
        ? _c("v-toolbar-title", { staticClass: "ui-kit-color-text" }, [
            _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-spacer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }