/**
 * @description
 *
 * Сериализация объекта в строку key1=value1&...&keyN=valueN
 *
 * @param { Object } obj - объект для сериализации
 *
 * @return { String } объект в виде строки
 */

function serializeObject(obj) {
  return Object.entries(obj).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')
}

export default serializeObject
