import {
  format,
  addMinutes,
  addSeconds,
  differenceInMilliseconds,
} from 'date-fns'
import { requestTimeout, testRequiredOptions } from 'utils'
import { SECOND_TIMEOUT, TIME_FORMAT } from 'modules/ExpireTime/constants'

class ExpireTime {
  _requestStop = null

  constructor(options = {}) {
    this._createOptions(options)

    const hasOptions = this._testMergedOptions()

    if (!hasOptions) {
      return
    }

    this.mountContainer = this.options.mountSelector ? document.querySelector(this.options.mountSelector) : null

    this._timerStart()
  }

  init({ startDate } = {}) {
    if (!startDate) {
      return
    }

    this.options.startDate = startDate

    this._timerStart()
  }

  reset() {
    this._timerStop()
  }

  _parseDate(strDate) {
    const str = strDate.endsWith('Z') ? strDate.slice(0, -1) : strDate
    const [dateParts, timeParts] = str.split('T')
    const [year, month, day] = dateParts.split('-')
    const [hours = 0, minutes = 0, seconds = 0] = timeParts?.split(':') ?? []

    return new Date(Date.UTC(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hours) - this.options.serverTimedelta,
      Number(minutes),
      Math.round(seconds),
    ))
  }

  _timerStop() {
    if (this._requestStop) {
      this._requestStop()
      this._requestStop = null
    }

    this.options.afterCountdown()
  }

  _timerStart() {
    const { mountContainer } = this
    const {
      startDate,
      timeFormat,
      offsetMinutes,
      offsetSeconds,
    } = this.options
    const startDateUTC = this._parseDate(startDate)
    const dateEnd = addSeconds(addMinutes(startDateUTC, offsetMinutes), offsetSeconds)
    const diffDate = differenceInMilliseconds(dateEnd, new Date())

    this.options.beforeCountdown()

    // Если время таймера истекло
    if (diffDate <= 0) {
      this._timerStop()
      return
    }

    if (mountContainer) {
      mountContainer.textContent = format(diffDate, timeFormat)
    }

    const loop = () => {
      const timeLeft = differenceInMilliseconds(dateEnd, new Date())

      // Если время таймера истекло
      if (timeLeft <= 0) {
        this._timerStop()
        return
      }

      if (mountContainer) {
        mountContainer.textContent = format(timeLeft, timeFormat)
      }

      this._requestStop = requestTimeout(loop, SECOND_TIMEOUT)
    }

    // Рекурсивным тайм-аутом отображается обратный отсчёт времени
    this._requestStop = requestTimeout(loop, SECOND_TIMEOUT)
  }

  _testMergedOptions() {
    const { startDate, offsetMinutes } = this.options

    return testRequiredOptions({
      mode: 'error',
      module: 'ExpireTime',
      requiredOptions: {
        startDate,
        offsetMinutes,
      },
    })
  }

  _createOptions(options = {}) {
    this.options = {
      startDate: null,
      timeFormat: TIME_FORMAT,
      offsetMinutes: 0,
      offsetSeconds: 0,
      serverTimedelta: 0,
      mountSelector: null,
      afterCountdown: () => {},
      beforeCountdown: () => {},
      ...options,
    }
  }
}

export default ExpireTime
