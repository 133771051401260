import createDevNotice from 'utils/executors/createDevNotice'

/**
 * @description
 *
 * Асинхронно загружает скрипт через тэг `body`.
 *
 * @param { String } src - путь, откуда брать скрипт
 *
 * @return { Promise }
 */

function loadExternalScript(src) {
  return new Promise((resolve, reject) => {
    if (!src) {
      createDevNotice({
        mode: 'error',
        module: 'utils',
        method: 'loadExternalScript',
        description: 'Не передан путь для загрузки скрипта',
      })

      reject()
    }

    const script = document.createElement('script')

    script.async = true
    script.src = src

    script.onload = () => resolve()
    script.onerror = err => reject(err)

    document.body.appendChild(script)
  })
}

export default loadExternalScript
