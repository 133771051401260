export const MOUNT_SELECTOR = '.vue-the-header-mount'

export const HEADER_EVENT_NAMES = {
  headerStickyHide: 'the-header-sticky:hide',
}

export const SITE_LOGO = {
  pd: {
    src: '/static/_v1/pd/logos/ui-kit/prodoctorov-logo.svg',
    alt: 'ПроДокторов',
  },
}

export const THROTTLE_DELAY = 200

// Указывает расстояние от начал страницы после которого будет фиксироваться шапка
export const OFFSET_HEADER_STICKY = 150
