import JSON5 from 'process-js-objects'

/**
 * @description
 *
 * Проверка переданного аргумента на формат JSON5
 *
 * @param { * } data - аргумент для проверки
 *
 * @return { Boolean } - булевое значение проверки
 * */

function isJSON5(data) {
  try {
    JSON5.parse(data)
    return true
  } catch (error) {
    return false
  }
}

export default isJSON5
