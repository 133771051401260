import { CLUB_EVENTS, USER_AUTH_EVENTS } from 'www/constants'

export const getRedirectData = () => ({
  appointmentData: { // Интерфейс взят из файла 'appointment-modules.js', метод 'redirectToNewAppointmentClinic'
    lpu: '',
    slot: '',
    domain: '',
    dTypeId: '',
    dClassId: '',
    deviceId: '',
    timedelta: '',
    synonymId: '',
    misServiceId: '',
  },
  isRedirectViaBanner: false,
})

export const getClubData = () => ({
  isInClub: null,
  cardMtLink: '',
  cardDateEnd: '',
  cardDateStart: '',
  cardNextPrice: null,
})

export const getStyleData = () => ({
  minHeight: '35vh',
})

export const getPaymentMadeData = () => ({
  isRedirectViaBanner: true,
  isShowConfetti: false,
})

export const getErrorData = () => ({
  title: 'Произошла ошибка',
  message: 'Обновите страницу и попробуйте ещё раз',
  buttonState: {
    submit: { action: 'reload', text: 'Обновить' },
    cancel: null,
  },
})

export const getErrorDataForStaff = () => ({
  title: 'Недоступно для рабочих аккаунтов',
  message: 'Чтобы пользоваться клубом, войдите как пациент',
  buttonState: {
    submit: { action: 'close', text: 'Понятно' },
    cancel: null,
  },
})

export const getErrorDataForCancelPayment = () => ({
  title: 'Платёж не прошёл',
  message: 'Деньги с вашей карты не списаны. Попробуйте оплатить ещё раз.',
  buttonState: {
    submit: { action: 'retry', text: 'Повторить' },
    cancel: { action: 'close', text: 'Отменить' },
  },
})

export const eventsData = {
  club: CLUB_EVENTS,
  userAuth: USER_AUTH_EVENTS,
}
