import ExpireTime from 'modules/ExpireTime'
import { WAITING_TIME_RE_REQUEST } from 'components/common/UserAuthorization/constants'

/**
 * В данном миксине содержатся данные, которые относятся к таймеру
 * */

const authByCallOrSmsExpireTimeMixin = {
  data: () => ({
    expireTime: {
      instance: null,
      isRunning: false,
      waitingTime: WAITING_TIME_RE_REQUEST,
      mountElementId: 'user-authorization-timer',
    },
  }),
  methods: {
    initTimer() {
      this.resetTimer()

      this.expireTime.instance = new ExpireTime({
        offsetSeconds: this.expireTime.waitingTime,
        startDate: new Date().toISOString(),
        mountSelector: `#${this.expireTime.mountElementId}`,
        beforeCountdown: () => {
          this.expireTime.isRunning = true
        },
        afterCountdown: () => {
          this.expireTime.isRunning = false

          if (this.requestGetCodeData.isWaitingTime) {
            this.requestGetCodeData.isWaitingTime = false
            this.requestGetCodeData.isRequested = false
            this.expireTime.waitingTime = WAITING_TIME_RE_REQUEST
          }
        },
      })
    },
    resetTimer() {
      if (this.expireTime.instance) {
        this.expireTime.instance.reset()
      }

      this.expireTime.isRunning = false
    },
  },
}

export default authByCallOrSmsExpireTimeMixin
