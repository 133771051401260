/**
 * @description
 *
 * Преобразует переданное значение к числу.
 * Если преобразование происходит неудачно - возвращается 0.
 * В любом случае вернется число
 *
 * @param { any } int - аргумент любого типа
 * @return { Number } - 0 или преобразованное число
 * */

const transformToNumber = int => {
  try {
    return Number(int) || 0
  } catch {
    return 0
  }
}

export default transformToNumber
