import { createDevNotice, getOnlyNumbers } from 'utils'
import { PHONE_DATA_BY_COUNTRY_ISO } from 'components/common/mixins/CountryData/constants'
import { getPhoneOptions } from 'components/common/mixins/CountryData/functions'

export default {
  name: 'CountryData',
  data() {
    const countryIso = window.COUNTRY_DATA.iso || ''
    const phoneDataByCountryIso = PHONE_DATA_BY_COUNTRY_ISO[countryIso.toLowerCase()]

    if (!countryIso) {
      createDevNotice({
        module: 'mixins/CountryData',
        method: 'data',
        message: 'Не передан ISO-код страны.',
      })

      return {
        country: null,
      }
    }

    if (!phoneDataByCountryIso) {
      createDevNotice({
        module: 'mixins/CountryData',
        method: 'data',
        message: `Данные номера телефона по указанному ISO-коду страны (${window.COUNTRY_DATA.iso}) не найдены.`,
      })

      return {
        country: { iso: countryIso },
      }
    }

    const { code } = window.COUNTRY_DATA.phone
    const codeLength = getOnlyNumbers(code).length
    const {
      masks,
      tokens,
      formattedMasks,
      regexps,
    } = getPhoneOptions(phoneDataByCountryIso, code)

    return {
      country: {
        iso: countryIso,
        phone: {
          minLength: phoneDataByCountryIso.minBodyLength + codeLength,
          maxLength: phoneDataByCountryIso.maxBodyLength + codeLength,
          code,
          maskOptions: {
            mask: masks,
            tokens,
          },
          formattedMasks,
          regexps,
        },
      },
    }
  },
}
