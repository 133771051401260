import createDevNotice from 'utils/executors/createDevNotice'

/**
 * @description
 *
 * Перезаписывает содержимое DOM элемента
 *
 * @param { String } selector -  элемент у которого меняем содержимое
 * @param { String } html - на что перезаписываем содержимое DOM элемента
 */

function replaceInnerHtml(selector, html) {
  const el = document.querySelector(selector)

  if (!el) {
    return
  }

  if (typeof html !== 'string') {
    createDevNotice({
      mode: 'warn',
      module: 'replaceInnerHtml',
      description: 'Некорректное содержимое аргумента "html"',
    })

    return
  }

  el.innerHTML = html
}

export default replaceInnerHtml
