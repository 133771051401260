import JSON5 from 'process-js-objects'
import isJSON5 from 'utils/is/isJSON5'

/**
 * @description
 *
 * Получаю массив со страницы из data атрибута в формате JSON
 *
 * @param { Object } options
 * @param { String } options.dataName - название атрибута, по которому будет производиться поиск и сбор
 * @param { HTMLElement|Node } options.container - значение dataName возьмется только у этого узла либо у всех [dataName] внутри него. По умолчанию document
 *
 * @return { Array<Object<HTMLElement|Node Object>> } - массив с данными
 *
 * @example
 *
 * [{
 *     node - узел, у которого взято значение
 *     data - само значение или null если не в формате JSON
 * }]
 */

function getDataJSON({ dataName, container = document }) {
  const returnedData = []

  if (!container) {
    return returnedData
  }

  if (container !== document) {
    const value = container.getAttribute(dataName)

    if (value) {
      returnedData.push({
        node: container,
        data: isJSON5(value) ? JSON5.parse(value) : null,
      })

      return returnedData
    }
  }

  const nodes = container.querySelectorAll(`[${dataName}]`)

  Array.from(nodes).forEach(node => {
    const value = node.getAttribute(dataName)

    returnedData.push({
      node,
      data: isJSON5(value) ? JSON5.parse(value) : null,
    })
  })

  return returnedData
}

export default getDataJSON
