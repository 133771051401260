export const GECKO = 'gecko'
export const BLINK = 'blink'
export const SAFARI = 'safari'
export const WEBKIT = 'webkit'
export const TRIDENT = 'trident'
export const EDGEHTML = 'edgehtml'

export const SUPPORTED_BROWSER_DATA = {
  /**
     * Список основных поддерживаемых браузерных движков
     * */
  supportedEngineList: [
    BLINK,
    GECKO,
    WEBKIT,
  ],
  /**
     * Список имен браузеров, которые возвращает "UAParser", но которых нет в "browserslist"
     * Список содержит только те браузеры, которых нет в списке "browserslist", но которые поддерживаются на PD
     * Достаточно указать только название и минимальную поддерживаемую версию(также нужно связать ключ со списком "compatibleNamesList").
     * Данные собраны на основе статистики в Яндекс.Метрике
     * */
  supportedNamesData: {
    miui: { min: '11' },
    yandex: { min: '18' },
    huawei: { min: '10' },
    chromium: { min: '2019' },
  },
  /**
     * Список для связки данных из "browserslist" и "UAParser"
     * Ключ - название браузера получаемое из "UAParser"
     * Значение - имя получаемое из списка "browserslist" или ключ из списка "supportedNamesData")
     * */
  compatibleNamesList: {
    yandex: 'yandex', // этого имени нет в списке "browserslist"
    iridium: 'chromium', // этого имени нет в списке "browserslist"
    'miui browser': 'miui', // этого имени нет в списке "browserslist"
    'opera mobi': 'op_mob',
    'opera mini': 'op_mini',
    'huawei browser': 'huawei', // этого имени нет в списке "browserslist"
    'mobile chrome': 'chrome',
    'mobile safari': 'ios_saf',
    'samsung browser': 'samsung',
    'mobile firefox': 'firefox',
    'android browser': 'android',
  },
}
