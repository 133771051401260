/**
 * @description
 *
 * Проверка sessionStorage на возможность использования
 *
 * @return { Boolean } - булевое значение тестирования
 */

function isUsedSessionStorage() {
  try {
    window.sessionStorage.setItem('__sessionStorageTest', '')
    window.sessionStorage.removeItem('__sessionStorageTest')

    return true
  } catch (error) {
    return false
  }
}

export default isUsedSessionStorage
