var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-getting-payment-made",
      attrs: { "data-qa": "card_getting_payment_made" },
    },
    [
      _c("VImg", {
        staticClass: "mx-auto mb-2",
        attrs: {
          width: "72",
          height: "72",
          src: "/static/_v1/pd/icons/ui-kit/club/club-check.svg",
          alt: "Иконка успешной оплаты",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ui-text ui-text_h5 ui-kit-color-text text-center",
          attrs: { "data-qa": "card_getting_payment_made_title" },
        },
        [_vm._v("\n    Вы в клубе\n  ")]
      ),
      _vm._v(" "),
      _vm.clubData.cardDateEnd
        ? _c(
            "div",
            {
              staticClass:
                "ui-text ui-text_body-1 ui-kit-color-text-secondary text-center mt-1",
              attrs: { "data-qa": "card_getting_payment_made_date_end" },
            },
            [_vm._v("\n    до " + _vm._s(_vm.clubData.cardDateEnd) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.componentProps.isRedirectViaBanner
        ? [
            _c(
              "VBtn",
              {
                staticClass: "mt-4",
                attrs: {
                  block: "",
                  color: "primary",
                  elevation: "0",
                  "data-qa": "card_getting_payment_made_btn_cancel",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit(_vm.events.club.cardGettingCancel)
                  },
                },
              },
              [_vm._v("\n      Отлично\n    ")]
            ),
            _vm._v(" "),
            _c(
              "VBtn",
              {
                staticClass: "mt-2",
                attrs: {
                  text: "",
                  block: "",
                  tag: "a",
                  color: "primary",
                  "data-qa": "card_getting_payment_made_open_card_info",
                },
                on: { click: _vm.handleClickCardInfo },
              },
              [_vm._v("\n      Открыть клубную карту\n    ")]
            ),
          ]
        : [
            _c(
              "VBtn",
              {
                staticClass: "mt-4",
                attrs: {
                  block: "",
                  color: "primary",
                  elevation: "0",
                  "data-qa": "card_getting_payment_made_btn_redirect",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit(_vm.events.club.cardGettingRedirect)
                  },
                },
              },
              [_vm._v("\n      Продолжить\n    ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "ui-text ui-text_body-1 ui-kit-color-text-secondary text-center mt-4",
        attrs: { "data-qa": "card_getting_payment_made_description" },
      },
      [
        _vm._v("\n    Чтобы получить услугу по клубной цене,"),
        _c("br"),
        _vm._v("\n    покажите администратору клиники клубную"),
        _c("br"),
        _vm._v("\n    карту в своём профиле\n  "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }