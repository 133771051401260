var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "the-header-line",
      class: {
        "the-header-line_sticky": _vm.isSticky,
        "the-header-line_sticky-active":
          _vm.isSticky && (_vm.isStickyActive || _vm.isStickyActiveHard),
      },
    },
    [
      _c("div", { staticClass: "the-header-line__inner" }, [
        _c("button", {
          staticClass: "the-header-line__hamburger-btn ui-icon-menu p-3",
          attrs: {
            type: "button",
            "aria-hidden": "false",
            "data-role": "none",
            "data-qa": "the_header_hamburger_btn",
          },
          on: { click: _vm.handleClickHamburger },
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "d-flex",
            attrs: { href: "/", "data-qa": "the_header_logo_link" },
          },
          [
            _c("img", {
              staticClass: "the-header-line__logo",
              attrs: { src: _vm.siteLogo.src, alt: _vm.siteLogo.alt },
            }),
          ]
        ),
        _vm._v(" "),
        _vm.search.useRender
          ? _c(
              "label",
              {
                staticClass: "the-header-line__search-btn ui-icon-search p-3",
                attrs: { "data-qa": "the_header_search_btn" },
              },
              [
                _c("input", {
                  staticClass: "the-header-line__keyboard-input",
                  attrs: { type: "text", "data-role": "none" },
                  on: { focus: _vm.handleClickSearchBtn },
                }),
              ]
            )
          : _c("div", { staticClass: "the-header-line__search-btn-empty" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }