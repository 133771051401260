/**
 * @description
 *
 * Возвращает тип объекта в нижнем регистре (object, array, function, date и все прочие разновидности)
 *
 * @param { * } data - любой тип данных
 *
 * @return { String } - тип объекта
 * */

function getTypeObject(data) {
  return {}.toString.call(data).slice(8, -1).toLowerCase()
}

export default getTypeObject
