/**
 * @description
 * Удаляет значение query параметра для текущего урла с помощью replaceState
 * @param {string} name
 * */

const deleteQueryParam = name => {
  const url = new URL(window.location)

  url.searchParams.delete(name)

  window.history.replaceState(window.history.state, '', url)
}

export default deleteQueryParam
