/**
 * @description
 *
 * Возвращает булевое значение или undefined, преобразуя значение строки к булевому. Регистр не важен
 *
 * @param { * } value
 *
 * @return { Boolean | undefined } - 'true' -> true || 'false' -> false || undefined
 * */

function getBooleanFromString(value) {
  if (typeof value === 'boolean') {
    return value
  }

  if (typeof value !== 'string') {
    return
  }

  if (value.toLowerCase() === 'true') {
    return true
  }

  if (value.toLowerCase() === 'false') {
    return false
  }
}

export default getBooleanFromString
