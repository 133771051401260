import ServerListener from 'modules/ServerListener'
import { AppointmentModules } from 'modules/appointment-modules'

import {
  getQueryParam,
  deleteQueryParam,
  getObjectQueryParams,
  transformObjectToClean,
  dispatchCustomEventGlobally,
  getBooleanFromString,
  scrollLock,
} from 'utils'

import {
  createFreeClubCard,
  createPaidClubCard,
  getUserClubCardData,
} from 'components/common/Club/components/core/ClubCardGetting/api'

import {
  eventsData,
  getStyleData,
  getClubData,
  getRedirectData,
  getPaymentMadeData,
  getErrorDataForStaff,
  getErrorDataForCancelPayment,
  getErrorData,
} from 'components/common/Club/components/core/ClubCardGetting/interfaces'

import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import { CLUB_CARD_GETTING_YA_GOALS } from 'yandexGoals'

import { createSentryLog } from 'components/common/Club/components/core/ClubCardGetting/functions'
import { steps, PAYMENT_STATUSES, ALFA_BANK_PAYMENT_HOST_NAME } from 'components/common/Club/components/core/ClubCardGetting/constants'

const appointmentMethods = new AppointmentModules()

export default {
  mixins: [
    ManageYandexGoals,
  ],
  data: vm => ({
    steps,
    state: {
      step: 0,
      isEnabledWidget: false,
    },
    events: eventsData,
    clubData: window.USER.club || getClubData(),
    userPhone: '',
    styleData: getStyleData(),
    errorData: getErrorData(),
    redirectData: getRedirectData(),
    paymentMadeData: getPaymentMadeData(),
    yaGoalsList: CLUB_CARD_GETTING_YA_GOALS,
    userIsAuthenticated: !window.USER.isAnonymous,
    instanceServerListener: null,
    errorButtonActions: {
      close: () => vm.closeWidget(),
      retry: () => vm.setStep('emailEnter'),
      reload: () => window.location.reload(),
    },
  }),
  created() {
    const { cardGettingOpen, detailInfoBannerClick } = this.events.club

    window.addEventListener(cardGettingOpen, this.handleOpenWidget)
    window.addEventListener(detailInfoBannerClick, this.handleOpenWidget)
  },
  mounted() {
    this.handleOpenWidgetAfterRedirect()
  },
  computed: {
    showConfettiImage() {
      return (this.state.step === this.steps.paymentMade) && this.paymentMadeData.isShowConfetti
    },
  },
  methods: {
    openWidget() {
      this.state.isEnabledWidget = true

      scrollLock.enable()
    },
    closeWidget() {
      this.state.isEnabledWidget = false

      scrollLock.disable()

      this.sendYandexGoal('closeBottomSheet')
      this.handleQueryParams()
    },
    removeSkeleton() {
      /**
             * Т.к. удаляемый по таймауту класс перебивает стили анимации для бесшовного
             * перехода skeleton'а к настоящему компоненту, то класс, соответственно,
             * нельзя удалять до завершения анимации, которая длится 300 ms
             * */
      setTimeout(() => {
        document.body.classList.remove('club-card-getting-skeleton')
      }, 300)
    },
    handleOpenWidget({ detail } = {}) {
      if (!detail) {
        createSentryLog('Не передано обязательное поле "detail"')
        return
      }

      if (window.USER.isStaff || window.USER.isDoctor || window.USER.isLpu) {
        this.showErrorForStaff()
        this.openWidget()
        return
      }

      this.redirectData = detail

      this.setUserClubState()
      this.openWidget()
    },
    handleEventConfirmed(userPhone) {
      this.userPhone = userPhone
      this.userIsAuthenticated = true

      this.setStep('preloaderText')
      this.requestToUserClubCardData()
    },
    handleEventRedirectOrCancel() {
      if (this.redirectData.isRedirectViaBanner) {
        this.closeWidget()
        return
      }

      this.redirectToNewAppointment()
    },
    async handleCardGettingSubmit() {
      if (this.clubData.email) {
        this.setStep('preloaderOnly')
        this.handleEventEmailSent(this.clubData.email)

        return
      }

      if (this.clubData.cardNextPrice) {
        this.setStep('emailEnter')
        return
      }

      try {
        const {
          mt_link: cardMtLink,
          dt_end: cardDateEnd,
        } = await createFreeClubCard()

        this.updateUserClubCardData({
          isInClub: true,
          cardMtLink,
          cardDateEnd,
        })

        this.paymentMadeData.isRedirectViaBanner = this.redirectData.isRedirectViaBanner
        this.paymentMadeData.isShowConfetti = true

        this.setStep('paymentMade')
      } catch (e) {
        this.handleError(e)
      }
    },
    handleEventEmailSent(email) {
      this.redirectToPayment(email)
    },
    setStep(key) {
      this.state.step = this.steps[key]
    },
    redirectToNewAppointment() {
      appointmentMethods.redirectToNewAppointmentClinic(this.redirectData.appointmentData)
    },
    setUserClubState() {
      if (!this.userIsAuthenticated) {
        this.sendYandexGoal(this.redirectData.isRedirectViaBanner ? 'seeConfirmationViaBanner' : 'seeConfirmationViaAppointment')
        this.setStep('confirmation')

        return
      }

      if (this.clubData.isInClub) {
        this.paymentMadeData.isRedirectViaBanner = this.redirectData.isRedirectViaBanner

        this.sendYandexGoal('seePaymentMade')
        this.setStep('paymentMade')

        return
      }

      this.sendYandexGoal('seeClubEnter')
      this.setStep('clubEnter')
    },
    async redirectToPayment(email) {
      try {
        this.setStep('preloaderOnly')

        const response = await createPaidClubCard({
          email,
          returnUrl: this.createReturnUrl(),
        })

        const { provider_payment_data: paymentData } = response.payment_credentials

        if (paymentData.url) {
          this.sendYandexGoal('successRedirectToAlfa')

          window.location.assign(paymentData.url)

          return
        }

        createSentryLog(`Не предоставлен URL для редиректа для оплаты. Ответ: "${paymentData}"`)

        this.sendYandexGoal('errorRedirectToAlfa')
        this.showDefaultError()
      } catch (e) {
        this.sendYandexGoal('errorRedirectToAlfa')
        this.handleError(e)
      }
    },
    async requestToUserClubCardData() {
      try {
        const userClubData = await getUserClubCardData()

        this.updateUserClubCardData(userClubData)
        this.setUserClubState()
      } catch (e) {
        this.handleError(e)
      }
    },
    updateUserClubCardData(newUserData) {
      this.clubData = {
        ...this.clubData,
        ...newUserData,
      }

      dispatchCustomEventGlobally('club-service-discount:updated-user-data', {
        userClubData: this.clubData,
      })
    },
    handleOpenWidgetAfterRedirect() {
      this.removeSkeleton()

      const requestIdQuery = getQueryParam('card_request_id')
      const requestId = parseInt(requestIdQuery, 10)
      const isValidRequestId = !Number.isNaN(requestId)
      const isRedirectFromAlfaBank = document.referrer.includes(ALFA_BANK_PAYMENT_HOST_NAME)

      if (isRedirectFromAlfaBank && isValidRequestId) {
        this.sendYandexGoal('seePreloaderAfterRedirectFromAlfa')
        this.setStep('preloaderOnly')
        this.openWidget()

        this.instanceServerListener = new ServerListener({
          socketData: {
            url: `wss://${window.location.host}/ws/club_card_request?subscribe-user`,
            receiveCallback: this.handlePaymentStatus,
            errorCallback: this.handleError,
          },
          requestData: {
            timeout: 5000,
            url: `/club/ajax/card/request/${requestId}/status/`,
            successCallback: this.handlePaymentStatus,
            errorCallback: this.handleError,
          },
        })
      }
    },
    handlePaymentStatus({ data } = {}) {
      const { status } = data

      // TODO: удалить статус expired после того как его уберут на бэкенде
      if (status === PAYMENT_STATUSES.inProcess || status === PAYMENT_STATUSES.expired) {
        return
      }

      this.instanceServerListener.disconnect()

      const {
        dt_end: cardDateEnd,
        dt_start: cardDateStart,
        mt_link: cardMtLink,
      } = data

      this.updateUserClubCardData({
        cardDateEnd,
        cardDateStart,
        cardMtLink,
      })

      if (status === PAYMENT_STATUSES.paid) {
        this.handleQueryParams()
        this.updateUserClubCardData({ isInClub: true })

        this.paymentMadeData.isRedirectViaBanner = this.redirectData.isRedirectViaBanner
        this.paymentMadeData.isShowConfetti = true

        this.sendYandexGoal('seePaymentSuccess')
        this.setStep('paymentMade')

        return
      }

      if (status === PAYMENT_STATUSES.canceled || status === PAYMENT_STATUSES.paymentFailed) {
        this.handleQueryParams()
        this.sendYandexGoal('seePaymentError')
        this.showErrorForCancelPayment()

        return
      }

      createSentryLog(`Неизвестный статус ответа. Статус: "${status}"`)

      this.sendYandexGoal('seePaymentError')
      this.showDefaultError()
    },
    createReturnUrl() {
      const cleanAppointmentData = transformObjectToClean(this.redirectData.appointmentData, true)

      /* Очищаем слот для того, чтобы пользователь повторно выбрал время приёма. Это позволит избежать
            проблем когда слот может занять кто-нибудь другой, в момент оплаты клубной карты */
      delete cleanAppointmentData.slot

      const customCurrentUrl = new URL(window.location.href)
      const queryString = new URLSearchParams({
        ...cleanAppointmentData,
        isRedirectViaBanner: this.redirectData.isRedirectViaBanner,
      }).toString()

      if (customCurrentUrl.search) {
        customCurrentUrl.search += `&${queryString}`
      }

      customCurrentUrl.search = customCurrentUrl.search ? `${customCurrentUrl.search}&${queryString}` : `?${queryString}`

      return customCurrentUrl.href
    },
    handleQueryParams() {
      const queryParamsObject = getObjectQueryParams()
      const queryParamsObjectKeys = Object.keys(queryParamsObject)

      if (!queryParamsObjectKeys.length) {
        return
      }

      // Находим все свойства appointmentData которые находятся в query-параметрах и обновляем их
      queryParamsObjectKeys.forEach(key => {
        if (this.redirectData.appointmentData.hasOwnProperty(key)) {
          this.redirectData.appointmentData[key] = queryParamsObject[key]

          deleteQueryParam(key)
        }
      })

      if (queryParamsObject.isRedirectViaBanner) {
        this.redirectData.isRedirectViaBanner = getBooleanFromString(queryParamsObject.isRedirectViaBanner)
      }

      deleteQueryParam('isRedirectViaBanner')
      deleteQueryParam('card_request_id')
      deleteQueryParam('orderId')
      deleteQueryParam('lang')
    },
    handleError({ data, message, status } = {}) {
      if (status === 403) {
        // - Учетные данные не были предоставлены
        // - CSRF Failed: CSRF token missing or incorrect

        createSentryLog(data.detail)

        this.showDefaultError()

        return
      }

      // eslint-disable-next-line camelcase
      if (data?.button_state) {
        this.errorData = {
          title: data.title,
          message: data.message,
          buttonState: data.button_state,
        }

        createSentryLog(`${data.message} Код ошибки: "${data.code}"`)

        this.setStep('error')

        return
      }

      const errorInfo = message || JSON.stringify(data)

      createSentryLog(`Неизвестная ошибка. Данные ошибки: "${errorInfo}"`)

      this.showDefaultError()
    },
    handleActionInError(actionName) {
      const fn = this.errorButtonActions[actionName]

      if (typeof fn === 'function') {
        fn()

        return
      }

      createSentryLog('Во время ошибки не было вызвано целевое действие из-за отсутствия данных на backend')

      this.showDefaultError()
    },
    showDefaultError() {
      this.errorData = getErrorData()
      this.setStep('error')
    },
    showErrorForStaff() {
      this.errorData = getErrorDataForStaff()
      this.setStep('error')
    },
    showErrorForCancelPayment() {
      this.errorData = getErrorDataForCancelPayment()
      this.setStep('error')
    },
  },
}
