import getBooleanFromString from 'utils/get/getBooleanFromString'

/**
 * @description
 *
 * Трансформирует поля переданного объекта в 'Boolean' значение
 *
 * @params { Object } object - объект, в котором необходимо произвести трансформацию
 * @params { Array<string> } propList - список ключей
 * */

function transformPropsToBoolean(object = {}, propList = []) {
  propList.forEach(prop => {
    if (object[prop]) {
      object[prop] = getBooleanFromString(object[prop])
    }
  })
}

export default transformPropsToBoolean
