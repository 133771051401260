let url = `${window.MEDTOCHKA_URL}/services/sso/login/`

let getParams = '?'

if (window.FEATURE_FLAGS.dbPreparationCisPhone) {
  getParams += `preselected_country=${window.COUNTRY_DATA.iso}&`
}

if (window.FEATURE_FLAGS.cisAddClientIdParam) {
  getParams += `client_id=${window.MEDTOCHKA_CLIENT_ID}&`
}

if (window.FEATURE_FLAGS.cisAddClientIdParam || window.FEATURE_FLAGS.dbPreparationCisPhone) {
  getParams += `next=${encodeURIComponent(window.location.href)}`

  url += getParams
}

export const LOGIN_LINKS = {
  get medtochka() {
    return window.FEATURE_FLAGS.cisAddClientIdParam || window.FEATURE_FLAGS.dbPreparationCisPhone
      ? url
      : `${window.MEDTOCHKA_URL}/services/sso/login/?client_id=prodoctorov&next=${encodeURIComponent(window.location.href)}`
  },
  get cabinet() {
    return `https://${window.PRODOCTOROV_DOMAIN}/profile/login/?next=${encodeURIComponent(window.location.href)}`
  },
}
