/**
 * @description
 *
 * Помещает URL в историю браузера.
 *
 * @param { String } url - страница записи
 * @param { Object } state - данные состояния
 */

function pushHistoryState(url, state) {
  window.history.pushState(state, null, url)
}

export default pushHistoryState
