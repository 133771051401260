import { axiosClient, getCSRFToken } from 'utils'

/**
 * Создаёт платную клубную карту
 * (если пользователь уже был в клубе, и у него истекла предыдущая карта)
 *
 * @param { Object } payload
 * @param { String } payload.email - Email, на который придёт чек об оплате.
 * @param { String } payload.returnUrl - Ссылка, куда вернется пользователь после виджета оплаты.
 *
 * @example
 * response = {
 *     payment_credentials: {
 *         provider: 'alfabank',
 *         provider_payment_data: {
 *             url: 'https://web.rbsuat.com/ab/merchants/typical/payment_ru.html?mdOrder=06b65753-6db6-76ef-aa82-2626020983dd',
 *             widget_credentials: {},
 *         },
 *     },
 * }
 *
 * @returns {Promise}
 * */

function createPaidClubCard({ email, returnUrl } = {}) {
  return new Promise((resolve, reject) => {
    axiosClient({
      url: '/club/ajax/card/request/',
      method: 'POST',
      withCredentials: true,
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'Content-Type': 'application/json',
      },
      data: {
        return_url: returnUrl,
        receipt_email: email,
      },
    })
      .then(({ data }) => resolve(data))
      .catch(({ response }) => reject(response))
  })
}

export default createPaidClubCard
