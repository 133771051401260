import getTypeObject from 'utils/get/getTypeObject'

/**
 * @description
 *
 * Глубокое слияние целевого объекта с любым количеством объектов. Любой уровень вложенности
 *
 * @param { Object } target - целевой объект слияния
 * @param { ...Object } rest - объекты для слияния с целевым объектом
 *
 * @return { Object } - целевой объект
 */

function deepMerge(target, ...rest) {
  rest.forEach(current => {
    Object.keys(current)
      .forEach(key => {
        if (getTypeObject(current[key]) === 'object' && getTypeObject(target[key]) === 'object') {
          deepMerge(target[key], current[key])
        } else {
          target[key] = current[key]
        }
      })
  })

  return target
}

export default deepMerge
