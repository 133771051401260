var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: _vm.persistent,
        transition: "dialog-bottom-transition",
        fullscreen: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("BaseToolbar", {
            attrs: { "toolbar-icon": _vm.toolbarIcon, title: _vm.title },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "px-2" }, [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }