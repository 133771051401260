/**
 * @description Функция возвращает объект с пространством имен модуля
 *
 * @param { Object } object
 * @param { string } object.baseModifier - Базовый модификатор
 * @param { string } object.customModifier - Кастомный модификатор
 * @param { boolean= } object.isMobile - Флаг, указывающий на тип устройства для которого будет генерироваться пространство имён
 * */

function getNamespace({
  baseModifier,
  customModifier,
  isMobile,
}) {
  const deviceName = isMobile ? 'mobile' : 'full'

  return {
    block: {
      base: baseModifier,
      modifier: customModifier,
    },
    blockDeviceName: {
      base: `${baseModifier}_${deviceName}`,
      modifier: customModifier ? `${customModifier}_${deviceName}` : null,
    },
    layer: {
      base: `${baseModifier}__layer`,
      modifier: customModifier ? `${customModifier}__layer` : null,
    },
    content: {
      base: `${baseModifier}__content`,
      modifier: customModifier ? `${customModifier}__content` : null,
    },
    background: {
      base: `${baseModifier}__background`,
      modifier: customModifier ? `${customModifier}__background` : null,
    },
  }
}

/**
 * @description Функция возвращает объект с классами для HTML-элементов
 *
 * @param { Object } object - Содержит список классов для узлов
 * */

function getClassListHTML({
  block,
  blockDeviceName,
  layer,
  content,
  background,
}) {
  return {
    block: block.modifier ? `${block.base} ${block.modifier} ${blockDeviceName.base} ${blockDeviceName.modifier}` : `${block.base} ${blockDeviceName.base}`,
    layer: layer.modifier ? `${layer.base} ${layer.modifier}` : layer.base,
    content: content.modifier ? `${content.base} ${content.modifier}` : content.base,
    background: background.modifier ? `${background.base} ${background.modifier}` : background.base,
  }
}

export {
  getNamespace,
  getClassListHTML,
}
