<template>
  <div
    ref="searchDropdown"
    class="search-site-dropdown"
  >
    <div
      v-if="searchResults.length && searchQuery"
      class="search-site-dropdown__list"
      data-qa="search_site_dropdown_list"
    >
      <SearchSiteDropdownItem
        v-for="(item, index) in searchResults"
        :key="`${index}${item.value}`"
        :item-data="item"
        :search-query="searchQuery"
        :present-town="location.town"
        :is-selected-item="index === indexSelectedItem"
        class="search-site-dropdown__item"
        data-qa="search_site_dropdown_list_item"
        @dropdown-item:selected-item="handleSelectedItem"
        @search-site:submit-form="$emit('search-site:submit-form')"
      />
    </div>
    <div
      v-else
      class="search-site-dropdown__preview-text ui-text ui-text_body-1 ui-kit-color-text"
      data-qa="search_site_dropdown_preview_text"
    >
      {{ previewText }}
    </div>
  </div>
</template>

<script>
import SearchSiteDropdownItem from 'components/common/SearchSite/components/SearchSiteDropdownItem'

export default {
  name: 'SearchSiteDropdown',
  components: {
    SearchSiteDropdownItem,
  },
  props: {
    triggerKeydownInput: {
      type: Number,
      required: true,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
    previewText: {
      type: String,
      default: '',
    },
    location: {
      type: Object,
      default: () => ({
        town: '',
        district: '',
        microdistrict: '',
        street: '',
        metro: '',
      }),
    },
  },
  data: () => ({
    indexSelectedItem: -1,
    transformItemData: {},
  }),
  watch: {
    triggerKeydownInput(val, oldVal) {
      if (val > oldVal) {
        this.handleKeydownArrowUp()
      } else {
        this.handleKeydownArrowDown()
      }
    },
    searchQuery() {
      this.indexSelectedItem = -1
    },
  },
  mounted() {
    this.$emit('dropdown:mounted', this.$refs.searchDropdown)
  },
  methods: {
    handleKeydownArrowUp() {
      if (this.indexSelectedItem === -1) {
        this.indexSelectedItem = this.searchResults.length - 1
      } else {
        this.indexSelectedItem -= 1

        if (this.indexSelectedItem === -1) {
          this.indexSelectedItem = this.searchResults.length - 1
        }
      }

      this.emitSelectedItem()
    },
    handleKeydownArrowDown() {
      if (this.indexSelectedItem === -1) {
        this.indexSelectedItem = 0
      } else {
        this.indexSelectedItem += 1
        this.indexSelectedItem %= this.searchResults.length
      }

      this.emitSelectedItem()
    },
    handleSelectedItem({ transformItemData }) {
      this.transformItemData = transformItemData
    },
    emitSelectedItem() {
      this.$nextTick(() => this.$emit('dropdown:selected-item', {
        transformData: this.transformItemData,
      }))
    },
  },
}
</script>
