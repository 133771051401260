import getNamespace from 'modules/ExpansionPanels/functions/getNamespace'

const namespace = getNamespace()

function getUpgradeHeadTemplate({ innerHTML = '', hasArrow, isRightArrow }) {
  return `
        <button
            type="button"
            class="${namespace.button}${hasArrow ? ` ${namespace.buttonHasArrow}` : ''}${hasArrow && isRightArrow ? ` ${namespace.buttonRightArrow}` : ''}"
            aria-expanded="false"
        >
            ${innerHTML}
        </button>
    `
}

export default getUpgradeHeadTemplate
