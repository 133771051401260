import AuthService from 'services/AuthService'
import {
  createDevNotice,
  getOnlyNumbers,
} from 'utils'
import { ERRORS } from '../constants'

export const showError = (message, prefixed = false) => {
  // eslint-disable-next-line no-alert
  alert(`${prefixed ? ERRORS.base : ''}${message}`)
}

export const showReloadError = (message = null, prefixed = false) => {
  // eslint-disable-next-line no-alert
  if (window.confirm(`${prefixed ? ERRORS.base : ''}${message ?? ERRORS.baseExtended}`)) {
    window.location.reload()
  }
}

export const validatePhone = input => input.length === 11

export const validateCode = input => input.length === 4

export const formatPhone = phone => getOnlyNumbers(`7${phone}`)

export const getWsUri = ({ uuid }) => `wss://${window.MEDTOCHKA_DOMAIN}/ws/phone_confirmation/call/${uuid}?subscribe-broadcast`

export const isAccountsUnsync = uuid => uuid !== null && uuid !== window.USER.medtochkaUuid

export const needsPhoneReconfirm = async () => {
  if (window.USER.isRealPatient) {
    try {
      const response = await AuthService.pullAuth({ skipGrant: true })
      const { uuid } = response.data

      return isAccountsUnsync(uuid)
    } catch (e) {
      createDevNotice({
        module: 'blocksMixins/auth/functions',
        method: 'needsPhoneReconfirm',
        description: e.message,
      })
    }
  }
}

export const reconfirmPhone = async (resolvedCb = () => {}, rejectedCb = () => {}) => {
  try {
    await AuthService.logout({ onlyOneSession: true })
    resolvedCb()
  } catch (e) {
    createDevNotice({
      module: 'blocksMixins/auth/functions',
      method: 'reconfirmPhone',
      description: e.message,
    })
    rejectedCb()
  }
}
