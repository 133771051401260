/**
 * @description
 *
 * Возвращает строку, у которой первый символ преобразовывается в верхний регистр
 *
 * @params { String } - строка для преобразования
 *
 * @return { String } - преобразованная строка
 * */

const getFirstLetterUpper = (str = '') => str.charAt(0).toUpperCase() + str.slice(1)

export default getFirstLetterUpper
