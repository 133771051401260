<template>
  <div data-qa="user_authorization">
    <UserAuthorizationForm
      ref="authorization-form"
      is-allowed-submit
      :fn-handler-submit-form="handleCreateRequest"
      :is-phone-confirmed="isPhoneConfirmed"
      :is-phone-readonly="isPhoneReadonly"
      :is-needed-confirm-by-call="isNeededConfirmByCall"
      :is-autofocus-field="isRenderInModal"
      :init-phone="initPhone"
      :is-country-authorization="isCountryAuthorization"
    >
      <template #default>
        <UserAuthorizationByUserCallContent />
      </template>
      <template
        #action="{
          onSubmitForm,
          loading,
          disabled,
          isVisible,
        }"
      >
        <div
          v-if="!isPhoneReadonly"
          :style="getStyleForAction(isVisible)"
        >
          <VExpandTransition>
            <VBtn
              v-if="isVisible"
              block
              depressed
              color="primary"
              :loading="loading"
              :disabled="disabled"
              data-qa="user_authorization_confirmation_btn"
              @click="onSubmitForm"
            >
              Подтвердить
            </VBtn>
          </VExpandTransition>
        </div>
        <VBtn
          v-else-if="isVisible"
          block
          depressed
          color="primary"
          :loading="loading"
          :disabled="disabled"
          data-qa="user_authorization_confirmation_btn"
          @click="onSubmitForm"
        >
          Подтвердить
        </VBtn>
      </template>
    </UserAuthorizationForm>
  </div>
</template>

<script>
import UserAuthorizationForm from 'components/common/UserAuthorization/components/common/UserAuthorizationForm'
import UserAuthorizationByUserCallContent from 'components/common/UserAuthorization/components/common/UserAuthorizationByUserCallContent'
import { authByUserCallSendFormMixin } from 'components/common/UserAuthorization/mixins'

export default {
  name: 'UserAuthorizationByUserCall',
  components: {
    UserAuthorizationForm,
    UserAuthorizationByUserCallContent,
  },
  mixins: [
    authByUserCallSendFormMixin,
  ],
  props: {
    initPhone: {
      type: String,
      default: '',
    },
    isRenderInModal: {
      type: Boolean,
      default: false,
    },
    isPhoneReadonly: {
      type: Boolean,
      default: false,
    },
    isNeededConfirmByCall: {
      type: Boolean,
      default: false,
    },
    isCountryAuthorization: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPhoneConfirmed: false,
  }),
  mounted() {
    if (this.initPhone && !this.isNeededConfirmByCall) {
      this.handlePhoneConfirmed({ phone: `7${this.initPhone}` })
    }
  },
  methods: {
    getStyleForAction(isVisible) {
      // Фикс проблемы с анимацией при сворачивании/разворачивании контента + кнопки
      return isVisible ? null : { opacity: 0 }
    },
    handlePhoneConfirmed({ phone }) {
      this.isPhoneConfirmed = true

      this.$emit('authorization:confirmed', phone)
    },
  },
}
</script>
