import { getGoalsList } from 'utils'

const PATIENT_PROFILE_QA_GOALS = getGoalsList({
  block: 'patient_profile',
  goals: [
    'node_btn_close', // Узел кнопки 'Закрыть'
    'node_criterion', // Узел с критерием доверия к пациенту
    'node_count_appointments', // Узел кол-ва записей
  ],
})

export default PATIENT_PROFILE_QA_GOALS
