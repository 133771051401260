function isFirstSurrogatePair(charCode) {
  // Значения 0xd800...0xdbff - зарезервированы стандартом под первую часть суррогатных пар
  return charCode >= 0xd800 && charCode <= 0xdbff
}

function isSecondSurrogatePair(charCode) {
  // Значения 0xdc00...0xdfff - зарезервированы стандартом под вторую часть суррогатных пар
  return charCode >= 0xdc00 && charCode <= 0xdfff
}

/**
 * @description
 *
 * Возвращает символ на заданной позиции.
 * Если позиция попадает на первую или вторую часть суррогатной пары, то возвращает её целиком,
 * но только если пара состоит из двух частей.
 * Если указана позиция за пределами строки, то возвращается пустая строка.
 *
 * @param { string } str - строка, из которой нужно вернуть символ
 * @param { number } pos - позиция, по которой будет браться символ
 *
 * @return { string } - возвращаемый символ (суррогатная пара)
 * */

function getSymbolAtPos(str, pos) {
  if (pos < 0 || pos >= str.length) {
    return ''
  }

  const charCode = str.charCodeAt(pos)

  if (isFirstSurrogatePair(charCode)) {
    const pairCharCode = str.charCodeAt(pos + 1)

    if (isSecondSurrogatePair(pairCharCode)) {
      return String.fromCharCode(charCode, pairCharCode)
    }
  }

  if (isSecondSurrogatePair(charCode)) {
    const pairCharCode = str.charCodeAt(pos - 1)

    if (isFirstSurrogatePair(pairCharCode)) {
      return String.fromCharCode(pairCharCode, charCode)
    }
  }

  return String.fromCharCode(charCode)
}

export default getSymbolAtPos
