export const EVENTS = {
  open: 'dialog:open',
  close: 'dialog:close',
}

export const ERROR = {
  title: 'Что-то пошло не так',
  text: 'Попробуйте немного подождать и обновить страницу.',
  closeText: 'Понятно',
}

export const MAX_WIDTH = 550
