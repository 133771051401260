import { UAParser } from 'ua-parser-js'
import { compare } from 'compare-versions'
import createDevNotice from 'utils/executors/createDevNotice'
import { SUPPORTED_BROWSER_DATA } from 'constants/browserCores'

/**
 * @description
 * Возвращает объект с данными "browserslist", которое доступно в "window" на всех страницах
 *
 * @return { Object | null } - словарь с данными или null, если произошла ошибка парсинга
 * */
const getBrowserListData = () => {
  try {
    return JSON.parse(window.browserListData)
  } catch {
    createDevNotice({
      module: 'isBrowserSupported',
      method: 'getBrowserListData',
      description: 'Не удается прочитать JSON файл со списком поддерживаемых браузеров',
    })

    return null
  }
}

/**
 * @description
 * Производит парсинг "userAgent" и возвращает необходимые данные для работы "isBrowserSupported"
 *
 * @param { String } userAgent - строка с userAgent пользователя. Если не передано - значение достается из "navigator.userAgent"
 * @return { Object } - объект с данными, которые были получены из "userAgent"
 * */
const getUAParserData = userAgent => {
  const browserParser = new UAParser(userAgent || navigator.userAgent)
  const { name: engineName } = browserParser.getEngine()
  const { name: browserName, major: UABrowserVersion } = browserParser.getBrowser() // UA - User Agent
  const UABrowserName = browserName ? browserName.toLowerCase() : null
  const UABrowserEngine = engineName ? engineName.toLowerCase() : null

  return {
    UABrowserName,
    UABrowserEngine,
    UABrowserVersion,
  }
}

/**
 * @description
 * Производит проверку на поддерживаемость браузера в рамках списка "browserslist", кастомного списка поддерживаемых браузеров, а также движка браузера
 *
 * @param { String } [userAgent] - строка с userAgent пользователя(необязательно). Если не передано - значение достается из "navigator.userAgent"
 * @return { Boolean | undefined }
 * */
const isBrowserSupported = userAgent => {
  /**
     * ToDo - удалить условие после внедрения нового webpack и удаления старого
     * Временное решение до тех пор, пока поле не будет передаваться всегда в каждом шаблоне.
     * Если поля нет, значит данные не сгенерированы(например потому что используется старый webpack)
     * */
  if (!window.browserListData) {
    return true
  }

  const browserListData = getBrowserListData()
  const {
    UABrowserName,
    UABrowserEngine,
    UABrowserVersion,
  } = getUAParserData(userAgent)
  const {
    supportedNamesData,
    supportedEngineList,
    compatibleNamesList,
  } = SUPPORTED_BROWSER_DATA

  if (!UABrowserName && !UABrowserEngine) { // Если нет имени браузера или движка, значит "UAParser" не может спарсить строку "userAgent"
    return
  }

  if (!supportedEngineList.includes(UABrowserEngine)) { // Поддерживаем только ограниченное количество браузерных движков
    return
  }

  if (!browserListData) { // Если список прочитать не получается - значит произошла ошибка во время генерации
    return
  }

  /**
     * Поскольку название браузера, которое предоставляет "browserslist" может отличаться от пакета "UAParser" - необходимо их связать.
     * Для основных браузеров используется связка из объекта "compatibleNamesList"(который содержит в себе список совместимости "browserslist" и "UAParser", а также кастомный список).
     * */
  const compatibleName = compatibleNamesList[UABrowserName] || UABrowserName
  const UABrowserData = browserListData[compatibleName] || supportedNamesData[compatibleName]

  if (!UABrowserData) { // Значит данных по браузеру не найдено - нет ни в списке "browserslist", ни в списке "supportedNamesData"
    return
  }

  if (UABrowserData.min === 'all') { // Значит мы поддерживаем все версии браузеров(это ключевое слово предоставляет пакет "browserslist")
    return true
  }

  return compare(UABrowserVersion, UABrowserData.min, '>=')
}

export default isBrowserSupported
