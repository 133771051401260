/**
 * @description
 *
 * Проверка переданного аргумента на формат JSON
 *
 * @param { * } data - аргумент для проверки
 *
 * @return { Boolean } - булевое значение проверки
 * */

function isJSON(data) {
  try {
    JSON.parse(data)
    return true
  } catch (error) {
    return false
  }
}

export default isJSON
