/**
 * @description
 *
 * Определяет, до конца ли был проскроллен контент в элементе
 *
 * @param { HTMLElement } node - элемент, в котором контент прокручивается
 * @param { Number } offset - кол-во пикселей, на которые уменьшается высота элемента при расчете, следовательно значение `true` будет получено быстрее на `offset` пикселей.
 *
 * @return { Boolean }
 */

function isContentFullyScrolled(node, offset = 0) {
  return (node.scrollHeight - Math.abs(node.scrollTop)) <= (node.clientHeight + offset)
}

export default isContentFullyScrolled
