import { axiosClient, getCSRFToken } from 'utils'

/**
 * Метод для отправки смс кода на номер телефона для стафов
 *
 * @param { String } phone
 *
 * @returns { Promise }
 */

function requestGetCodeForStaff(phone) {
  const formData = new FormData()

  formData.append('phone', phone)

  return axiosClient({
    method: 'POST',
    url: '/ajax/phone_confirmation/',
    data: formData,
    headers: {
      'X-CSRFToken': getCSRFToken(),
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default requestGetCodeForStaff
