var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      staticClass: "app-side-menu",
      class: {
        "app-side-menu_active":
          _vm.isActive || (!_vm.isActive && !_vm.isTransitionEnd),
      },
      attrs: { "data-side-menu": "" },
    },
    [
      _c(
        "div",
        [
          _c(
            "v-navigation-drawer",
            {
              attrs: { width: "270", fixed: "" },
              on: { transitionend: _vm.handleTransitionend },
              model: {
                value: _vm.isActive,
                callback: function ($$v) {
                  _vm.isActive = $$v
                },
                expression: "isActive",
              },
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href: _vm.townSelectUrl,
                        "data-qa": "active_town",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", { staticClass: "ui-icon-map-pin" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _vm.town
                            ? _c(
                                "v-list-item-title",
                                { staticClass: "uiKitText--text" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.town) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _c(
                                "v-list-item-title",
                                { staticClass: "uiKitTextInfo--text" },
                                [
                                  _vm._v(
                                    "\n              Город не указан\n            "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isRealPatient || _vm.isAnonymous
                    ? _c("v-divider", { attrs: { inset: "" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRealPatient
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            href: _vm.getMedtochkaSectionUrl(
                              _vm.medtochkaRoutes.profile
                            ),
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                staticClass: "ui-icon-user-only",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "uiKitText--text" },
                                [
                                  _vm._v(
                                    "\n              Профиль\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : !_vm.isAnonymous
                    ? _c(
                        "v-list-item",
                        { attrs: { href: _vm.personalAreaUrl } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", { staticClass: "ui-icon-doctor" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "uiKitText--text" },
                                [
                                  _vm._v(
                                    "\n              Личный кабинет\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : [
                        _c(
                          "v-list-item",
                          {
                            attrs: { "data-qa": "patient_login" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.loginRelocation(
                                  _vm.loginPathMedtochka
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  staticClass: "ui-icon-user-only",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Войти как пациент\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: { "data-qa": "clinic_login" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.loginRelocation(_vm.loginPathCabinet)
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", { staticClass: "ui-icon-doctor" })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Войти как врач/клиника\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                  _vm._v(" "),
                  _vm.isRealPatient
                    ? [
                        _vm.clubData.isInClub
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.handleClickCardInfo } },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "my-auto" },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: "/static/_v1/pd/icons/ui-kit/club/club-check.svg",
                                        width: "24",
                                        height: "24",
                                        alt: "Иконка Галочка Клуба",
                                        eager: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-2" },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "uiKitText--text" },
                                      [
                                        _vm._v(
                                          "\n                Клубная карта\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "uiKitTextInfo--text" },
                                      [
                                        _vm._v(
                                          "\n                до " +
                                            _vm._s(_vm.clubData.cardDateEnd) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              href: _vm.getMedtochkaSectionUrl(
                                _vm.medtochkaRoutes.home
                              ),
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  staticClass: "ui-icon-home-MedLock",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Главная кабинета\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              href: _vm.getMedtochkaSectionUrl(
                                _vm.medtochkaRoutes.appointments
                              ),
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  staticClass:
                                    "ui-icon-calendar-date-and-clock",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Записи на приём\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              href: _vm.getMedtochkaSectionUrl(
                                _vm.medtochkaRoutes.rates
                              ),
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  staticClass: "ui-icon-Reviews",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Отзывы\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              href: _vm.getMedtochkaSectionUrl(
                                _vm.medtochkaRoutes.favourites
                              ),
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", { staticClass: "ui-icon-heart" })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Избранное\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              href: _vm.getMedtochkaSectionUrl(
                                _vm.medtochkaRoutes.medcard
                              ),
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  staticClass: "ui-icon-med-book",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n                Медкарта\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.medcardStatusText
                                  ? _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "error--text" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.medcardStatusText) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.handleLogout } },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  staticClass: "ui-icon-log-out",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "uiKitText--text" },
                                  [
                                    _vm._v(
                                      "\n                Выйти\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-subtitle",
                                  { staticClass: "uiKitTextInfo--text" },
                                  [
                                    _vm._v(
                                      "\n                на всех устройствах\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRealPatient || _vm.isAnonymous
                    ? _c("v-divider", { attrs: { inset: "" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projects
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { "data-qa": "other_projects" },
                          on: { click: _vm.openOurProjectsDialog },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                staticClass: "ui-icon-more-projects",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "uiKitText--text" },
                                [
                                  _vm._v(
                                    "\n              Наши проекты\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.projects
            ? _c("OurProjectsDialog", {
                attrs: { projects: _vm.projects },
                model: {
                  value: _vm.isActiveOurProjectsDialog,
                  callback: function ($$v) {
                    _vm.isActiveOurProjectsDialog = $$v
                  },
                  expression: "isActiveOurProjectsDialog",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }