import 'modules/ExpansionPanels/index.scss'

import {
  callChain,
  deepMerge,
  animateCollapse,
} from 'utils'
import { BLOCK_NAME, ANIMATION_DURATION } from 'modules/ExpansionPanels/constants'
import { getNamespace, getUpgradeHeadTemplate } from 'modules/ExpansionPanels/functions'

class ExpansionPanels {
  moduleName = 'ExpansionPanels'

  constructor(options = {}) {
    this._init(options)
  }

  _init(options) {
    this._createOptions(options)
    this._hookBeforeInit()
    this._setBlockElement()

    if (!this.blockElement) {
      return
    }

    callChain({
      context: this,
      chain: [
        [this._createInstanceData],
        [this._upgradeHeadPanels],
        [this._setActivePanels],
        [this._setEventListeners],
        [this._hookAfterInit],
      ],
    })
  }

  _getDefaultOptions = () => ({
    target: `.${BLOCK_NAME}`,
    multiple: false,
    hasArrow: true,
    isRightArrow: false,
    duration: ANIMATION_DURATION,
    hookBeforeInit: () => {},
    hookAfterInit: () => {},
    hookBeforeAnimateStart: () => {},
    hookAfterAnimateEnd: () => {},
  })

  _createOptions = options => {
    this.options = deepMerge(this._getDefaultOptions(), options)
  }

  _setBlockElement = () => {
    this.blockElement = typeof this.options.target === 'string'
      ? document.querySelector(this.options.target)
      : this.options.target
  }

  _createInstanceData = () => {
    this.namespace = getNamespace()
    this.activePanels = [...this.blockElement.querySelectorAll(`.${this.namespace.panelActive}`)]
    this.panelHeadElements = [...this.blockElement.querySelectorAll(`.${this.namespace.head}`)]
  }

  _upgradeHeadPanels = () => {
    if (this.panelHeadElements) {
      this.panelHeadElements.forEach(panelHeadElement => {
        panelHeadElement.innerHTML = getUpgradeHeadTemplate({
          innerHTML: panelHeadElement.innerHTML,
          hasArrow: this.options.hasArrow,
          isRightArrow: this.options.isRightArrow,
        })
      })
    }
  }

  _setActivePanels = () => {
    if (this.activePanels) {
      this.activePanels.forEach(panel => this._toggleActivePanel(panel))
    }
  }

  _setEventListeners = () => {
    this.blockElement.addEventListener('click', this._handleClickOnBlock)
  }

  _handleClickOnBlock = ({ target }) => {
    const panelHeadElement = target.closest(`.${this.namespace.head}`)

    if (!panelHeadElement) {
      return
    }

    const panelElement = panelHeadElement.closest(`.${this.namespace.panel}`)

    if (!this.options.multiple) {
      /**
             * Если опция `multiple` установлена в `false`, то будут скрываться все панели кроме текущей
             * */
      this.activePanels = [...this.blockElement.querySelectorAll(`.${this.namespace.panelActive}`)]

      const activePanelsWithoutCurrent = this.activePanels.filter(el => el !== panelElement)

      activePanelsWithoutCurrent.forEach(panel => {
        this._toggleActivePanel(panel)
      })
    }

    this._toggleActivePanel(panelElement)
  }

  _toggleActivePanel(panel) {
    const panelButton = panel.querySelector(`.${this.namespace.button}`)
    const panelContent = panel.querySelector(`.${this.namespace.body}`)
    const isExpanded = panelButton.getAttribute('aria-expanded') === 'true'
    const method = isExpanded ? 'remove' : 'add'

    panel.classList[method](this.namespace.panelActive)
    panelButton.setAttribute('aria-expanded', !isExpanded)

    animateCollapse({
      element: panelContent,
      isExpand: !isExpanded,
      hookBeforeAnimateStart: data => this._hookBeforeAnimateStart(data, panel),
      hookAfterAnimateEnd: data => this._hookAfterAnimateEnd(data, panel),
      duration: this.options.duration,
    })
  }

  _hookBeforeInit = data => this.options.hookBeforeInit(data)

  _hookAfterInit = data => this.options.hookAfterInit(data)

  _hookBeforeAnimateStart = (data, panelElement) => this.options.hookBeforeAnimateStart(data, panelElement)

  _hookAfterAnimateEnd = (data, panelElement) => this.options.hookAfterAnimateEnd(data, panelElement)
}

export default ExpansionPanels
