const ClubStyleData = {
  data: () => ({
    isMobileDevice: window.MOBILE_VERSION,
    dialogEmailFormWidth: 550,
    dependenciesComponentsStyleList: {
      width: '100%',
    },
  }),
  computed: {
    preloaderData() {
      return {
        width: this.isMobileDevice ? 3 : 4,
        size: this.isMobileDevice ? 61 : 82,
      }
    },
    canceledIconStyleList() {
      return {
        width: '1em',
        height: '1em',
        fontSize: this.isMobileDevice ? '58px' : '78px',
      }
    },
    buttonDeviceClassList() {
      return this.isMobileDevice ? 'b-button_full-width' : 'mx-auto'
    },
    appointmentViewClassList() {
      let classListResult = 'club-appointment-view'

      if (this.isMobileDevice) {
        classListResult += ' club-appointment-view_mobile'
      }

      return classListResult
    },
    appointmentSuccessDetailStyleList() {
      return {
        marginTop: this.isMobileDevice ? '24px' : '64px',
        marginBottom: '16px',
      }
    },
  },
}

export default ClubStyleData
