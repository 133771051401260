import { CLUB_QA_GOALS } from 'dataQA'

const ClubQALocators = {
  data: () => ({
    dataQALocators: {
      ...CLUB_QA_GOALS,
    },
  }),
}

export default ClubQALocators
