import scrollTo from 'utils/executors/scrollTo'
import getElementOffsetTop from 'utils/get/getElementOffsetTop'

const SCROLL_OFFSET_MOBILE = -104 // высота шапки (64px) + 40px смещение по дизайну
const SCROLL_OFFSET_DESKTOP = -40 // 40px смещение по дизайну

/**
 * @description Производит скролл до указанного элемента
 *
 * @param { HTMLElement } element - элемент, до которого нужно проскроллить
 * @param { HTMLElement= } parent - родительский элемент, относительно которого нужно проскроллить
 * */

function scrollToElement(element, parent) {
  const { top } = element.getBoundingClientRect()
  const scrollOffset = window.MOBILE_VERSION ? SCROLL_OFFSET_MOBILE : SCROLL_OFFSET_DESKTOP

  // Не скроллим, если элемент уже где надо.
  // Т.к. скроллинг может быть неточным, то проверяем погрешность таким образом
  if (Math.abs(top + scrollOffset) <= 1) {
    return
  }

  scrollTo(getElementOffsetTop(element, parent), {
    offset: scrollOffset, // Нужно из-за выезжающей шапки
    duration: 400,
  }, parent)
}

export default scrollToElement
