import { getOnlyNumbers } from 'utils'

/**
 * @typedef MaskOptions
 * @property { string } mask
 * @property { Record<string, string> } tokens
 * */

/**
 * @typedef PhoneData
 * @property { string } code
 * @property { Array<RegExp> } regexps
 * @property { Array<string> } formattedMasks
 * @property { number } minLength
 * @property { number } maxLength
 * */

/**
 * @param { PhoneData } phoneData
 * */
function getPhoneValidationRules({
  regexps,
  formattedMasks,
  minLength,
  maxLength,
}) {
  const formattedMasksString = formattedMasks.join(', ')

  return [
    val => !!val || 'Укажите номер телефона',
    val => regexps.some(regexp => regexp.test(val.trim())) || `Укажите номер в формате ${formattedMasksString}`,
    val => {
      const numberCount = getOnlyNumbers(val).length

      return (numberCount >= minLength && numberCount <= maxLength)
        || `Укажите номер в формате ${formattedMasksString}`
    },
  ]
}

export default getPhoneValidationRules
