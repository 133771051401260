/**
 * @description
 *
 * Возвращает преобразованную строку camelCase => kebab-case
 *
 * @param { String } str - строка, которую необходимо преобразовать
 *
 * @return { String } - преобразованная строка
 * */

function getKebabFromCamel(str) {
  return str.replace(/[A-Z]/g, (symbol, i) => (i === 0 ? symbol.toLowerCase() : `-${symbol.toLowerCase()}`))
}

export default getKebabFromCamel
