export const CLUB_EVENTS = {
  discountSwitchClick: 'club-discount-switch:click',
  detailInfoBannerClick: 'club-detail-info-banner:click-btn',

  cardGettingOpen: 'club-card-getting:open',
  cardGettingCancel: 'club-card-getting:cancel',
  cardGettingSubmit: 'club-card-getting:submit',
  cardGettingRedirect: 'club-card-getting:redirect',
  cardGettingEmailSent: 'club-card-getting:email-sent',
  cardGettingErrorCancel: 'club-card-getting-error:cancel',
  cardGettingErrorSubmit: 'club-card-getting-error:submit',
  cardGettingRedirectOrCancel: 'club-card-getting:redirect-or-cancel',

  cardInfoOpen: 'club-card-info:open',
}

export const USER_AUTH_EVENTS = {
  confirmed: 'authorization:confirmed',
  requested: 'authorization:requested',
}

export const POPUP_MAP_EVENTS = {
  openDesktop: 'popup-map:open-dialog-desktop',
  openMobile: 'popup-map:open-dialog-mobile',
  mounted: 'popup-map:mounted',
}
