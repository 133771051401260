import Cookie from 'js-cookie'

/**
 * @description
 *
 * Функция достает из кук CSRF токен и возвращает его
 *
 * @return { String } - CSRF токен или пустая строка, если его не удастся получить
 * */

function getCSRFToken() {
  try {
    return Cookie.get('csrftoken') || ''
  } catch {
    return ''
  }
}

export default getCSRFToken
