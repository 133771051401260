import LazyLoad from 'vanilla-lazyload'

function getLazyLoadInstance(isForBackgroundImage = false) {
  return new LazyLoad({
    elements_selector: isForBackgroundImage ? '[data-lazy-bg]' : 'img[data-lazy-src]',
    threshold: 400,
    data_src: 'lazy-src',
    data_bg: 'lazy-bg',
    use_native: !isForBackgroundImage,
  })
}

export default getLazyLoadInstance
