/**
 * @description
 * Устанавливает значение query параметра для текущего урла с помощью replaceState
 * @param { string } name
 * @param { any } value
 * */

const setQueryParam = (name, value) => {
  const url = new URL(window.location)

  url.searchParams.set(name, value)

  window.history.replaceState(window.history.state, '', url)
}

export default setQueryParam
