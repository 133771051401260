var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "user_authorization" } },
    [
      _c("UserAuthorizationForm", {
        ref: "authorization-form",
        attrs: {
          "is-allowed-submit": "",
          "fn-handler-submit-form": _vm.handleCreateRequest,
          "is-phone-confirmed": _vm.isPhoneConfirmed,
          "is-phone-readonly": _vm.isPhoneReadonly,
          "is-needed-confirm-by-call": _vm.isNeededConfirmByCall,
          "is-autofocus-field": _vm.isRenderInModal,
          "init-phone": _vm.initPhone,
          "is-country-authorization": _vm.isCountryAuthorization,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [_c("UserAuthorizationByUserCallContent")]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function ({ onSubmitForm, loading, disabled, isVisible }) {
              return [
                !_vm.isPhoneReadonly
                  ? _c(
                      "div",
                      { style: _vm.getStyleForAction(isVisible) },
                      [
                        _c(
                          "VExpandTransition",
                          [
                            isVisible
                              ? _c(
                                  "VBtn",
                                  {
                                    attrs: {
                                      block: "",
                                      depressed: "",
                                      color: "primary",
                                      loading: loading,
                                      disabled: disabled,
                                      "data-qa":
                                        "user_authorization_confirmation_btn",
                                    },
                                    on: { click: onSubmitForm },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Подтвердить\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : isVisible
                  ? _c(
                      "VBtn",
                      {
                        attrs: {
                          block: "",
                          depressed: "",
                          color: "primary",
                          loading: loading,
                          disabled: disabled,
                          "data-qa": "user_authorization_confirmation_btn",
                        },
                        on: { click: onSubmitForm },
                      },
                      [_vm._v("\n        Подтвердить\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }