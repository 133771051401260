import { CLUB_EVENTS } from 'components/common/Club/constants'

const ClubEventsNames = {
  data: () => ({
    eventClubPrepayment: CLUB_EVENTS.clubPrepayment,
    eventClubSelectInfoModal: CLUB_EVENTS.clubSelectInfoModal,
    eventClubDownloadCoupon: CLUB_EVENTS.clubDownloadCoupon,
    eventClubCountdownStarted: CLUB_EVENTS.clubCountdownStarted,
    eventClubCountdownExpired: CLUB_EVENTS.clubCountdownExpired,
    eventClubMakeAppointment: CLUB_EVENTS.clubMakeAppointment,
    eventClubTypePaymentSelected: CLUB_EVENTS.clubTypePaymentSelected,
    eventClubSendEmailSuccess: CLUB_EVENTS.clubSendEmailSuccess,
    eventClubRequestActiveCard: CLUB_EVENTS.clubRequestActiveCard,
    eventClubGoWithoutPrepayment: CLUB_EVENTS.clubGoWithoutPrepayment,
    eventClubRedirectToMedtochka: CLUB_EVENTS.clubRedirectToMedtochka,
    eventClubReturnFromErrorView: CLUB_EVENTS.clubReturnFromErrorView,
    eventClubCancellationEmailForm: CLUB_EVENTS.clubCancellationEmailForm,
    eventClubAppointmentTypeSelected: CLUB_EVENTS.clubAppointmentTypeSelected,
    eventClubGetResponseErrorDesktop: CLUB_EVENTS.clubGetResponseErrorDesktop,
    eventClubGetResponseAppointmentDesktop: CLUB_EVENTS.clubGetResponseAppointmentDesktop,
    eventClubSelectLpuChanged: CLUB_EVENTS.clubSelectLpuChanged,
    eventClubSelectSpecialityChanged: CLUB_EVENTS.clubSelectSpecialityChanged,
    eventClubClickShowNotice: CLUB_EVENTS.clubClickShowNotice,
    eventClubClickPayment: CLUB_EVENTS.clubClickPayment,
  }),
}

export default ClubEventsNames
