export const FILE_TYPES = {
  pdf: 'pdf',
  doc: 'doc',
  docx: 'docx',
  xls: 'xls',
  xlsx: 'xlsx',
  png: 'png',
  jpeg: 'jpeg',
  jpg: 'jpg',
  webp: 'webp',
  svg: 'svg',
  heic: 'heic',
  heif: 'heif',
}

export const MIME_TYPE_IMAGE_PREFIX = 'image/'

export const BASE64_PREFIX = ';base64,'

// Про MIME-типы см. тут: https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPES = {
  all: '*',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  heic: 'image/heic',
  heif: 'image/heif',
}
