import { getGoalsList } from 'utils'

const TELEMED_YANDEX_GOALS = getGoalsList({
  block: 'telemed',
  goals: [
    'appointmentOpen', // Пользователь перешёл в форму записи
    'appointmentOpenTwoStep', // Пользователь перешёл на 2й шаг записи
    'appointmentPhoneConfirmed', // Пользователь подтвердил номер телефона
    'appointmentSuccess', // Пользователь успешно записался и перенаправляется на МТ
  ],
})

export default TELEMED_YANDEX_GOALS
