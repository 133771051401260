/**
 * @author Александр Быков (@bykov)
 * @copyright Кирилл Шабанов (@shabanov)
 * */

import './index.scss'
import {
  isNode,
  callChain,
  deepMerge,
  getTypeObject,
  createDevNotice,
} from 'utils'
import tippy from 'tippy.js'
import { getNamespace, getClassListHTML } from './functions'
import { BLOCK_NAME, IS_DECORATED_SYMBOL, WIDTH_HINT } from './constants'

class Hint {
  constructor(options = {}) {
    this._changeValueIsInit({ value: false })
    this._createPromiseCounter()
    this._createOptions(options)

    const { text, target, templateLinking } = this.options

    if (!((text || templateLinking) && target)) {
      createDevNotice({
        module: 'Hint',
        description: '("text" или "templateLinking") и "target" обязательные поля',
        method: 'constructor',
        mode: 'throw',
      })
    }

    callChain({
      context: this,
      chain: [
        [this._createNameSpace],
        [this._createClassListHTML],
        [this._createTemplate],
        [this._createInstance],
        [this._changeValueIsInit, [{ value: true }]],
      ],
    })
  }

  /**
     * @description
     * Метод реализован с помощью рекурсивного таймаута, поскольку это единственный способ отслеживания завершения работы callChain(setTimeout).
     * Функция вызывается в случае, если инициализация еще не прошла quantity количество раз через delay задержку.
     * Возможно 2 исхода - исполнение переданной функции, после успешной инициализации или возврат(переданная функция не выполнится)
     *
     * @params { Function } - функция, которая должна исполниться и которой нужны данные экземпляра для исполнения
     * */

  // TODO SwiperModule использует идентичный метод с идентичными параметрами.
  //  Если алгоритм себя зарекомендует и не последует доработок - нужно вынести метод в utils(за подробностями обращаться к автору)

  promise = (fn = () => {}) => {
    const { delay, quantity } = this.promiseCounter

    if (!this.isInit) {
      if (this.promiseCounter.counter < quantity) {
        setTimeout(() => {
          this.promiseCounter.counter++
          this.promise(fn)
        }, delay)
      }

      this.promiseCounter.counter = 0

      createDevNotice({
        mode: 'warn',
        module: 'Hint',
        method: 'promise',
        description: 'Метод "promise" не выполнился.',
      })

      return
    }

    this.promiseCounter.counter = 0

    fn({ data: this.instances })
  }

  update = () => {
    setTimeout(() => {
      this.instances.forEach(instance => {
        instance.destroy()
      })

      this._createInstance()
    })
  }

  _setMaxWidth() {
    const { maxWidth: optionsMaxWidth } = this.options

    if (!optionsMaxWidth) {
      return
    }

    const maxWidth = WIDTH_HINT[optionsMaxWidth]

    if (!maxWidth) {
      createDevNotice({
        mode: 'throw',
        module: 'Hint',
        method: '_setMaxWidth',
        description: 'Неверно указан параметр ширины maxWidth',
      })
    }

    this.options.tippyOptions.maxWidth = maxWidth
  }

  _createInstance() {
    const { hint } = this.template
    const { target, tippyOptions, templateLinking } = this.options
    const { content } = this.namespace

    this.options.tippyOnShow = this._onShowDecorator(this.options.tippyOnShow)

    this._changeValueIsInit({ value: false })
    this._setMaxWidth()

    const tippyInstance = tippy(target, {
      ...tippyOptions,
      content(reference) {
        if (templateLinking) {
          const cloneHint = hint.cloneNode(true)
          const text = templateLinking(reference)
          const contentNode = cloneHint.querySelector(`.${content.base}`)

          /**
                     * Данное условие нужно для того - чтобы можно было в text передать DOM узел возвращённый методом removeChild,
                     * который можно вставить в другой узел методом appendChild со всеми его зарегистрированными обработчиками событий
                     * (например можно передать vue-компонент, и он будет корректно работать)
                     * */
          if (isNode(text)) {
            contentNode.appendChild(text)

            return cloneHint
          }

          contentNode.innerHTML = text

          return cloneHint.outerHTML
        }

        return hint.outerHTML
      },
      onShow: this.options.tippyOnShow,
    })

    this.instances = getTypeObject(tippyInstance) !== 'array' ? [tippyInstance] : tippyInstance
    this._changeValueIsInit({ value: true })
  }

  _onShowDecorator = fn => instance => {
    if (!instance[IS_DECORATED_SYMBOL]) {
      instance.setContent = this._setContentDecorator(instance.setContent)
      instance[IS_DECORATED_SYMBOL] = true
    }

    fn(instance)
  }

  _setContentDecorator = fn => content => {
    const newContent = this._getBodyTemplate({ content })

    fn(newContent)
  }

  _createOptions(options) {
    this.options = deepMerge({
      text: '',
      target: null,
      isMobile: true,
      modifier: null,
      maxWidth: null,
      templateLinking: null, // https://atomiks.github.io/tippyjs/v6/html-content/#template-linking
      tippyOptions: {
        trigger: 'click',
        allowHTML: true,
        placement: 'bottom',
        hideOnClick: true,
        duration: 0,
      },
      tippyOnShow: () => {},
    }, options)
  }

  _createNameSpace() {
    this.namespace = getNamespace({
      baseModifier: BLOCK_NAME,
      customModifier: this.options.modifier,
      isMobile: this.options.isMobile,
    })
  }

  _createClassListHTML() {
    this.classListHTML = getClassListHTML(this.namespace)
  }

  _createTemplate() {
    const hint = document.createElement('div')

    this.classListHTML.block.split(' ').forEach(itemClass => {
      hint.classList.add(itemClass)
    })

    hint.innerHTML = this._getBodyTemplate({ content: this.options.text })

    this.template = { hint }
  }

  _getBodyTemplate = ({ content }) => (
    `<div class="${this.classListHTML.layer}">
              <div class="${this.classListHTML.background}">
                  <div class="${this.classListHTML.content} ui-text ui-text_body-secondary ui-kit-color-text">
                      ${content}
                  </div>
              </div>
         </div>`
  )

  /**
     * @description
     * Создается объект с данными, которые необходимы для работы публичного метода promise
     * Логика метода описана выше
     *
     * @return { Object } - объект с параметрами
     * */

  _createPromiseCounter() {
    this.promiseCounter = {
      delay: 100,
      counter: 0,
      quantity: 10,
    }
  }

  _changeValueIsInit({ value } = {}) {
    this.isInit = value
  }
}

export default Hint
