var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { "data-draft-reminder": "" } },
    [
      _c(
        "VBanner",
        {
          staticClass: "draft-reminder",
          class: { "draft-reminder_desktop b-container mb-5": !_vm.isMobile },
          attrs: { elevation: "24" },
          model: {
            value: _vm.isVisible,
            callback: function ($$v) {
              _vm.isVisible = $$v
            },
            expression: "isVisible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between",
              class: { "flex-direction-column": _vm.isMobile },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  class: {
                    "align-items-center": !_vm.isMobile,
                    "pt-2": _vm.isMobile,
                  },
                },
                [
                  _c("img", {
                    staticClass: "draft-reminder__avatar",
                    class: {
                      "draft-reminder__avatar_lpu": !_vm.isReviewObjectDoctor,
                    },
                    attrs: {
                      alt: _vm.storageData.reviewObject.name,
                      src: _vm.storageData.reviewObject.avatarUrl,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "draft-reminder_text ui-text ui-text_body-2",
                      class: {
                        "ml-4": _vm.isMobile,
                        "ml-6": !_vm.isMobile,
                      },
                    },
                    [
                      _vm._v(
                        "\n          У вас остался незавершённый отзыв о " +
                          _vm._s(_vm.reviewObjectTypeText) +
                          "\n          "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "ui-text ui-text_subtitle-2",
                          class: { "ui-text_nowrap": _vm.isReviewObjectDoctor },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.reviewObjectNameText) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isMobile ? _c("br") : _vm._e(),
                      _vm._v(
                        "Хотите дописать его и поделиться мнением с пациентами?\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                },
                [
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-2",
                      attrs: { text: "", color: "uiKitText" },
                      on: { click: _vm.handleClickNotNow },
                    },
                    [_vm._v("\n          Не сейчас\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-2 ml-2",
                      class: { "mr-2": !_vm.isMobile },
                      attrs: {
                        text: "",
                        color: "primary",
                        href: _vm.newRateUrl,
                      },
                      on: { click: _vm.handleClickGoToReview },
                    },
                    [_vm._v("\n          К отзыву\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }