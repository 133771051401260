<template>
  <div
    class="card-getting-payment-made"
    data-qa="card_getting_payment_made"
  >
    <VImg
      width="72"
      height="72"
      class="mx-auto mb-2"
      src="/static/_v1/pd/icons/ui-kit/club/club-check.svg"
      alt="Иконка успешной оплаты"
    />
    <div
      class="ui-text ui-text_h5 ui-kit-color-text text-center"
      data-qa="card_getting_payment_made_title"
    >
      Вы в клубе
    </div>
    <div
      v-if="clubData.cardDateEnd"
      class="ui-text ui-text_body-1 ui-kit-color-text-secondary text-center mt-1"
      data-qa="card_getting_payment_made_date_end"
    >
      до {{ clubData.cardDateEnd }}
    </div>
    <div
      class="ui-text ui-text_body-1 ui-kit-color-text-secondary text-center mt-4"
      data-qa="card_getting_payment_made_description"
    >
      Чтобы получить услугу по клубной цене,<br>
      покажите администратору клиники клубную<br>
      карту в своём профиле
    </div>
    <template v-if="componentProps.isRedirectViaBanner">
      <VBtn
        block
        color="primary"
        class="mt-4"
        elevation="0"
        data-qa="card_getting_payment_made_btn_cancel"
        @click="$emit(events.club.cardGettingCancel)"
      >
        Отлично
      </VBtn>
      <VBtn
        text
        block
        tag="a"
        class="mt-2"
        color="primary"
        data-qa="card_getting_payment_made_open_card_info"
        @click="handleClickCardInfo"
      >
        Открыть клубную карту
      </VBtn>
    </template>
    <template v-else>
      <VBtn
        block
        color="primary"
        class="mt-4"
        elevation="0"
        data-qa="card_getting_payment_made_btn_redirect"
        @click="$emit(events.club.cardGettingRedirect)"
      >
        Продолжить
      </VBtn>
    </template>
  </div>
</template>

<script>
import { emitOpenCardInfo } from 'www/global.js.blocks/common/club-card-info/functions'
import {
  eventsData,
  getClubData,
  getPaymentMadeData,
} from 'components/common/Club/components/core/ClubCardGetting/interfaces'

export default {
  name: 'ClubCardGettingPaymentMade',
  props: {
    clubData: {
      type: Object,
      default: getClubData,
    },
    userPhone: {
      type: String,
      default: '',
    },
    componentProps: {
      type: Object,
      default: getPaymentMadeData,
    },
  },
  data: () => ({
    events: eventsData,
  }),
  methods: {
    handleClickCardInfo() {
      emitOpenCardInfo(this.clubData, this.userPhone)
    },
  },
}
</script>
