/**
 * @description
 *
 * Склоняет слово в зависимости от числа.
 *
 * @param { Number } num - число для склонения
 * @param { Array } wordForms - массив слов для склонения
 *
 * @return { String }
 */

function declensionByNum(num, wordForms) {
  const number = Math.abs(num) % 100
  const balanceOfTen = number % 10

  if (number > 10 && number < 20) {
    return wordForms[2]
  }

  if (balanceOfTen > 1 && balanceOfTen < 5) {
    return wordForms[1]
  }

  if (balanceOfTen === 1) {
    return wordForms[0]
  }

  return wordForms[2]
}

export default declensionByNum
