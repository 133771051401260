import { getGoalsList } from 'utils'

const APPOINTMENT_YANDEX_GOALS = getGoalsList({
  block: 'appointment',
  goals: [
    'timerRedirectToMedtochka', // Редирект на Медточку по таймеру
    'clickRedirectToMedtochka', // Редирект на Медточку по клику на кнопку "Детали записи"
    'clickServiceListSlotExpress', // Редирект на страницу ЭЗ по клику на слот(список LPU на диагностике)
    'clickServiceListSlotDirect', // Редирект на страницу ПЗ по клику на слот(список LPU на диагностике)
    'servicesSynonymModalOpen', // Открытие модалки с синонимами после клика на селект
    'servicesSynonymModalOpenError', // Открытие диалогового окна с ошибкой после неудачной загрузки синонимов в модалку
    'servicesSynonymSelected', // Клик на синоним в модалке
    'servicesAppointmentExpressValid', // Начало записи по ЭЗ со слотами (ПД связывается с клиникой и передаёт ей информацию, но клиника ещё не дозвонилась пользователю)
    'servicesAppointmentDirectSuccess', // Успешная запись по ПЗ Диагностики // Форма записи. ПЗ. Успешная прямая запись (услуги)

    'clickConfirmPhone', // Подтверждение номера. Нажать активную кнопку Подтвердить номер
    'phoneConfirmed', // Подтверждение номера. Номер успешно подтвержден
    'openFormExpress', // ЭЗ. Переход в форму экспресс-записи
    'clickAppointmentExpress', // ЭЗ. Нажать кнопку записаться
    'appointmentExpressBeforeSendData', // ЭЗ. Пользователь подтвердил номер и записывается на прием(перед запросом)
    'expressStepFirst', // ЭЗ. Переход на экран с шагами записи - первый шаг
    'expressStepLast', // ЭЗ. Переход на экран с шагами записи - последний шаг
    'openFormDirect', // ПЗ. Переход в форму прямой записи
    'clickAppointmentDirect', // ПЗ. Нажать активную кнопку Записаться
    'successDirect', // ПЗ. Успешная прямая запись (в том числе при выборе Обычная запись в клубной форме)
    'showCodeInput', // Появился инпут ввода кода для подтверждения телефона
    'confirmedByCall', // Успешное подтверждение номера телефона через звонок
    'confirmedBySms', // Успешное подтверждение номера телефона через СМС

    'serviceOpenFormDirect', // Форма записи. ПЗ. Переход в форму прямой записи (услуги)
    'serviceOpenFormExpress', // Форма записи. ЭЗ. Переход в форму прямой записи (услуги)
    'serviceClickAppointmentDirect', // Форма записи. ПЗ. Нажать активную кнопку Записаться (услуги)
    'serviceClickAppointmentExpress', // Форма записи. ЭЗ. Нажать кнопку записаться (услуги)
    'serviceSuccessExpress', // Форма записи. ЭЗ. Экран успеха экспресс-записи - 5 минут (услуги)
    'serviceOpenFormWithDoctor', // ПЗ. Услуги. Открылась форма с врачом
    'serviceOpenFormWithEquipment', // ПЗ. Услуги. Открылась форма с оборудованием
    'serviceOpenDoctorList', // ПЗ. Услуги. Открыто окно выбора врача
    'serviceSelectedDoctorKnown', // ПЗ. Услуги. Выбрали нового врача  (известный)
    'serviceSelectedDoctorUnknown', // ПЗ. Услуги. Выбрали нового врача  (неизвестный)
    'serviceOpenEquipmentList', // ПЗ. Услуги. Выбрали новое оборудование (неизвестное)
    'serviceSelectedEquipmentKnown', // ПЗ. Услуги. Выбрали новое оборудование (известное)
    'serviceSelectedEquipmentUnknown', // ПЗ. Услуги. Открыто окно выбора оборудования

    'serviceOpenModalPreliminaryConsultation', // ОЗ. Операции. Открылась модалка о необходимости первичной консультации
    'serviceClickConfirmPreliminaryConsultation', // ОЗ. Операции. Пользователь на модалке клиникнул Записаться к врачу
    'serviceClickDeclinePreliminaryConsultation', // ОЗ. Операции. Пользователь на модалке клиникнул Отменить
    'serviceFirstSelectedDoctorConsultation', // ОЗ. Операции. Выбрали первичную консультацию (при обновлении врача)
    'serviceOpenConsultingDoctorList', // ОЗ. Операции. Открыто окно выбора врача
    'serviceSelectedConsultingDoctor', // ОЗ. Операции. Выбрали нового врача
    'serviceOpenDoctorConsultationList', // ОЗ. Операции. Открылась форма выбора первичной консультации
    'serviceSelectedDoctorConsultation', // ОЗ. Операции. Выбрали новую первичную консультацию

    'doctorPageBtnFixedShown', // Страница врача. Проскроллили до момента когда отобразилась фиксированная кнопка "Записаться на приём"
    'doctorPageBtnFixedClick', // Страница врача. Нажали на кнопку "Записаться на приём"

    'clickSlotServicePriceListDirect', // Страница списка цен клинки. Клик по слоту на странице - ОЗ
    'clickSlotServicePriceListExpress', // Страница списка цен клинки. Клик по слоту на странице - ЭЗ
  ],
})

export default APPOINTMENT_YANDEX_GOALS
