let isCanceledRequest = false
let timerId
let timerExpireId
let start
let end

const defaultOptions = {
  timeout: 5 * 1000, // 5 сек.
  lifeTime: 15 * 60 * 1000, // 15 мин.
  hookAfterExpire: () => {}, // Выполнится при остановке цикла (если истекло время жизни)
}

function cancel(settings, isExpire = false) {
  isCanceledRequest = true

  clearTimeout(timerId)
  clearTimeout(timerExpireId)

  if (isExpire) {
    settings.hookAfterExpire()
  }
}

function loop(fn, settings) {
  if (isCanceledRequest) {
    return
  }

  start = performance.now()

  fn().finally(() => {
    end = performance.now()

    const diff = end - start

    if (diff > settings.timeout) {
      loop(fn, settings)
    } else {
      timerId = setTimeout(() => loop(fn, settings), settings.timeout - diff)
    }
  }).catch(() => null)
}

/**
 * @description Функция циклично вызывает переданную асинхронную функцию каждые 5 сек,
 * если переданная асинхронная функция будет выполняться больше 5сек, то
 * новый вызов произойдёт только после завершения вызова асинхронной функции.
 *
 * @param { Function } fn - асинхронная функция, которая будет циклично вызываться
 * @param { Object } options
 * @param { Number } options.timeout - время в `ms`, для периодичности вызовов функции `fn`
 * @param { Number } options.lifeTime - время в `ms`, через которое цикл должен остановиться
 * @param { Function } options.hookAfterExpire - callback функция, которая будет вызвана если цикл остановился по таймауту `lifeTime`
 *
 * @returns { Function } cancel - функция для ручной остановки цикла
 */

function loopAsyncRequest(fn = async () => {}, options = defaultOptions) {
  const settings = { ...defaultOptions, ...options }

  isCanceledRequest = false // сбрасываем для повторного вызова функции

  loop(fn, settings)

  timerExpireId = setTimeout(() => cancel(settings, true), settings.lifeTime)

  return () => cancel(settings)
}

export default loopAsyncRequest
