/**
 * @description
 *
 * Функция возвращает данные в формате form-data
 * @param object - объект, на основе которого создастся form-data
 *
 * @return { FormData }
 * */
function getFormDataFromObject(object) {
  const requestFormData = new FormData()

  Object.entries(object).forEach(entry => {
    requestFormData.append(...entry)
  })

  return requestFormData
}

export default getFormDataFromObject
