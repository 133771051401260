/**
 * @description
 *
 * Проверка localStorage на возможность использования
 *
 * @return { Boolean } - булевое значение тестирования
 */

function isUsedLocalStorage() {
  try {
    window.localStorage.setItem('__localStorageTest', '')
    window.localStorage.removeItem('__localStorageTest')

    return true
  } catch (error) {
    return false
  }
}

export default isUsedLocalStorage
