<template>
  <DialogBottomSheet
    eager
    persistent
    no-click-animation
    :is-visible="isVisibleWithDelay"
    :width="isMobile ? '100%' : '360px'"
  >
    <VCard>
      <VCardTitle class="pb-4">
        <div class="ui-text ui-text_h6 ui-kit-color-text">
          Войдите, чтобы продолжить
        </div>
      </VCardTitle>
      <VDivider v-if="!isMobile" />
      <div :class="isMobile ? 'px-2 pb-6' : 'px-4 py-6'">
        <slot />
      </div>
    </VCard>
  </DialogBottomSheet>
</template>

<script>
import DialogBottomSheet from 'components/common/core/DialogBottomSheet'

export default {
  name: 'UserAuthorizationModalLayout',
  components: {
    DialogBottomSheet,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isVisibleWithDelay: false,
    isMobile: window.MOBILE_VERSION,
  }),
  watch: {
    isVisible: {
      immediate: true,
      async handler() {
        /**
                 * Необходима небольшая задержка перед открытием модалки,
                 * чтобы она не мерцала при загрузке страницы.
                 * */
        await this.$nextTick()

        this.isVisibleWithDelay = this.isVisible
      },
    },
  },
}
</script>

<style lang="scss">
.v-overlay {
  // Значение transition взято из vuetify у v-overlay (за исключением длительности анимации для z-index)
  // Значение 0ms - исправляет анимацию, когда элементы с больши́м z-index отображаются поверх оверлея
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 0ms;
}
</style>
