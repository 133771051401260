/**
 * @description
 *
 * Создает простой граф.
 *
 * @param { Object } options
 * @param { Object } options.graph
 * @param { Function } options.addVertexes
 * @param { Function } options.addEdge
 */

const Graph = {
  graph: {},
  addVertexes(arr) {
    arr.forEach(v => {
      if (this.graph[v] === undefined) {
        this.graph[v] = new Set()
      }
    })

    return this
  },
  addEdge(val1, val2) {
    val2.forEach(val => {
      this.graph[val1].add(val)
      this.graph[val].add(val1)
    })

    return this
  },
}

export default Graph
