import { ERROR_MESSAGES } from 'constants/errorMessages'

const baseData = {
  key: '',
  has: false,
  description: '',
}

/**
 * @description
 *
 * Произведено разделение объекта ошибок из-за различного способа использования:
 * dataErrorFromPage - объект статично передается со страницы
 * dataErrorStore - объект передается путем dispatchEvent
 * currentErrorStore - для унификации работы с 2 объектами используется computed свойство
 * */

const ClubErrorData = {
  props: {
    dataErrorFromPage: {
      type: Object,
      default: () => ({ ...baseData }),
    },
  },
  data: () => ({
    dataErrorStore: { ...baseData },
  }),
  computed: {
    currentErrorStore() {
      if (this.dataErrorFromPage.has || this.dataErrorFromPage.key) {
        return this.dataErrorFromPage
      }

      return this.dataErrorStore
    },
  },
  methods: {
    setErrorDescription() {
      const description = ERROR_MESSAGES[this.currentErrorStore.key]

      if (description) {
        this.$set(this.currentErrorStore, 'description', description)
      }
    },
    clearErrorStore() {
      this.dataErrorStore = { ...baseData }
    },
  },
}

export default ClubErrorData
