export const ERRORS = {
  base: 'Произошла ошибка\n',
  baseExtended: 'Произошла ошибка\nОбновите страницу и попробуйте еще раз.',
}

export const REQUEST_CODE_ERRORS = {
  invalid: {
    code: 'invalid',
    message: 'Некорректный номер телефона',
  },
  errorLimit: {
    code: 'error_limit',
    message: 'Достигнут лимит сообщений на заданный номер',
  },
  errorLimitTotal: {
    code: 'error_limit_total',
    message: 'Достигнут лимит отправки сообщений',
  },
}

export const CONFIRM_PHONE_ERRORS = {
  invalid: {
    code: 'invalid',
    message: 'Некорректный код',
  },
  errorCode: {
    code: 'error_code',
    message: 'Неверный код',
  },
  codeExpired: {
    code: 'code_expired',
    message: 'Код устарел',
  },
  userHasPhone: {
    code: 'user_has_phone',
    message: 'Вы уже авторизованы. Пожалуйста, обновите страницу',
  },
}

export const CALL_CREATE_REQUEST_ERRORS = {
  invalid: {
    code: 'invalid',
    message: 'Некорректный номер телефона',
  },
  userHasPhone: {
    code: 'user_has_phone',
    message: 'Вы уже авторизованы. Пожалуйста, обновите страницу',
  },
}
