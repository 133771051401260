/**
 * @description
 *
 * Возвращает значение query параметра с текущего урла если он есть, либо undefined
 *
 * @return { String | undefined }
 * */

function getQueryParam(paramName) {
  return new URLSearchParams(window.location.search).get(paramName)
}

export default getQueryParam
