import * as Sentry from '@sentry/vue' // eslint-disable-line import/no-extraneous-dependencies

const MODES = {
  warn: 'warn',
  error: 'error',
  throw: 'throw',
}

/**
 * @param { Object } options
 * @param { String } options.module - *Обязательный. Название модуля, в котором нужно выбросить ошибку
 * @param { String } options.description - *Обязательный. Описание ошибки. При использовании в блоке `catch (error)` необходимо передавать `error.message`, а не просто `error`
 * @param { String= } options.method - Название метода, который выкидывает ошибку
 * @param { Object } [options.sentryPayload] - Полезные данные для Sentry
 * @param {('error'|'warn'|'throw')=} options.mode - режим работы:
 *
 * - error - выводит ошибку с помощью console.error, не останавливает исполнение
 * - warn - выводит предупреждение с помощью console.warn, не останавливает исполнение
 * - throw - выводит ошибку с помощью оператора throw. Исполнение скрипта прекращается
 * */
function createDevNotice(options = {}) {
  const defaultOptions = {
    module: null,
    method: null,
    description: null,
    mode: MODES.error,
    sentryPayload: null,
    ...options,
  }

  const descriptionDev = option => `Для генерации уведомления необходимо передать параметр "${option}"`

  if (!defaultOptions.module) {
    createDevNotice({
      module: 'createDevNotice',
      description: descriptionDev('module'),
    })
  }

  if (!defaultOptions.description) {
    createDevNotice({
      module: 'createDevNotice',
      description: descriptionDev('description'),
    })
  }

  let baseTemplate = `Модуль: "${defaultOptions.module}". Описание: ${defaultOptions.description}.`

  if (defaultOptions.method) {
    baseTemplate += ` Метод, вызвавший ошибку: ${defaultOptions.method}`
  }

  const sentryError = defaultOptions.sentryPayload
    ? `[Ошибка]: ${baseTemplate}. [Данные]: ${JSON.stringify(defaultOptions.sentryPayload)}`
    : `[Ошибка]: ${baseTemplate}`

  switch (defaultOptions.mode) {
    case MODES.warn:
      // eslint-disable-next-line no-console
      console.warn(baseTemplate)
      break
    case MODES.error:
      // eslint-disable-next-line no-console
      console.error(baseTemplate)
      Sentry.captureException(new Error(sentryError))
      break
    case MODES.throw:
      Sentry.captureException(new Error(sentryError))
      throw new Error(baseTemplate)
  }
}

export default createDevNotice
