/**
 * Возвращает true - если переданный аргумент `element` являет DOM узлом (Node)
 * */

function isNode(element) {
  return (
    typeof Node === 'object'
      ? element instanceof Node
      : element && typeof element === 'object' && typeof element.nodeType === 'number' && typeof element.nodeName === 'string'
  )
}

export default isNode
