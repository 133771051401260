import UserStorage from 'modules/UserStorage'
import { LOGIN_ATTEMPT_STORAGE_NAME } from 'www/global.js.blocks/common/auto-login/constants'

function isExceededLoginAttempt() {
  const { isExceeded } = UserStorage.get(LOGIN_ATTEMPT_STORAGE_NAME) || {}

  return isExceeded
}

export default isExceededLoginAttempt
