var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "searchDropdown", staticClass: "search-site-dropdown" },
    [
      _vm.searchResults.length && _vm.searchQuery
        ? _c(
            "div",
            {
              staticClass: "search-site-dropdown__list",
              attrs: { "data-qa": "search_site_dropdown_list" },
            },
            _vm._l(_vm.searchResults, function (item, index) {
              return _c("SearchSiteDropdownItem", {
                key: `${index}${item.value}`,
                staticClass: "search-site-dropdown__item",
                attrs: {
                  "item-data": item,
                  "search-query": _vm.searchQuery,
                  "present-town": _vm.location.town,
                  "is-selected-item": index === _vm.indexSelectedItem,
                  "data-qa": "search_site_dropdown_list_item",
                },
                on: {
                  "dropdown-item:selected-item": _vm.handleSelectedItem,
                  "search-site:submit-form": function ($event) {
                    return _vm.$emit("search-site:submit-form")
                  },
                },
              })
            }),
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "search-site-dropdown__preview-text ui-text ui-text_body-1 ui-kit-color-text",
              attrs: { "data-qa": "search_site_dropdown_preview_text" },
            },
            [_vm._v("\n    " + _vm._s(_vm.previewText) + "\n  ")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }