/**
 * @description
 *
 * Трансформирует поля переданного объекта в 'Number' значение
 *
 * @params { Object } object - объект, в котором необходимо произвести трансформацию
 * @params { Array<string> } propList - список ключей
 * */

function transformPropsToNumber(object = {}, propList = []) {
  propList.forEach(prop => {
    if (object[prop]) {
      object[prop] = Number(object[prop])
    }
  })
}

export default transformPropsToNumber
