import { axiosClient } from 'utils'

/**
 * @description Производит запрос для звонка или смс с целью подтвердить телефон,
 * по последним 4м цифрам входящего звонка или по коду из смс.

 * @param { Object } options
 * @param { String } options.phone - 79189999999
 * @param { Boolean } options?.forceSms - если true, значит будет отправлен смс код, а не звонок
 *
 * @returns { Promise }
 */

function requestGetCodeForUser({
  phone = '',
  isVkFlow = false,
} = {}) {
  const data = {
    phone,
  }

  if (isVkFlow) {
    Object.assign(data, {
      confirmation_flow: 'vk',
    })
  }

  return axiosClient.post(
    `${window.MEDTOCHKA_URL}/services/sso/confirmation/request/`,
    data,
    {
      withCredentials: true,
    },
  )
}

export default requestGetCodeForUser
