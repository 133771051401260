/**
 * @description
 *
 * Получает только цифры из строки
 *
 * @param { String } value - входная строка
 * @param { Object } options - конфиг
 * @param { Function } options.returnType - тип возвращаемого значения
 *
 * return { String | Void }
 * */
import createDevNotice from 'utils/executors/createDevNotice'

function getOnlyNumbers(value, { returnType = String } = {}) {
  if (typeof value !== 'string') {
    createDevNotice({
      module: 'getOnlyNumbers',
      description: 'Параметр должен быть строкой',
    })

    return
  }

  return returnType(value.replace(/[^0-9]/g, ''))
}

export default getOnlyNumbers
