/**
 * @description
 *
 * Возвращает функцию, которая откладывает свой вызов на ms миллисекунд. Если за это время функция была вызвана снова,
 * то предыдущий вызов отменяется, а новый откладывается на ms миллисекунд.
 *
 * @param { Function } callback - вызываемая функция
 * @param { Number } ms - задержка в миллисекундах
 *
 * @return { Function } - функция с отложенным вызовом
 */

function debounce(callback, ms) {
  let isCoolDown = false
  let timer

  return function debounced(...args) {
    const timerCallback = () => {
      callback.apply(this, args)
      isCoolDown = false
    }

    if (isCoolDown) {
      clearTimeout(timer)
      timer = setTimeout(timerCallback, ms)

      return
    }

    isCoolDown = true
    timer = setTimeout(timerCallback, ms)
  }
}

export default debounce
