export const MOUNT_SELECTOR = '.vue-draft-reminder-mount'
export const STORAGE_KEY = 'DraftReminder'
export const HOURS_BETWEEN_DISPLAYS = 12
export const MINUTES_BEFORE_FIRST_DISPLAY = 60
export const MAX_NUMBER_OF_DISPLAYS = 2
export const AJAX_URL_IS_VISIBLE = '/is-show-unfinished-draft/'
export const MODULE_NAME = 'DraftReminder'
export const REVIEW_OBJECT_TYPES = {
  doctor: 'doctor',
  lpu: 'lpu',
}
