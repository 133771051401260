export const HEARTBEAT = {
  message: '--heartbeat--',
  interval: 5000,
  maxLostAllowed: 3,
}
export const WS_STATES = {
  connecting: 0,
  open: 1,
  closing: 2,
  closed: 3,
}
export const HOOKS = {
  beforeConnect: 'beforeConnect',
  connected: 'connected',
  disconnected: 'disconnected',
  errorConnection: 'errorConnection',
}
export const MAX_RECONNECTION_TIMEOUT = 30 * 1000
export const MODULE_NAME = 'Sockets'
