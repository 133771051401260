var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "card_getting_club_enter" } },
    [
      _c(
        "div",
        {
          staticClass: "ui-text ui-text_h6 ui-kit-color-text",
          attrs: { "data-qa": "card_getting_club_enter_title" },
        },
        [_vm._v("\n    Вы не в клубе\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ui-text ui-text_body-1 ui-kit-color-text mt-3",
          attrs: { "data-qa": "card_getting_club_enter_description" },
        },
        [
          _vm._v("\n    Клубная карта даёт доступ к ценам ниже\n    "),
          _c("br"),
          _vm._v(
            "на 30% на приёмы врачей и " +
              _vm._s(_vm.discountOnServices) +
              "% на услуги\n    "
          ),
          _c("br"),
          _vm._v("в клиниках клуба\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "club-card-offer ui-text ui-text_body-1 ui-kit-color-text",
          attrs: { "data-qa": "card_getting_club_enter_offer" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-1",
            },
            [
              _c(
                "span",
                { attrs: { "data-qa": "card_getting_club_enter_offer_title" } },
                [_vm._v("\n        Карта на 6 месяцев\n      ")]
              ),
              _vm._v(" "),
              _vm.clubData.cardNextPrice
                ? _c(
                    "span",
                    {
                      staticClass: "ui-text ui-text_subtitle-1",
                      attrs: {
                        "data-qa": "card_getting_club_enter_offer_price",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.clubData.cardNextPrice) +
                          " " +
                          _vm._s(_vm.currency.symbol) +
                          "\n      "
                      ),
                    ]
                  )
                : _c("VImg", {
                    staticClass: "flex-grow-0",
                    attrs: {
                      contain: "",
                      width: "24",
                      height: "25",
                      "data-qa": "card_getting_club_enter_offer_present",
                      src: "/static/_v1/pd/icons/emoji/wrapped-present.png",
                      alt: "Иконка подарок",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
      _vm._v(" "),
      _c(
        "VBtn",
        {
          staticClass: "mt-6 mb-2",
          attrs: {
            block: "",
            color: "primary",
            elevation: 0,
            "data-qa": "card_getting_club_enter_submit",
          },
          on: { click: _vm.handleClickSubmit },
        },
        [_vm._v("\n    " + _vm._s(_vm.variationBtnText) + "\n  ")]
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "VBtn",
        {
          staticClass: "mt-2",
          attrs: {
            text: "",
            block: "",
            "data-qa": "card_getting_club_enter_cancel",
          },
          on: {
            click: function ($event) {
              return _vm.$emit(_vm.events.club.cardGettingCancel)
            },
          },
        },
        [_vm._v("\n    Отменить\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ui-text ui-text_body-2 ui-kit-color-text-secondary",
        attrs: { "data-qa": "card_getting_club_enter_offer_description" },
      },
      [
        _vm._v("\n      Это не подписка. Вы сами решаете, покупать\n      "),
        _c("br"),
        _vm._v("новую клубную карту или нет.\n    "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "ui-text ui-text_body-2 ui-kit-color-text-secondary text-center",
      },
      [
        _vm._v("\n    Нажимая на кнопку, вы даете согласие "),
        _c("br"),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ui-text ui-kit-color-primary ui-text_without-line",
            attrs: { target: "_blank", href: "/info/privacy-policy/" },
          },
          [_vm._v("на обработку персональных данных")]
        ),
        _vm._v(",\n    "),
        _c(
          "a",
          {
            staticClass: "ui-text ui-kit-color-primary ui-text_without-line",
            attrs: { target: "_blank", href: "/info/terms-of-use/" },
          },
          [_vm._v("принимаете пользовательское соглашение")]
        ),
        _vm._v(" и соглашаетесь "),
        _c("br"),
        _vm._v(" с\n    "),
        _c(
          "a",
          {
            staticClass: "ui-text ui-kit-color-primary ui-text_without-line",
            attrs: {
              target: "_blank",
              href: "/static/_v1/pd/documents/pdf/club/Правила_использования_купонов.pdf",
            },
          },
          [_vm._v("офертой")]
        ),
        _vm._v(" и\n    "),
        _c(
          "a",
          {
            staticClass: "ui-text ui-kit-color-primary ui-text_without-line",
            attrs: {
              target: "_blank",
              href: "/static/_v1/pd/documents/pdf/club/Правила_участия_в_клубе_ПроДокторов.pdf",
            },
          },
          [_vm._v("правилами клуба")]
        ),
        _vm._v(".\n  "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }