import { getGoalsList } from 'utils'

const CLUB_CARD_GETTING_YA_GOALS = getGoalsList({
  block: 'clubCardGetting',
  goals: [
    'closeBottomSheet', // Пользователь закрыл bottom-sheet
    'seeConfirmationViaBanner', // Пользователь увидел экран с подтверждением телефона перейдя через баннер (кнопка "[Продолжить]")
    'seeConfirmationViaAppointment', // Пользователь увидел экран с подтверждением телефона перейдя через любую кнопку записи (слоты/кнопка "[Записаться]"/цены)
    'phoneRequestCodeOnce', // Пользователь нажал кнопку "[Подтвердить]" телефон, отправляется один раз (без повторного подтверждения)
    'phoneConfirmed', // Пользователь успешно подтвердил телефон
    'seeClubEnter', // Пользователь увидел экран "Вы не в клубе" с предложением купить карту за ₽
    'seePaymentMade', // Пользователь увидел экран "Вы не в клубе" с предложением получить карту бесплатно
    'clickPayClubCard', // Пользователь нажал кнопку  "[Оплатить xx ₽]" на экране "Вы не в клубе"
    'seePaymentSuccess', // Пользователь увидел экран "Вы в клубе" после успешной оплаты
    'seePaymentError', // Пользователь увидел экран "Платёж не прошёл" после неудачной оплаты
    'successRedirectToAlfa', // Успешный редирект на страницу оплаты Альфы
    'errorRedirectToAlfa', // Неудачный редирект на страницу оплаты Альфы
    'seePreloaderAfterRedirectFromAlfa', // Пользователь вернулся со страницы оплаты Альфы и увидел прелоадер
  ],
})

export default CLUB_CARD_GETTING_YA_GOALS
