var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "card_getting_error" } },
    [
      _c("VImg", {
        staticClass: "mx-auto mb-2",
        attrs: {
          width: "72",
          height: "72",
          "data-qa": "card_getting_error_icon",
          src: "/static/_v1/pd/icons/ui-kit/club/club-check-close.svg",
          alt: "Иконка ошибки",
        },
      }),
      _vm._v(" "),
      _vm.componentData.title
        ? _c("div", {
            staticClass:
              "ui-text ui-text_h5 ui-kit-color-text mb-2 text-center",
            attrs: { "data-qa": "card_getting_error_title" },
            domProps: { textContent: _vm._s(_vm.componentData.title) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.componentData.message
        ? _c("div", {
            staticClass:
              "ui-text ui-text_body-1 ui-kit-color-text-secondary text-center",
            attrs: { "data-qa": "card_getting_error_message" },
            domProps: { textContent: _vm._s(_vm.componentData.message) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.buttonSubmit || _vm.buttonCancel
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _vm.buttonSubmit
                ? _c(
                    "VBtn",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        block: "",
                        color: "primary",
                        elevation: "0",
                        "data-qa": "card_getting_error_btn_submit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            _vm.events.club.cardGettingErrorSubmit,
                            _vm.buttonSubmit.action
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.buttonSubmit.text) + "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.buttonCancel
                ? _c(
                    "VBtn",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        text: "",
                        block: "",
                        color: "uiKitText",
                        elevation: "0",
                        "data-qa": "card_getting_error_btn_cancel",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            _vm.events.club.cardGettingErrorCancel,
                            _vm.buttonCancel.action
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.buttonCancel.text) + "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }