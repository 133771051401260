import { getGoalsList } from 'utils'

const CLUB_YANDEX_GOALS = getGoalsList({
  block: 'club',
  goals: [
    'clickClubSlot', // Переход по слоту клуба
    'appointmentForm', // Переход в форму записи, где есть клуб
    'infoModalGlobal', // Открытие модального окна с информацией
    'selectedPaymentTabClub', // Нажатие на кнопку записаться с оплатой через клуб
    'selectedPaymentTabLpu', // Нажатие на кнопку записаться с оплатой в клинике
    'appointmentSuccess', // Экран успеха с таймером скидки
    'clickPaymentButton', // Нажатие на кнопку оплатить
    'sendPaymentEmail', // Нажатие добавить в форме email
    'appointmentOpenPaymentWidget', // Открытие виджета на странице успешной записи
    'appointmentExpiredTimer', // Экран вы не успели воспользоваться скидкой
    'paymentPending', // Прелоадер оплата еще не зачислена
    'paymentSuccess', // Экран вы в клубе для тех у кого нет карты и для тех у кого есть (общая цель)
    'paymentError', // Страница с ошибкой оплаты
    'downloadCoupon', // Скачать купон на экране успеха
    'paymentSuccessRedirectToMedtochka', // Нажатие на детали записи на экране успеха
    'repeatPaymentButton', // Клик по кнопке - Повторить оплату(в случае неудачной оплаты)
    'clickGoWithoutPrepayment', // Клик по кнопке - Пойду по обычной цене
    'sendPaymentEmailUserWithoutCard', // Успешная отправка email при условии отсутствия карты у пользователя(и он ее будет приобретать)
  ],
})

export default CLUB_YANDEX_GOALS
