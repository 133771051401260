<template>
  <VApp>
    <component
      :is="versionDependedProps.topLevelComponent.component"
      v-model="isVisible"
      v-bind="versionDependedProps.topLevelComponent.props"
    >
      <VCard
        class="ui-text ui-kit-color-text"
        :class="versionDependedProps.classCardPadding"
      >
        <div
          v-if="isOptionCallDoctor"
          class="ui-text ui-text_h6"
          :class="{ 'mb-6': !pd_phone_alert }"
        >
          Запишитесь онлайн — <br>
          {{ pd_phone_alert ? 'так быстрее и удобнее' : 'так дешевле' }}
        </div>
        <div
          v-else-if="isOptionClubIsCheaper"
          class="ui-text ui-text_h6 mb-4"
        >
          Дешевле, чем в клинике
        </div>

        <div
          v-if="isOptionClubIsCheaper"
          class="ui-text mb-6"
          :class="versionDependedProps.classUiTextBody"
        >
          Оплачивать приём на&nbsp;сайте ПроДокторов дешевле —
          клиника подписала договор и&nbsp;обязана соблюдать это условие.
        </div>

        <template v-if="pd_phone_alert">
          <div class="ui-text ui-text_body-1 ui-kit-color-text mt-4">
            <div class="d-flex align-center py-3">
              <VIcon
                color="ui-kit-icon-secondary"
                class="mr-4"
                size="24"
              >
                ui-icon-phone-missed
              </VIcon>
              Не ждите ответа
            </div>
            <div class="d-flex align-center py-3">
              <VIcon
                color="ui-kit-icon-secondary"
                class="mr-4"
                size="24"
              >
                ui-icon-calendar-date-and-clock
              </VIcon>
              Выберите удобное время приёма
            </div>
            <div
              v-if="doctorData.isClub"
              class="d-flex align-center py-3"
            >
              <img
                src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
                class="mr-4"
                width="24"
                height="24"
                alt="Иконка Процент Клуба"
              >
              <div>
                <div>Воспользуйтесь клубной ценой</div>
                <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
                  Экономия {{ savingPrice }} {{ currency.symbol }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mb-6">
            <div
              class="d-flex align-items-center justify-content-between ui-text mb-4"
              :class="versionDependedProps.classUiTextBody"
            >
              <div v-if="isOptionCallDoctor">
                Через сайт
              </div>
              <div v-else-if="isOptionClubIsCheaper">
                ПроДокторов
              </div>
              <div
                class="ui-text"
                :class="versionDependedProps.classUiTextSubtitle"
              >
                <span
                  class="ui-text ui-kit-color-error mr-2"
                  :class="versionDependedProps.classUiTextSubtitle"
                  :data-qa="dataQALocators.appointmentNoticeDiscount"
                >
                  -{{ doctorData.clubDiscount }}%
                </span>

                <span
                  v-if="isOptionCallDoctor"
                  :data-qa="dataQALocators.appointmentNoticeClubPrice"
                >
                  {{ doctorData.price }} {{ currency.symbol }}
                </span>
                <span
                  v-else-if="isOptionClubIsCheaper"
                  :data-qa="dataQALocators.appointmentNoticeClubPrice"
                >
                  {{ doctorData.price }} {{ currency.symbol }}
                </span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between ui-text mb-4"
              :class="versionDependedProps.classUiTextBody"
            >
              <div v-if="isOptionCallDoctor">
                По телефону
              </div>
              <div v-else-if="isOptionClubIsCheaper">
                В клинике
              </div>
              <div
                class="ui-text"
                :class="versionDependedProps.classUiTextSubtitle"
                :data-qa="dataQALocators.appointmentNoticeLpuPrice"
              >
                {{ doctorData.priceFull }} {{ currency.symbol }}
              </div>
            </div>
          </div>

          <div
            v-if="isOptionCallDoctor"
            class="ui-text ui-text_body-1 text-center mb-10"
          >
            Экономия до
            <span :data-qa="dataQALocators.appointmentNoticeSavingPrice">
              {{ savingPrice }}
            </span>
            {{ currency.symbol }}
          </div>
        </template>
        <template v-if="isMobile">
          <a
            class="b-button b-button_blue b-button_full-width mb-2"
            :class="[pd_phone_alert ? 'mt-2' : 'mb-2']"
            :href="doctorData.appointmentLink"
            @click="handleClickButtonGoToApp"
          >
            <span class="ui-text ui-text_button ui-kit-color-bg-gray-0">
              Записаться онлайн
            </span>
          </a>
          <a
            v-if="isOptionCallDoctor"
            class="b-button b-button_text b-button_full-width"
            :class="{ 'mt-2': pd_phone_alert }"
            :href="hrefPhoneNumber"
            @click="handleClickButtonCall"
          >
            <span class="ui-text ui-text_button ui-kit-color-primary">
              позвонить
            </span>
          </a>
          <span
            v-else-if="isOptionClubIsCheaper"
            class="b-button b-button_text b-button_full-width"
            @click="handleClickButtonClose"
          >
            <span class="ui-text ui-text_button ui-kit-color-primary">
              закрыть
            </span>
          </span>
        </template>
        <template v-else>
          <div
            v-if="isOptionClubIsCheaper"
            class="d-flex justify-content-end"
          >
            <VBtn
              text
              class="px-2 py-3"
              :color="vuetifyColors.uiKitText"
              @click="handleClickButtonClose"
            >
              Закрыть
            </VBtn>
            <VBtn
              text
              class="px-2 py-3 ml-2 mr-n2"
              :color="vuetifyColors.primary"
              :href="doctorData.appointmentLink"
              @click="handleClickButtonGoToApp"
            >
              Записаться онлайн
            </VBtn>
          </div>
        </template>
      </VCard>
    </component>
  </VApp>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import { CLUB_EVENTS } from 'components/common/Club/constants'
import { MOBILE_CLUB_YANDEX_GOALS } from 'yandexGoals'
import YaGoals from 'modules/YandexGoals'
import { INFO_OPTIONS } from 'components/common/Club/components/dependencies/ClubAppointmentNotice/constants'
import { VBottomSheet, VDialog } from 'vuetify/lib'
import { ClubQALocators } from 'clubCommonMixins'
import LocationParser from 'modules/LocationParser'
import CurrencyData from 'components/common/mixins/CurrencyData'

export default {
  name: 'ClubAppointmentNotice',
  vuetify,
  mixins: [
    ClubQALocators,
    CurrencyData,
  ],
  data: () => ({
    isVisible: false,
    isMobile: window.MOBILE_VERSION,
    doctorData: {
      isDirect: false,
      isClub: false,
      clubDiscount: null,
      price: null,
      priceFull: null,
      phoneNumber: '',
      appointmentLink: '',
      infoOption: 'callDoctor',
    },
    instanceYaGoals: new YaGoals(),
    locationParserHref: new LocationParser(),
    infoOption: INFO_OPTIONS.callDoctor,
  }),
  computed: {
    pd_phone_alert: () => window.FEATURE_FLAGS.pd_phone_alert,
    hrefPhoneNumber() {
      return `tel:+7${this.doctorData.phoneNumber}`
    },
    savingPrice() {
      return this.doctorData.priceFull - this.doctorData.price
    },
    versionDependedProps() {
      return this.isMobile
        ? {
          topLevelComponent: {
            component: VBottomSheet,
            props: {},
          },
          classUiTextBody: 'ui-text_body-1',
          classUiTextSubtitle: 'ui-text_subtitle-1',
          classCardPadding: this.pd_phone_alert ? 'px-4 py-6' : 'p-4',
        }
        : {
          topLevelComponent: {
            component: VDialog,
            props: { width: 496 },
          },
          classUiTextBody: 'ui-text_body-2',
          classUiTextSubtitle: 'ui-text_subtitle-2',
          classCardPadding: 'px-4 pt-6 pb-2',
        }
    },
    isOptionCallDoctor() {
      return this.infoOption === INFO_OPTIONS.callDoctor
    },
    isOptionClubIsCheaper() {
      return this.infoOption === INFO_OPTIONS.clubIsCheaper
    },
    vuetifyColors() {
      return { ...this.$vuetify.theme.themes.light }
    },
  },
  mounted() {
    window.addEventListener(CLUB_EVENTS.clubClickShowNotice, this.handleClickShowNotice)
  },
  methods: {
    handleClickShowNotice({ detail }) {
      this.isVisible = true
      this.doctorData = detail
      this.infoOption = INFO_OPTIONS[detail.infoOption] || INFO_OPTIONS.callDoctor

      const { appointmentNoticeShow } = MOBILE_CLUB_YANDEX_GOALS

      this.doctorData.appointmentLink += `&source=${encodeURIComponent(this.locationParserHref.source)}`

      let goalName

      if (this.isOptionCallDoctor) {
        goalName = appointmentNoticeShow
      }

      if (goalName) {
        this.instanceYaGoals.send({ name: goalName })
      }
    },
    handleClickButtonCall() {
      const { appointmentNoticeCall } = MOBILE_CLUB_YANDEX_GOALS

      this.instanceYaGoals.send({ name: appointmentNoticeCall })
    },
    handleClickButtonClose() {
      this.isVisible = false
    },
    handleClickButtonGoToApp() {
      const { appointmentNoticeGoToApp } = MOBILE_CLUB_YANDEX_GOALS
      let goalName

      if (this.isOptionCallDoctor) {
        goalName = appointmentNoticeGoToApp
      }

      if (goalName) {
        this.instanceYaGoals.send({ name: goalName })
      }
    },
  },
}
</script>
