import getBooleanFromString from 'utils/get/getBooleanFromString'

/**
 * @description
 *
 * Возвращаю true при условии, что feature flag включен и есть атрибут в html
 * Вызов в HTML - data-feature-flag-test="{% if 'flag_name'|feature_enabled_for:request %}true{% endif %}"
 * Вызов в js - isFeatureFlagEnabledHTML({ dataAttr: 'data-feature-flag-test' });
 *
 * @param { Object } options
 * @param { String } options.dataAttr - название атрибута
 *
 * @return { Boolean|undefined } булевое значение состояния флага или undefined
 * */

/* TODO: переименовать в getAttributeBoolean, чтобы можно было использовать не только для флагов */

function isFeatureFlagEnabledHTML({ dataAttr = '' }) {
  const node = document.querySelector(`[${dataAttr}]`)
  const value = node?.getAttribute(dataAttr)

  return getBooleanFromString(value)
}

export default isFeatureFlagEnabledHTML
