<template>
  <VApp data-club-card-info>
    <VDialog
      v-model="isDialogVisible"
      :fullscreen="isMobile"
      :width="isMobile ? undefined : 456"
    >
      <ModalLayout
        title="Ваша клубная карта"
        class="club-card-info"
        data-qa="club_card_info"
        close-to
        :body-flex-basis-auto="!isMobile"
        @modal:back="handleCloseDialog"
      >
        <div class="p-10">
          <div class="club-card-info__card-container">
            <div class="w-100">
              <div class="club-card-info__card ui-text ui-text_body-1 ui-kit-color-bg-gray-0">
                <div class="d-flex align-items-center ui-text ui-text_h6">
                  <img
                    src="/static/_v1/pd/icons/ui-kit/club/club-percent-white.svg"
                    class="mr-2"
                    width="24"
                    height="24"
                    alt="Иконка Процент Клуба"
                  >
                  Клуб ПроДокторов
                </div>
                <div class="d-flex flex-direction-column align-items-center">
                  <img
                    src="/static/_v1/pd/icons/ui-kit/club/club-check-white.svg"
                    class="mb-2"
                    width="72"
                    height="72"
                    alt="Иконка Галочка Клуба"
                  >
                  <span
                    class="ui-text ui-text_h6 mb-1"
                    data-qa="club_card_info_user_phone"
                  >
                    {{ formattedPhone }}
                  </span>
                  <span class="ui-text ui-text_body-2">
                    в клубе до {{ clubData.cardDateEnd }}
                  </span>
                </div>
                <div>
                  Если возникнут вопросы:
                  <br>8 800 707-31-29
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!isMobile"
            class="mt-6"
          >
            <VBtn
              color="primary"
              data-qa="club_card_info_fixed_close_button"
              depressed
              block
              @click="handleCloseDialog"
            >
              Закрыть
            </VBtn>
          </div>
        </div>
        <BottomPanel
          v-if="isMobile"
          fixed
        >
          <template #container>
            <div class="px-2 pt-2 pb-6">
              <VBtn
                color="primary"
                data-qa="club_card_info_fixed_close_button"
                depressed
                block
                @click="handleCloseDialog"
              >
                Закрыть
              </VBtn>
            </div>
          </template>
        </BottomPanel>
      </ModalLayout>
    </VDialog>
  </VApp>
</template>

<script>
import { getPhoneFormatted } from 'utils'
import { CLUB_EVENTS } from 'www/constants'
import { CLUB_CARD_INFO_YA_GOALS } from 'yandexGoals'
import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import ModalLayout from 'components/mobile/core/ModalLayout'
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'

export default {
  components: {
    BottomPanel,
    ModalLayout,
  },
  mixins: [
    ManageYandexGoals,
  ],
  data() {
    return {
      isDialogVisible: false,
      clubData: window.USER.club,
      isMobile: window.MOBILE_VERSION,
      userPhone: window.USER.userPhone,
      yaGoalsList: CLUB_CARD_INFO_YA_GOALS,
    }
  },
  computed: {
    formattedPhone() {
      return getPhoneFormatted(this.userPhone)
    },
  },
  created() {
    window.addEventListener(CLUB_EVENTS.cardInfoOpen, ({ detail }) => {
      if (detail) {
        if (detail.clubData) {
          this.clubData = detail.clubData
        }

        if (detail.userPhone) {
          this.userPhone = detail.userPhone
        }
      }

      this.isDialogVisible = true
      this.sendYandexGoal('open')
    })
  },
  methods: {
    handleCloseDialog() {
      this.isDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.club-card-info {
  &__card-container {
    display: flex;

    &::before {
      content: '';
      display: block;
      padding-top: 145%;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 360px;
    padding: 16px 24px 8px;
    text-align: center;
    border-radius: $border-radius-md;
    background-color: $ui-kit-error;
    background-image: $club-coral-double-radial-gradient;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-club-card-info] {
  @include v-app-reset;
}
</style>
