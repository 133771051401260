import createDevNotice from 'utils/executors/createDevNotice'

/**
 * Экранирует специальные символы для строки, из которой создается регулярное выражение.
 * @param {string} string
 * @returns {string}
 * @see https://github.com/sindresorhus/escape-string-regexp
 */
export default function escapeStringRegexp(string) {
  if (typeof string !== 'string') {
    createDevNotice({
      module: 'escapeStringRegexp',
      description: 'Должна быть передана строка',
    })
  }

  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
}
