import { DIGIT_MASK, FIRST_DIGITS_MASK_LIST } from 'components/common/mixins/CountryData/constants'

/** @typedef { 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 } Digit */

/**
 * @param { Array<string> } symbolMaskList
 *
 * @return { Array<number> }
 * */
function getDigitsMaskPositions(symbolMaskList) {
  return symbolMaskList.reduce(
    (maskPositions, symbolMask, index) => {
      if (symbolMask === DIGIT_MASK) {
        maskPositions.push(index)
      }

      return maskPositions
    },
    [],
  )
}

/**
 * @param { string } bodyMask
 * @param { Array } firstDigits
 *
 * @return {{
 *   preparedBodyMask: string,
 *   preparedFormattedBodyMask: string,
 * }}
 * */
function getPreparedBodyMasks(bodyMask, firstDigits) {
  const symbolMaskList = bodyMask.split('')
  const symbolFormattedMaskList = bodyMask.split('')
  const digitsMaskPositions = getDigitsMaskPositions(symbolMaskList)

  firstDigits.forEach((digit, index) => {
    if (index >= digitsMaskPositions.length) {
      return
    }

    symbolMaskList[digitsMaskPositions[index]] = FIRST_DIGITS_MASK_LIST[index]
    symbolFormattedMaskList[digitsMaskPositions[index]] = digit
  })

  return {
    preparedBodyMask: symbolMaskList.join(''),
    preparedFormattedBodyMask: symbolFormattedMaskList.join(''),
  }
}

/**
 * @param { object } phoneData
 * @param { Array<string> } phoneData.bodyMasks
 * @param { Array<Digit> } phoneData.firstDigits
 * @param { string } phoneCode
 *
 * @return {{
 *   masks: Array<string>,
 *   formattedMasks: Array<string>,
 *   regexps: Array<RegExp>,
 *   tokens: Record<string, { pattern: RegExp }>,
 * }}
 * */
function getPhoneOptions({ bodyMasks, firstDigits = [] }, phoneCode) {
  const preparedBodyMasks = bodyMasks
  const preparedFormattedBodyMasks = bodyMasks
  /** @type { Record<string, { pattern: RegExp }> } */
  const tokens = {
    X: { pattern: /\d/ },
  }

  if (firstDigits && firstDigits.length) {
    bodyMasks.forEach(bodyMask => {
      const { preparedBodyMask, preparedFormattedBodyMask } = getPreparedBodyMasks(bodyMask, firstDigits)

      preparedBodyMasks.push(preparedBodyMask)
      preparedFormattedBodyMasks.push(preparedFormattedBodyMask)
    })

    firstDigits.forEach((digit, index) => {
      tokens[FIRST_DIGITS_MASK_LIST[index]] = { pattern: new RegExp(String(digit)) }
    })
  }

  const getMask = preparedMask => `${phoneCode} ${preparedMask}`
  const masks = preparedBodyMasks.map(getMask)
  const formattedMasks = preparedFormattedBodyMasks.map(getMask)
  const regexps = formattedMasks.map(formattedMask => {
    const regexpString = formattedMask.replaceAll(DIGIT_MASK, '\\d')
      .replace('+', '\\+')

    return new RegExp(regexpString)
  })

  return {
    masks,
    formattedMasks,
    regexps,
    tokens,
  }
}

export default getPhoneOptions
