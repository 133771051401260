import axios from 'axios'
import { axiosClient, getCSRFToken } from 'utils'

let axiosCancelTokenSource

/**
 * @description Метод для проверки и подтверждения номера телефона при помощи смс кода (не создает нового пользователя)
 *
 * Т.к. при подтверждении звонком автоматически создаётся профиль в МедТочке,
 * то для регистрации врача/клиники используем подтверждение через СМС
 *
 * @param { Object } options
 * @param { String } options.phone
 * @param { String } options.code
 *
 * @returns { Promise }
 */

function requestCheckCodeForUserNoRegistration({ phone, code }) {
  const formData = new FormData()

  formData.append('phone', phone)
  formData.append('code', code)

  axiosCancelTokenSource = axios.CancelToken.source()

  return axiosClient({
    method: 'POST',
    url: '/profile/ajax/confirm_sms_code/',
    data: formData,
    headers: {
      'X-CSRFToken': getCSRFToken(),
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: axiosCancelTokenSource.token,
  })
}

function cancelRequestCheckCodeForUserNoRegistration() {
  if (axiosCancelTokenSource?.cancel) {
    axiosCancelTokenSource.cancel()
  }
}

export {
  requestCheckCodeForUserNoRegistration,
  cancelRequestCheckCodeForUserNoRegistration,
}
