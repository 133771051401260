import UserStorage from 'modules/UserStorage'
import { LOGIN_ATTEMPT_STORAGE_NAME } from 'www/global.js.blocks/common/auto-login/constants'

function resetLoginAttemptStorage(uuid) {
  UserStorage.set(LOGIN_ATTEMPT_STORAGE_NAME, {
    uuid,
    attempt: 1,
    date: null,
    isExceeded: false,
  })
}

export default resetLoginAttemptStorage
