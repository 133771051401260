var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bottom-panel",
      class: {
        "bottom-panel_mobile": _vm.isMobile,
        "bottom-panel_no-height": _vm.noHeight,
      },
    },
    [
      _c(
        "div",
        { staticClass: "bottom-panel__content", style: _vm.fixedStyle },
        [
          _c(
            "div",
            { staticClass: "bottom-panel__container" },
            [_vm._t("container")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }