const dailyLimitElement = document.querySelector('[data-daily-limit]')
const dailyLimit = dailyLimitElement ? dailyLimitElement.dataset.dailyLimit : '10 раз'

export const ERROR_MESSAGES = {
  slot_already_busy: 'Запись на приём не удалась. Выбранное время другой пациент успел забронировать раньше вас.<br><br> Пожалуйста, выберите другое время.',
  patient_has_record: 'Запись на приём не удалась. У Вас уже есть запись на приём в эту клинику.',
  error_max_appointments: `Вы записались на прием ${dailyLimit} за последние сутки.<br> Достигнут лимит количества записей`,
  error_limit_doctor_appointment: 'Достигнут лимит записей к врачу',
  error_limit_addr_appointment: 'Вы уже отправили заявку на запись',
  error_appointment_disabled: 'Запись к врачу выключена',
}
