/**
 * @description
 *
 * Возвращает все query-параметры в виде объекта
 *
 *
 * @example
 * getObjectQueryParams('?a=1&b=2&c=3')
 * >>>: { a: '1', b: '2', c: '3' }
 *
 * getObjectQueryParams('https://prodoctorov.ru/?d=4&e=5&f=')
 * >>>: { d: '4', e: '5', f: '' }
 *
 * getObjectQueryParams() // если функция вызывается на странице https://prodoctorov.ru/?g=6&h=7
 * >>>: { g: '6', h: '7' }
 *
 *
 * @param { String } queryString - строка с query-параметрами (по умолчанию содержит query-параметры текущей страницы)
 *
 * @return { Object | undefined }
 * */

function getObjectQueryParams(queryString = window.location.search) {
  const params = new URLSearchParams(queryString)

  if (!params) {
    return
  }

  return Object.fromEntries(params)
}

export default getObjectQueryParams
