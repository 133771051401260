export const PAGE_SOURCES = {
  lpuList: ['lpu-list', 'lpu-list-filter', 'lpu-list-place'],
  lpuInner: ['lpu-inner'],
  doctorList: ['doctor-list', 'doctor-list-filter', 'doctor-list-treatment', 'doctor-list-place'],
  doctorListOnline: ['doctor-list-online', 'doctor-list-online-filter'],
  doctorInner: ['doctor-inner'],
  serviceList: ['service-list'],
  withoutSource: ['without-source'],
  diseaseDoctorList: ['disease-doctor-list'],
}

export const REGEXP_PATTERNS = {
  'doctor-list-treatment': new RegExp(`${window.location.origin}/[-a-z]+/lechenie/[0-9-a-z]+/`, 'gi'),
  'doctor-list': new RegExp(`${window.location.origin}/(?!online)[-a-z]+/vrach/$`, 'gi'),
  'doctor-list-place': new RegExp(`${window.location.origin}/(?!online)[-a-z]+/vrach/[-a-z?]+`, 'gi'),
  'doctor-list-filter': new RegExp(`${window.location.origin}/(?!online)[-a-z]+/(?!lpu|top|vrach|uslugi|diagnostika|lechenie)([-a-z?]+)/`, 'gi'),
  'doctor-list-online': new RegExp(`${window.location.origin}/online/vrach/`, 'gi'),
  'doctor-list-online-filter': new RegExp(`${window.location.origin}/online/(?!lpu|top|vrach|uslugi|diagnostika|lechenie)([-a-z?]+)/`, 'gi'),
  'doctor-inner': new RegExp(`${window.location.origin}/[-a-z]+/vrach/[0-9]+[-0-9a-z]+/`, 'gi'),
  'lpu-list': new RegExp(`${window.location.origin}/[-a-z]+/lpu/$`, 'gi'),
  'lpu-list-place': new RegExp(`${window.location.origin}/[-a-z]+/lpu/[-a-z?]+`, 'gi'),
  'lpu-list-filter': new RegExp(`${window.location.origin}/[-a-z]+/top/[-a-z?]+/`, 'gi'),
  'lpu-inner': new RegExp(`${window.location.origin}/[-a-z]+/lpu/[0-9]+[-0-9a-z]+/`, 'gi'),
  'service-list': new RegExp(`${window.location.origin}/[-a-z]+/uslugi|diagnostika/`, 'gi'),
}
