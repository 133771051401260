/**
 * @description
 *
 * Перенаправляет по указанному URL
 *
 * @param { String } url - путь для редиректа
 */
function setLocation(url) {
  window.location.href = url
}

export default setLocation
