var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VForm",
    {
      attrs: { "data-qa": "card_getting_email_enter" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.$emit(
            _vm.events.club.cardGettingEmailSent,
            _vm.state.emailValue
          )
        },
      },
      model: {
        value: _vm.state.isValidForm,
        callback: function ($$v) {
          _vm.$set(_vm.state, "isValidForm", $$v)
        },
        expression: "state.isValidForm",
      },
    },
    [
      _c(
        "div",
        { staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-2 text-left" },
        [_vm._v("\n    Введите Email\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ui-text ui-text_body-1 ui-kit-color-text-secondary mb-4 text-left",
        },
        [_vm._v("\n    Вышлем чек\n  ")]
      ),
      _vm._v(" "),
      _c("VTextField", {
        attrs: {
          rules: _vm.componentData.emailValidationRules,
          "append-icon": _vm.componentData.vuetifyIcons.emailIcon,
          label: "Email",
          "data-qa": "card_getting_email_enter_input",
          outlined: "",
        },
        model: {
          value: _vm.state.emailValue,
          callback: function ($$v) {
            _vm.$set(_vm.state, "emailValue", $$v)
          },
          expression: "state.emailValue",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end" },
        [
          _c(
            "VBtn",
            {
              attrs: {
                type: "submit",
                text: "",
                disabled: _vm.isDisabledSendButton,
                color: _vm.componentData.vuetifyColors.primary,
                "data-qa": "card_getting_email_enter_submit",
              },
            },
            [_vm._v("\n      Отправить\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }