var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "text-center": !_vm.isMobile },
      attrs: { "data-qa": "user_authorization_phone_to_call" },
    },
    [
      _c(
        "div",
        { staticClass: "ui-text ui-text_body-1 ui-kit-color-text mb-4" },
        [
          _vm._v(
            "\n    Для подтверждения позвоните нам.\n    Вам не придётся ничего говорить: система сама завершит звонок.\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isMobile
        ? _c(
            "VBtn",
            {
              staticClass: "mb-4",
              attrs: {
                block: "",
                elevation: "0",
                color: "primary",
                href: "tel:88005000066",
                "data-qa": "user_authorization_phone_to_call_button",
              },
            },
            [_vm._v("\n    Позвонить 8 800 500-00-66\n  ")]
          )
        : _c(
            "div",
            {
              staticClass: "ui-text ui-text_h4 ui-kit-color-text mb-2",
              attrs: { "data-qa": "user_authorization_phone_to_call_number" },
            },
            [_vm._v("\n    8 800 500-00-66\n  ")]
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ui-text ui-text_body-1 ui-kit-color-text text-center" },
        [_vm._v("\n    Звонок бесплатный\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }