var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown-item",
      class: { "dropdown-item_selected": _vm.isSelectedItem },
      on: { click: _vm.handleClick },
    },
    [
      _vm.transformItemData.itemImg
        ? _c("img", {
            staticClass: "dropdown-item__img mr-4",
            attrs: {
              src: _vm.transformItemData.itemImg,
              alt: "аватар",
              width: "40",
              height: "40",
            },
          })
        : _vm.transformItemData.itemIcon
        ? _c("span", {
            staticClass: "ui-kit-color-icon-secondary dropdown-item__icon mr-8",
            class: _vm.transformItemData.itemIcon,
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _c("span", {
            staticClass: "ui-text ui-text_body-1",
            class: { "ui-kit-color-primary": _vm.isItemShowMore },
            domProps: { innerHTML: _vm._s(_vm.titleWithMarkedQuery) },
          }),
        ]),
        _vm._v(" "),
        _vm.transformItemData.itemSubtitle
          ? _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_body-2 ui-kit-color-text-secondary",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.transformItemData.itemSubtitle) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }