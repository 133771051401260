import confetti from 'canvas-confetti'
import styleVars from 'www/themes/doctors/common/variables-export.module.scss'

const PARTICLE_COUNT = 200
const DEFAULT_OPTIONS = { origin: { y: 0.7 } }

function fire(particleRatio, options) {
  confetti({
    ...DEFAULT_OPTIONS,
    ...options,
    particleCount: Math.floor(PARTICLE_COUNT * particleRatio),
    colors: [styleVars.uiKitBrand, styleVars.uiKitSecondary, styleVars.uiKitBgGray0],
  })
}

function fireConfetti() {
  fire(0.25, { spread: 26, startVelocity: 55 })
  fire(0.2, { spread: 60 })
  fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 })
  fire(0.1, {
    spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2,
  })
  fire(0.1, { spread: 120, startVelocity: 45 })
}

export default fireConfetti
