var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    [
      _c(
        _vm.versionDependedProps.topLevelComponent.component,
        _vm._b(
          {
            tag: "component",
            model: {
              value: _vm.isVisible,
              callback: function ($$v) {
                _vm.isVisible = $$v
              },
              expression: "isVisible",
            },
          },
          "component",
          _vm.versionDependedProps.topLevelComponent.props,
          false
        ),
        [
          _c(
            "VCard",
            {
              staticClass: "ui-text ui-kit-color-text",
              class: _vm.versionDependedProps.classCardPadding,
            },
            [
              _vm.isOptionCallDoctor
                ? _c(
                    "div",
                    {
                      staticClass: "ui-text ui-text_h6",
                      class: { "mb-6": !_vm.pd_phone_alert },
                    },
                    [
                      _vm._v("\n        Запишитесь онлайн — "),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.pd_phone_alert
                              ? "так быстрее и удобнее"
                              : "так дешевле"
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm.isOptionClubIsCheaper
                ? _c("div", { staticClass: "ui-text ui-text_h6 mb-4" }, [
                    _vm._v("\n        Дешевле, чем в клинике\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isOptionClubIsCheaper
                ? _c(
                    "div",
                    {
                      staticClass: "ui-text mb-6",
                      class: _vm.versionDependedProps.classUiTextBody,
                    },
                    [
                      _vm._v(
                        "\n        Оплачивать приём на сайте ПроДокторов дешевле —\n        клиника подписала договор и обязана соблюдать это условие.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pd_phone_alert
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-1 ui-kit-color-text mt-4",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center py-3" },
                          [
                            _c(
                              "VIcon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  color: "ui-kit-icon-secondary",
                                  size: "24",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              ui-icon-phone-missed\n            "
                                ),
                              ]
                            ),
                            _vm._v("\n            Не ждите ответа\n          "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center py-3" },
                          [
                            _c(
                              "VIcon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  color: "ui-kit-icon-secondary",
                                  size: "24",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              ui-icon-calendar-date-and-clock\n            "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n            Выберите удобное время приёма\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.doctorData.isClub
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-center py-3" },
                              [
                                _c("img", {
                                  staticClass: "mr-4",
                                  attrs: {
                                    src: "/static/_v1/pd/icons/ui-kit/club/club-percent.svg",
                                    width: "24",
                                    height: "24",
                                    alt: "Иконка Процент Клуба",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", [
                                  _c("div", [
                                    _vm._v("Воспользуйтесь клубной ценой"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ui-text ui-text_body-2 ui-kit-color-text-secondary",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Экономия " +
                                          _vm._s(_vm.savingPrice) +
                                          " " +
                                          _vm._s(_vm.currency.symbol) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                : [
                    _c("div", { staticClass: "mb-6" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between ui-text mb-4",
                          class: _vm.versionDependedProps.classUiTextBody,
                        },
                        [
                          _vm.isOptionCallDoctor
                            ? _c("div", [
                                _vm._v(
                                  "\n              Через сайт\n            "
                                ),
                              ])
                            : _vm.isOptionClubIsCheaper
                            ? _c("div", [
                                _vm._v(
                                  "\n              ПроДокторов\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ui-text",
                              class:
                                _vm.versionDependedProps.classUiTextSubtitle,
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "ui-text ui-kit-color-error mr-2",
                                  class:
                                    _vm.versionDependedProps
                                      .classUiTextSubtitle,
                                  attrs: {
                                    "data-qa":
                                      _vm.dataQALocators
                                        .appointmentNoticeDiscount,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                -" +
                                      _vm._s(_vm.doctorData.clubDiscount) +
                                      "%\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isOptionCallDoctor
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-qa":
                                          _vm.dataQALocators
                                            .appointmentNoticeClubPrice,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.doctorData.price) +
                                          " " +
                                          _vm._s(_vm.currency.symbol) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm.isOptionClubIsCheaper
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-qa":
                                          _vm.dataQALocators
                                            .appointmentNoticeClubPrice,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.doctorData.price) +
                                          " " +
                                          _vm._s(_vm.currency.symbol) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between ui-text mb-4",
                          class: _vm.versionDependedProps.classUiTextBody,
                        },
                        [
                          _vm.isOptionCallDoctor
                            ? _c("div", [
                                _vm._v(
                                  "\n              По телефону\n            "
                                ),
                              ])
                            : _vm.isOptionClubIsCheaper
                            ? _c("div", [
                                _vm._v(
                                  "\n              В клинике\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ui-text",
                              class:
                                _vm.versionDependedProps.classUiTextSubtitle,
                              attrs: {
                                "data-qa":
                                  _vm.dataQALocators.appointmentNoticeLpuPrice,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.doctorData.priceFull) +
                                  " " +
                                  _vm._s(_vm.currency.symbol) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.isOptionCallDoctor
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_body-1 text-center mb-10",
                          },
                          [
                            _vm._v("\n          Экономия до\n          "),
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-qa":
                                    _vm.dataQALocators
                                      .appointmentNoticeSavingPrice,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.savingPrice) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.currency.symbol) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
              _vm._v(" "),
              _vm.isMobile
                ? [
                    _c(
                      "a",
                      {
                        staticClass:
                          "b-button b-button_blue b-button_full-width mb-2",
                        class: [_vm.pd_phone_alert ? "mt-2" : "mb-2"],
                        attrs: { href: _vm.doctorData.appointmentLink },
                        on: { click: _vm.handleClickButtonGoToApp },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "ui-text ui-text_button ui-kit-color-bg-gray-0",
                          },
                          [
                            _vm._v(
                              "\n            Записаться онлайн\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isOptionCallDoctor
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "b-button b-button_text b-button_full-width",
                            class: { "mt-2": _vm.pd_phone_alert },
                            attrs: { href: _vm.hrefPhoneNumber },
                            on: { click: _vm.handleClickButtonCall },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ui-text ui-text_button ui-kit-color-primary",
                              },
                              [_vm._v("\n            позвонить\n          ")]
                            ),
                          ]
                        )
                      : _vm.isOptionClubIsCheaper
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "b-button b-button_text b-button_full-width",
                            on: { click: _vm.handleClickButtonClose },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ui-text ui-text_button ui-kit-color-primary",
                              },
                              [_vm._v("\n            закрыть\n          ")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.isOptionClubIsCheaper
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _c(
                              "VBtn",
                              {
                                staticClass: "px-2 py-3",
                                attrs: {
                                  text: "",
                                  color: _vm.vuetifyColors.uiKitText,
                                },
                                on: { click: _vm.handleClickButtonClose },
                              },
                              [_vm._v("\n            Закрыть\n          ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "VBtn",
                              {
                                staticClass: "px-2 py-3 ml-2 mr-n2",
                                attrs: {
                                  text: "",
                                  color: _vm.vuetifyColors.primary,
                                  href: _vm.doctorData.appointmentLink,
                                },
                                on: { click: _vm.handleClickButtonGoToApp },
                              },
                              [
                                _vm._v(
                                  "\n            Записаться онлайн\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }