import { REGEX } from 'utils/regex'

/**
 * @description
 *
 * Функция возвращает объект для валидации поля "email"
 *
 * @return { Object } - объект функций с валидацией поля по различным параметрам. Каждая функция принимает обязательное значение поля - value
 * */

const EMAIL_MAX_LENGTH = 65

function getEmailValidation() {
  return {
    required: val => !!val || 'Укажите почту',
    onlyEn: val => !REGEX.ru.test(val) || 'Используйте только латиницу',
    regex: val => REGEX.email.test(val.trim()) || 'Некорректный email',
    maxLength: val => val.length <= EMAIL_MAX_LENGTH || `Должно быть не больше ${EMAIL_MAX_LENGTH} символов`,
  }
}

export default getEmailValidation
