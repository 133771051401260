import { getGoalsList } from 'utils'

const CLUB_CARD_INFO_YA_GOALS = getGoalsList({
  block: 'clubCardInfo',
  goals: [
    'open', // Открыть модалку "Ваша клубная карта"
  ],
})

export default CLUB_CARD_INFO_YA_GOALS
