var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "card_getting_confirmation" } },
    [
      _c("div", { staticClass: "ui-text ui-text_h6 ui-kit-color-text" }, [
        _vm._v("\n    Получить клубную цену\n  "),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "UserAuthorization",
        _vm._g(
          { ref: "user-authorization" },
          {
            [_vm.events.userAuth.confirmed]: _vm.handleEventConfirmed,
            [_vm.events.userAuth.requested]: _vm.handleCodeRequestedOnce,
          }
        )
      ),
      _vm._v(" "),
      _c(
        "VBtn",
        {
          staticClass: "mt-4",
          attrs: { text: "", block: "", "data-qa": _vm.dataQaCancelButton },
          on: { click: _vm.handleClickButton },
        },
        [_vm._v("\n    " + _vm._s(_vm.variationBtnText) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "ui-text ui-text_body-1 ui-kit-color-text mt-2 mb-6" },
      [
        _vm._v("\n    Введите номер телефона — проверим,"),
        _c("br"),
        _vm._v(" в клубе ли вы\n  "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }