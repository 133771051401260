import { NAMESPACE, REQUEST_TYPES } from 'modules/Timetable/constants'

const attrs = {
  lpuInfo: 'b-doctor-card__selected-lpu-info',
  lpuInfoActive: 'b-doctor-card__lpu-info_active',
}

/**
 * @description
 *
 * Возвращает значение выбранного типа приёма(клуб, онлайн, телемед, на дом)
 *
 * @param { Object } arguments
 * @param { Object } arguments.target - слот, по которому произошёл клик
 * @param { Object } arguments.options - параметры настройки модуля Timetable
 *
 * @return { String } - выбранный тип или undefined если ничего не найдено
 *
 * */

function getVisitType({ target, options = {} } = {}) {
  const defaultResult = ''
  const parentContainer = target?.closest(`[${options.core.namespace.dataItem}]`)

  if (!parentContainer) {
    return defaultResult
  }

  if (options.core.typeRequest === REQUEST_TYPES.doctor) {
    const filterTabsElement = document.querySelector('[data-chosen-appointment-type-filter]')

    if (filterTabsElement) {
      return filterTabsElement.getAttribute('data-chosen-appointment-type-filter') || defaultResult
    }

    const selectedWpTabListMobile = [...parentContainer.querySelectorAll(`.${attrs.lpuInfo}`)]
      .filter(item => getComputedStyle(item).display !== 'none')[0]
    const selectedWpTabListDesktop = parentContainer.querySelector(`.${attrs.lpuInfoActive}`)
    const activeTabWrapper = selectedWpTabListMobile || selectedWpTabListDesktop || parentContainer

    if (!activeTabWrapper) {
      return defaultResult
    }

    const tabsWrapper = activeTabWrapper.querySelector(NAMESPACE.common.selectorAppointmentTypeTab)
    const visitType = tabsWrapper?.getAttribute(NAMESPACE.dataAttrs.activeTabValue)
            || activeTabWrapper.querySelector(`[${NAMESPACE.dataAttrs.activeTabValue}]`)?.getAttribute(NAMESPACE.dataAttrs.activeTabValue)

    return visitType || defaultResult
  }
}

export default getVisitType
