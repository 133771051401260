import Vue from 'components'
import ClubAppointmentNotice from 'components/common/Club/components/dependencies/ClubAppointmentNotice/ClubAppointmentNotice'
import { setListenerPhoneClick } from 'components/common/Club/components/dependencies/ClubAppointmentNotice/functions'
import { MOUNT_SELECTOR } from 'components/common/Club/components/dependencies/ClubAppointmentNotice/constants'

const el = document.querySelector(MOUNT_SELECTOR)

if (el) {
  new Vue({
    el,
    render: h => h(ClubAppointmentNotice),
  })

  setListenerPhoneClick()
}
