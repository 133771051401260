var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DialogBottomSheet",
    {
      attrs: {
        eager: "",
        persistent: "",
        "no-click-animation": "",
        "is-visible": _vm.isVisibleWithDelay,
        width: _vm.isMobile ? "100%" : "360px",
      },
    },
    [
      _c(
        "VCard",
        [
          _c("VCardTitle", { staticClass: "pb-4" }, [
            _c("div", { staticClass: "ui-text ui-text_h6 ui-kit-color-text" }, [
              _vm._v("\n        Войдите, чтобы продолжить\n      "),
            ]),
          ]),
          _vm._v(" "),
          !_vm.isMobile ? _c("VDivider") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.isMobile ? "px-2 pb-6" : "px-4 py-6" },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }