import { getKebabFromCamel } from 'utils'
import { PAGE_SOURCES, REGEXP_PATTERNS } from 'modules/LocationParser/constants'

class LocationParser {
  constructor(options = {}) {
    this._createOptions(options)
  }

  get source() {
    if (this.isDiseasePage) {
      return getKebabFromCamel(PAGE_SOURCES.diseaseDoctorList[0])
    }

        for (const [pageName, patternValue] of Object.entries(this.matches)) { // eslint-disable-line
      if (patternValue) {
                for (const [source, pagesGroups] of Object.entries(PAGE_SOURCES)) { // eslint-disable-line
          if (pagesGroups.includes(pageName)) {
            return getKebabFromCamel(source)
          }
        }
      }
    }

    return getKebabFromCamel(PAGE_SOURCES.withoutSource[0])
  }

  get isDiseasePage() {
    return this.options.testedLocation.indexOf(window.PROBOLEZNY_DOMAIN) !== -1
  }

  get matches() {
    const data = {}

    Object.entries(REGEXP_PATTERNS).forEach(([name, pattern]) => {
      data[name] = !!this.options.testedLocation.match(pattern)
    })

    return data
  }

  _createOptions(options = {}) {
    const defaultOptions = {
      testedLocation: 'href',
    }

    const mergedOptions = {
      ...defaultOptions,
      ...options,
    }

    if (mergedOptions.testedLocation === 'href') {
      mergedOptions.testedLocation = window.location.href
    }

    if (mergedOptions.testedLocation === 'referrer') {
      mergedOptions.testedLocation = document.referrer
    }

    this.options = mergedOptions
  }
}

export default LocationParser
