<template>
  <div data-qa="card_getting_error">
    <VImg
      width="72"
      height="72"
      class="mx-auto mb-2"
      data-qa="card_getting_error_icon"
      src="/static/_v1/pd/icons/ui-kit/club/club-check-close.svg"
      alt="Иконка ошибки"
    />
    <div
      v-if="componentData.title"
      data-qa="card_getting_error_title"
      class="ui-text ui-text_h5 ui-kit-color-text mb-2 text-center"
      v-text="componentData.title"
    />
    <div
      v-if="componentData.message"
      data-qa="card_getting_error_message"
      class="ui-text ui-text_body-1 ui-kit-color-text-secondary text-center"
      v-text="componentData.message"
    />
    <div
      v-if="buttonSubmit || buttonCancel"
      class="mt-4"
    >
      <VBtn
        v-if="buttonSubmit"
        block
        color="primary"
        class="mt-2"
        elevation="0"
        data-qa="card_getting_error_btn_submit"
        @click="$emit(events.club.cardGettingErrorSubmit, buttonSubmit.action)"
      >
        {{ buttonSubmit.text }}
      </VBtn>
      <VBtn
        v-if="buttonCancel"
        text
        block
        color="uiKitText"
        class="mt-2"
        elevation="0"
        data-qa="card_getting_error_btn_cancel"
        @click="$emit(events.club.cardGettingErrorCancel, buttonCancel.action)"
      >
        {{ buttonCancel.text }}
      </VBtn>
    </div>
  </div>
</template>

<script>
import {
  eventsData,
  getErrorData,
} from 'components/common/Club/components/core/ClubCardGetting/interfaces'

export default {
  name: 'ClubCardGettingError',
  props: {
    componentData: {
      type: Object,
      default: getErrorData,
    },
  },
  data: () => ({
    events: eventsData,
  }),
  computed: {
    buttonSubmit() {
      return this.componentData.buttonState.submit
    },
    buttonCancel() {
      return this.componentData.buttonState.cancel
    },
  },
}
</script>
