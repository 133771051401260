var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TheHeaderLine", {
        attrs: {
          "site-logo": _vm.siteLogo,
          search: _vm.search,
          "data-qa": "the_header_line",
        },
        on: { "header:click-search-btn": _vm.handleShowDialog },
      }),
      _vm._v(" "),
      _c("TheHeaderLine", {
        attrs: {
          "is-sticky": "",
          "site-logo": _vm.siteLogo,
          search: _vm.search,
          "is-visible-search-dialog": _vm.isVisibleSearchDialog,
        },
        on: { "header:click-search-btn": _vm.handleShowDialog },
      }),
      _vm._v(" "),
      _vm.search.useRender && _vm.isVisibleSearchDialog
        ? _c("TheHeaderSearchDialog", {
            staticClass: "the-header__dialog",
            attrs: { search: _vm.search },
            on: {
              "search-dialog:hide": _vm.handleCloseDialog,
              "search-site:submit-form": _vm.handleSubmitForm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }