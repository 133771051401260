const ClubWidgetData = {
  data: () => ({
    paymentURLRedirect: '',
  }),
  computed: {
    $_couponRequestId() {
      return this.couponRequest?.id || this.couponRequestId
    },
    $_couponRequestEmail() {
      return this.couponRequest?.email || this.couponRequestEmail
    },
  },
  methods: {
    getRequestBody() {
      return {
        data: {},
        couponRequestId: this.$_couponRequestId,
        couponRequestEmail: this.$_couponRequestEmail,
      }
    },
    updatePaymentUrl() {
      if (typeof Number(this.$_couponRequestId) === 'number') {
        this.clubPaymentUrl = `${window.location.origin}/club/coupon_request/${this.$_couponRequestId}/payment_status/`
      }
    },
    redirectToPayment() {
      window.location.assign(this.paymentURLRedirect)
    },
    setResponseData({ response } = {}) {
      if (!response) {
        return
      }

      this.paymentURLRedirect = response.provider_payment_data.url
    },
  },
}

export default ClubWidgetData
