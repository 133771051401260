/**
 * @description
 *
 * Находит первый последний индекс, который удовлетворяет условию в колбэке.
 *
 * @param { Array } array - массив для поиска
 * @param { Function } predicate - функция с условием
 *
 * @return { Number } - найденная позиция. Если не найдено вернется -1
 */

function findLastIndex(array, predicate) {
  let index = array.length

  while (index--) {
    if (predicate(array[index], index, array)) {
      return index
    }
  }

  return -1
}

export default findLastIndex
