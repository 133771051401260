import deepMerge from 'utils/withDependences/deepMerge'
import getTypeObject from 'utils/get/getTypeObject'
import createDevNotice from 'utils/executors/createDevNotice'

/**
 * @description
 *
 * Функция, используя настройки интервала, запускает проверку переданного testedValue и если рекурсивная проверка
 * выполнится успешно - вызовется "resolve", иначе вызовется "reject"
 *
 * Если поля "timeoutOptions" некорректны - они заменяются на дефолтные
 *
 * @params { Object } options
 * @params { Object } options.timeoutOptions - настройки интервала
 * @params { Object } options.timeoutOptions.delay - задержка таймаута
 * @params { Object } options.timeoutOptions.quantity - количество итераций
 * @params { Function } resolve - функция, которая выполнится в случае успеха проверки testedValue
 * @params { Function } reject - функция, которая выполнится в случае неудачи при проверке testedValue в течение "options.timeoutOptions.quantity"
 * @params { Function } testedValue - функция, которая должна возвращать булевое значение, которое будет использовано в условии
 *
 * @example
 *
 * promiseExecute({
 *      timeoutOptions: { // будет произведено 10 проверок testedValue с интервалом в 100мс
 *          delay: 100,
 *          quantity: 10,
 *      },
 *      resolve() { // выполнится в случае успеха
 *          console.log('resolved!');
 *      },
 *      reject() { // выполнится в случае неудачи
 *          console.log('rejected!');
 *      },
 *      testedValue() { // каждую итерацию проверяю наличие функции
 *          return window.testedValue;
 *      },
 * });
 *
 * @return { Function | undefined } destroyPromiseExecute - функция, которая уничтожает таймер. Если undefined,
 * значит были переданы не все необходимые опции для запуска таймер
 * */

const TYPES_DATA = {
  obj: 'object',
  fn: 'function',
}

const getDefaultOptions = () => ({
  timeoutOptions: {
    delay: 100,
    quantity: 10,
  },
})

let timerId = null

const getMergedOptions = options => deepMerge(getDefaultOptions(), options)

function executeTest(mergedOptions) {
  if (!mergedOptions.testedValue()) {
    if (mergedOptions.timeoutOptions.counter < mergedOptions.timeoutOptions.quantity) {
      timerId = setTimeout(() => {
        mergedOptions.timeoutOptions.counter++
        executeTest(mergedOptions)
      }, mergedOptions.timeoutOptions.delay)

      return
    }

    mergedOptions.reject()

    return
  }

  mergedOptions.resolve()
}

function setCorrectlyOptions(mergedOptions) {
  const defaultOptions = getDefaultOptions()
  const optionEntries = Object.entries(mergedOptions.timeoutOptions)

  optionEntries.forEach(([key, value]) => {
    /**
         * Не вынес примитив в константу, потому что задана настройка линтера. Она проверяет правописание
         * */

    if (typeof value !== 'number') {
      mergedOptions.timeoutOptions[key] = defaultOptions.timeoutOptions[key]
    }
  })
}

function testOptionsFunctions(mergedOptions) {
  const testFunctions = {
    reject: getTypeObject(mergedOptions.reject) === TYPES_DATA.fn,
    resolve: getTypeObject(mergedOptions.resolve) === TYPES_DATA.fn,
    testedValue: getTypeObject(mergedOptions.testedValue) === TYPES_DATA.fn,
  }

  return Object.entries(testFunctions)
    .every(([key, value]) => {
      if (!value) {
        createDevNotice({
          module: 'promiseExecute',
          description: `Поле "${key}" не является функцией`,
        })

        return false
      }

      return true
    })
}

function destroyPromiseExecute() {
  clearTimeout(timerId)
}

function promiseExecute(options = {}) {
  if (getTypeObject(options) !== TYPES_DATA.obj) {
    createDevNotice({
      module: 'promiseExecute',
      description: 'Переданный аргумент "options" не является объектом',
    })

    return
  }

  const mergedOptions = getMergedOptions(options)

  setCorrectlyOptions(mergedOptions)

  const isOptionsFunctions = testOptionsFunctions(mergedOptions)

  if (!isOptionsFunctions) {
    return
  }

  mergedOptions.timeoutOptions.counter = 0

  executeTest(mergedOptions)

  return destroyPromiseExecute
}

export default promiseExecute
