export const ICONS = {
  close: {
    name: 'close',
    classes: 'ui-icon-close-not-a-circle',
  },
  arrowBack: {
    name: 'arrowBack',
    classes: 'ui-icon-arrow-back',
  },
}
