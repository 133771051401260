var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SearchSite",
    {
      staticClass: "base-search",
      class: { "base-search_desktop": _vm.fullMode },
      attrs: {
        action: _vm.action,
        "ajax-action": _vm.ajaxAction,
        location: {
          town: _vm.town,
          district: _vm.district,
          microdistrict: _vm.microdistrict,
          street: _vm.street,
          metro: _vm.metro,
        },
        placeholder: _vm.placeholder,
        "preview-text": _vm.prompt,
      },
      scopedSlots: _vm._u([
        {
          key: "input-control",
          fn: function ({
            isFocused,
            inputValue,
            handleBlur,
            handleFocus,
            handleInput,
            handleKeydownArrowUp,
            handleKeydownArrowDown,
          }) {
            return [
              _c("MaterialInput", {
                ref: "inputComponent",
                staticClass: "base-search__input",
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  value: inputValue,
                  "borders-has": _vm.fullMode ? "1 1 1 1" : "1 0 1 1",
                  placeholder: _vm.placeholder,
                  gray: _vm.fullMode && !isFocused,
                  required: "",
                },
                on: {
                  input: handleInput,
                  focus: handleFocus,
                  blur: handleBlur,
                },
                nativeOn: {
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return handleKeydownArrowUp.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return handleKeydownArrowDown.apply(null, arguments)
                    },
                  ],
                },
              }),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "base-search__button b-button b-button_red b-button_big",
          class: {
            "base-search__button_full": _vm.fullMode,
            "b-button_appendable": !_vm.fullMode,
          },
          attrs: { type: "submit", "data-role": "none", "aria-label": "Найти" },
        },
        [
          _vm.fullMode
            ? _c("span", {
                staticClass:
                  "ui-text ui-text_button ui-kit-color-bg-gray-0 ui-icon-search ui-icon_fz_big",
              })
            : _c(
                "svg",
                {
                  staticClass:
                    "b-icon b-icon-search ui-text ui-kit-color-bg-gray-0",
                  attrs: { width: "24", height: "24" },
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/static/_v1/pd/svg-sprites/global.svg#icon-search",
                    },
                  }),
                ]
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }