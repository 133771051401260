const ClubPriceList = {
  props: {
    price: {
      type: Number,
      default: null,
      required: false,
    },
    fullPrice: {
      type: Number,
      default: null,
      required: false,
    },
    clubLpuPrice: {
      type: Number,
      default: null,
      required: false,
    },
    clubCouponPrice: {
      type: Number,
      default: null,
      required: false,
    },
    cardPrice: {
      type: [String, Number],
      default: null,
      required: false,
    },
    totalPrice: {
      type: [String, Number],
      default: null,
      required: false,
    },
  },
  computed: {
    /**
         * У пользователя есть 3 способа использовать возможности клуба:
         *      0. "isFree" - значит пользователь записывается с помощью клубного таба впервые - он пользуется возможностями клуба бесплатно(в течение "LIFETIME_MONTHS")
         *      1. "withoutCard" - значит период "LIFETIME_MONTHS" закончился - время покупать карту.
         *      С этим состоянием пользователь должен приобрести карту(поэтому он платит половину стоимости приема + стоимость карты)
         *      2. "withCard" - значит пользователь уже купил карту и пользуется ее возможностями
         * Поле "cardPrice" содержит информацию о карте:
         *      0. Значение 0 - "isFree"
         *      1. Любое число, кроме 0 - "withoutCard"
         *      2. None - "withCard"
         * */
    clubPrivilegeCurrentUserIsFree() {
      return this.cardPriceData !== null && this.cardPriceData !== 'None' && Number(this.cardPriceData) === 0
    },
    clubPrivilegeCurrentUserWithCard() {
      return !!((this.cardPriceData === null || this.cardPriceData === 'None') && (this.isClubRegistration || this.dateClubRegistration))
    },
    clubPrivilegeCurrentUserWithoutCard() {
      return this.cardPriceData !== null && this.cardPriceData !== 'None' && Number(this.cardPriceData) !== 0
    },
    cardPriceData() {
      if ((this.cardPrice && this.cardPrice !== 'None') || typeof this.cardPrice === 'number') {
        return this.cardPrice
      }

      return this.couponRequest.cardPrice
    },
  },
}

export default ClubPriceList
