/**
 * Возвращает true - если переданный аргумент `element` являет DOM элементом (HTMLElement)
 * */

function isElement(element) {
  return (
    typeof HTMLElement === 'object'
      ? element instanceof HTMLElement
      : element && typeof element === 'object' && element.nodeType === 1 && typeof element.nodeName === 'string'
  )
}

export default isElement
