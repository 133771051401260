<template>
  <component
    :is="wrapperComponent"
    v-bind="{
      ...defaultProps,
      ...$attrs,
    }"
    :value="isVisible"
    v-on="$listeners"
    @input="emitChangeVisible"
  >
    <template #activator="props">
      <slot
        name="activator"
        v-bind="props"
      />
    </template>
    <slot />
  </component>
</template>

<script>
import { VBottomSheet, VDialog } from 'vuetify/lib'

export default {
  name: 'DialogBottomSheet',
  components: {
    VBottomSheet,
    VDialog,
  },
  inheritAttrs: false,
  model: {
    prop: 'isVisible',
    event: 'dialog-bottom-sheet:change-visible',
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    wrapperComponent() {
      return this.isMobile ? 'VBottomSheet' : 'VDialog'
    },
    defaultProps() {
      if (this.isMobile) {
        return {}
      }

      return {
        width: 456, // Средняя ширина модалки. Значение взято из ui-kit (см. страницу макета Dialogs)
      }
    },
  },
  methods: {
    emitChangeVisible(value) {
      this.$emit('dialog-bottom-sheet:change-visible', value)
    },
  },
}
</script>
