var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "authorization-check-code" }, [
    _vm.checkCodeInputLabel
      ? _c(
          "div",
          { staticClass: "ui-text ui-text_body-2 ui-kit-color-text mb-5" },
          [
            _c("span", { staticClass: "text-pre-wrap" }, [
              _vm._v(_vm._s(_vm.checkCodeInputLabel)),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCodeInputVisible,
            expression: "isCodeInputVisible",
          },
        ],
        attrs: { "data-qa": "user_authorization_code_input" },
      },
      [
        _c("VOtpInput", {
          ref: "authorization-otp-input",
          staticClass: "authorization-check-code__otp-input",
          attrs: {
            disabled: _vm.requestCheckCodeData.isLoading,
            error: !!_vm.requestCheckCodeData.errorMessage,
            length: "4",
            type: "number",
            pattern: "[0-9]",
            inputmode: "numeric",
          },
          on: { finish: _vm.handleCheckCode },
          model: {
            value: _vm.requestCheckCodeData.code,
            callback: function ($$v) {
              _vm.$set(_vm.requestCheckCodeData, "code", $$v)
            },
            expression: "requestCheckCodeData.code",
          },
        }),
        _vm._v(" "),
        _c("VProgressLinear", {
          class: {
            "authorization-check-code__progress": true,
            "authorization-check-code__progress_hidden":
              !_vm.requestCheckCodeData.isLoading,
          },
          attrs: { active: "", rounded: "", height: "2", indeterminate: "" },
        }),
        _vm._v(" "),
        _c("VExpandTransition", [
          _vm.requestCheckCodeData.errorMessage
            ? _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_caption ui-kit-color-secondary text-center px-3",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.requestCheckCodeData.errorMessage) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }