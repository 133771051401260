var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-direction-column align-items-center justify-content-center",
      style: _vm.styleProps,
      attrs: { "data-qa": "card_getting_preloader" },
    },
    [
      _c("VProgressCircular", {
        staticClass: "ui-text ui-kit-color-primary d-block mx-auto",
        attrs: {
          indeterminate: "",
          size: _vm.componentData.circleSize,
          width: _vm.componentData.circleWidth,
          "data-qa": "card_getting_preloader_progress",
        },
      }),
      _vm._v(" "),
      _vm.useText
        ? _c(
            "div",
            {
              staticClass: "ui-text ui-text_body-1 ui-kit-color-text mt-6",
              attrs: { "data-qa": "card_getting_preloader_text" },
            },
            [_vm._v("\n    Проверяем...\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }