/**
 * @description
 *
 * Переключает класс у контейнера с текстом, который обрезает текст и добавляет многоточие
 *
 * @param { Object } options
 * @param { String } options.containerSelector - селектор контейнеров
 * @param { String } options.textSelector - селектор текстового узла внутри containerSelector
 * @param { Number } options.limitHeight - ограничение высоты
 * @param { String } options.notLimitedClass - переключаемый класс
 */

function switchClassEllipsis(options = {}) {
  const {
    containerSelector,
    textSelector,
    limitHeight,
    notLimitedClass,
  } = options
  const containerElements = [...document.querySelectorAll(containerSelector)]

  containerElements.forEach(container => {
    const textElement = container.querySelector(textSelector)

    if (!textElement) {
      return
    }

    const method = textElement.scrollHeight <= limitHeight ? 'add' : 'remove'

    container.classList[method](notLimitedClass)
  })
}

export default switchClassEllipsis
