/**
 * @description
 *
 * Трансформирует объект в `чистый` объект, т.е удаляет
 * в объекте, и во вложенных объектах, ключи у которых значения `undefined`, `null` или `""`(пустая строка - опционально)
 *
 * Inspired by https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
 *
 *
 * @example
 * transformObjectToClean({ a: 1, b: null, c: undefined, d: '' })
 * >>>: { a: 1, d: '' }
 *
 * transformObjectToClean({ a: 1, b: null, c: undefined, d: '' }, true)
 * >>>: { a: 1 }
 *
 * transformObjectToClean({ a: 1, b: { c: null }, d: { e: ''}, f: {} }, true)
 * >>>: { a: 1, b: {}, d: {}, f: {} }
 *
 *
 * @params { Object } object - объект, в котором необходимо произвести трансформацию
 * @params { Boolean } isCleanEmptyString - флаг, указывающий на то, нужно ли удалять ключи с пустой строкой
 *
 * @return { Object }
 * */

function transformObjectToClean(obj = {}, isCleanEmptyString = false) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => {
        if (isCleanEmptyString) {
          return v != null && v !== ''
        }

        return v != null
      })
      .map(([k, v]) => [k, v === Object(v) ? transformObjectToClean(v, isCleanEmptyString) : v]),
  )
}

export default transformObjectToClean
