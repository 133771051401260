import {
  deepMerge,
  testRequiredOptions,
  createDevLog,
  isJSON,
} from 'utils'
import {
  HEARTBEAT,
  HOOKS,
  MODULE_NAME,
  WS_STATES,
} from 'modules/Sockets/constants'
import { calculateReconnectionTimeout } from 'modules/Sockets/functions'

class Sockets {
  constructor(options = {}) {
    this._createOptions(options)

    const isRequiredOptionsSpecified = this._testRequiredOptions()

    if (!isRequiredOptionsSpecified) {
      return
    }

    this._createProps()
    this._connect()
  }

  close() {
    if (this._isStateClosingOrClosed) {
      return
    }

    this._shouldTryToReconnect = false
    this._shouldSendHeartbeat = false

    this.ws.close()

    this._closeHandler()
    this._changeListeners('remove')
  }

  _createProps() {
    this.ws = {}

    this._shouldTryToReconnect = true
    this._isReconnectionTimeoutSet = false
    this._numberOfRetries = 0

    this._shouldSendHeartbeat = true
    this._lostHeartbeats = 0
  }

  _createOptions(options) {
    this.options = deepMerge({
      url: '',
      messageHandler: () => {},
      heartbeat: {
        message: HEARTBEAT.message,
        interval: HEARTBEAT.interval,
        maxLostAllowed: HEARTBEAT.maxLostAllowed,
      },
      hooks: {
        [HOOKS.beforeConnect]: () => {},
        [HOOKS.connected]: () => {},
        [HOOKS.disconnected]: () => {},
        [HOOKS.errorConnection]: () => {},
      },
    }, options)
  }

  _testRequiredOptions() {
    const { url, messageHandler } = this.options

    return testRequiredOptions({
      module: 'Sockets',
      requiredOptions: {
        url,
        messageHandler,
      },
    })
  }

  _connect() {
    if (this._isStateConnectingOrOpen) {
      this._log('Подключение устанавливается или уже установлено')
      return
    }

    this._callHook(HOOKS.beforeConnect)

    try {
      this._log(`Попытка подключения к ${this.options.url}`)

      this.ws = new WebSocket(this.options.url)

      this._changeListeners('add')
    } catch (error) {
      this._log(`Ошибка подключения: ${error.message}`)
      this._tryToReconnect()
    }
  }

  _changeListeners(action) {
    const method = `${action}EventListener`

    this.ws[method]('message', this._messageHandler)
    this.ws[method]('open', this._openHandler)
    this.ws[method]('close', this._closeHandler)
    this.ws[method]('error', this._errorHandler)
  }

  _messageHandler = event => {
    const { data: message } = event

    if (message === this.options.heartbeat.message) {
      this._lostHeartbeats = 0
      return
    }

    let messageParsed = {}

    if (isJSON(message)) {
      messageParsed = JSON.parse(message)
    } else {
      this._log('Сообщение не является строкой в формате JSON')
    }

    this.options.messageHandler({ message, messageParsed, event })
  }

  _openHandler = () => {
    this._log('Подключено')

    this._numberOfRetries = 0
    this._lostHeartbeats = 0
    this._shouldSendHeartbeat = true

    this._heartbeat()
    this._callHook(HOOKS.connected)
  }

  _closeHandler = () => {
    this._log('Соединение закрыто')

    this._shouldSendHeartbeat = false

    this._callHook(HOOKS.disconnected)
    this._tryToReconnect()
  }

  _errorHandler = error => {
    this._callHook(HOOKS.errorConnection, error)

    this._log('Ошибка во время работы: вероятнее всего, произошёл разрыв соединения')
  }

  _tryToReconnect() {
    if (!this._shouldTryToReconnect || this._isReconnectionTimeoutSet) {
      return
    }

    this._log('Переподключение...')

    this._numberOfRetries++

    const reconnectionTimeout = calculateReconnectionTimeout(this._numberOfRetries)

    setTimeout(() => {
      this._isReconnectionTimeoutSet = false
      this._connect()
    }, reconnectionTimeout)

    this._isReconnectionTimeoutSet = true
  }

  _heartbeat = () => {
    if (!this._shouldSendHeartbeat) {
      return
    }

    try {
      this._lostHeartbeats++
      this._checkHeartbeats()
      this.ws.send(this.options.heartbeat.message)

      setTimeout(this._heartbeat, this.options.heartbeat.interval)
    } catch (error) {
      this._log(`Соединение закрывается. Причина: ${error.message}`)

      if (!this._isStateClosingOrClosed) {
        this.ws.close()
      }
    }
  }

  _checkHeartbeats() {
    if (this._lostHeartbeats > this.options.heartbeat.maxLostAllowed) {
      throw new Error('Отправлено слишком много heartbeat-сообщений без получения ответа')
    }
  }

  _callHook(hookName, payload) {
    const hook = this.options.hooks[hookName]

    if (hook && typeof hook === 'function') {
      hook(payload)
    }
  }

  _log(message) {
    createDevLog({ module: MODULE_NAME, message })
  }

  get _isStateConnectingOrOpen() {
    return this.ws.readyState === WS_STATES.connecting
            || this.ws.readyState === WS_STATES.open
  }

  get _isStateClosingOrClosed() {
    return this.ws.readyState === WS_STATES.closing
            || this.ws.readyState === WS_STATES.closed
  }
}

export default Sockets
