export const NAMESPACE = {
  mountSelector: '#vue-club-card-getting',
}

export const steps = {
  confirmation: 0,
  preloaderText: 1,
  clubEnter: 2,
  emailEnter: 3,
  preloaderOnly: 4,
  paymentMade: 5,
  error: 6,
}

export const PAYMENT_STATUSES = {
  // backend statuses
  paid: 'paid', // Оплачен
  canceled: 'canceled', // Отменен
  inProcess: 'in_process', // В процессе
  paymentFailed: 'payment_failed', // Платеж не удался
  expired: 'expired', // Истекло время оплаты (20 мин)
}

export const ALFA_BANK_PAYMENT_HOST_NAME = 'rbsuat.com' // https://web.rbsuat.com/ab || https://alfa.rbsuat.com/payment
