import { PAYMENT_STATUSES } from 'components/common/Club/constants'

const ClubCommonData = {
  data: () => ({
    fullCardPrice: 499,
    isMobileDevice: window.MOBILE_VERSION,
    paymentStatuses: { ...PAYMENT_STATUSES },
  }),
}

export default ClubCommonData
