/**
 * @description
 *
 * Проверка объекта на пустоту
 *
 * @param { Object } object - объект для проверки
 *
 * @return { Boolean } - возвращает true, если объект пуст
 * */

function isEmptyObject(object) {
  return typeof object === 'object' ? !Object.keys(object).length : false
}

export default isEmptyObject
