/**
 * Ведет себя аналогично setTimeout, за исключением того,
 * что по возможности использует requestAnimationFrame() для повышения производительности.
 *
 * @param { Function } fn - callback-функция
 * @param { Number } delay - Задержка в миллисекундах
 *
 * @return { Function } - Функция для отмены таймера
 */

function requestTimeout(fn = () => {}, delay = 0) {
  let requestID = null

  if (!window.requestAnimationFrame) {
    requestID = window.setTimeout(fn, delay)

    return () => {
      clearTimeout(requestID)
    }
  }

  const start = new Date().getTime()

  function loop(...args) {
    const current = new Date().getTime()
    const delta = current - start

    if (delta >= delay) {
      fn(...args)
    } else {
      requestID = requestAnimationFrame(loop)
    }
  }

  requestID = requestAnimationFrame(loop)

  return () => {
    cancelAnimationFrame(requestID)
  }
}

export default requestTimeout
