var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "root",
      staticClass: "search-site",
      class: { "search-site_focused": _vm.isFocused },
      attrs: { "data-qa": "search_site_form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmitForm.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "search-site__input-container",
          attrs: { "data-qa": "search_site_input_container" },
        },
        [
          _vm._t(
            "input-control",
            function () {
              return [
                _c("VTextField", {
                  attrs: {
                    dense: "",
                    outlined: "",
                    "full-width": "",
                    "hide-details": "",
                    clearable: "",
                    "clear-icon": "ui-icon-close-not-a-circle",
                    "prepend-inner-icon": "ui-icon-arrow-back",
                    value: _vm.inputValue,
                    placeholder: _vm.placeholder,
                  },
                  on: {
                    blur: _vm.handleBlur,
                    focus: _vm.handleFocus,
                    input: _vm.handleInput,
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "up", 38, $event.key, [
                            "Up",
                            "ArrowUp",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _vm.handleKeydownArrowUp.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _vm.handleKeydownArrowDown.apply(null, arguments)
                      },
                    ],
                    "click:clear": _vm.handleInput,
                  },
                }),
              ]
            },
            null,
            {
              isFocused: _vm.isFocused,
              inputValue: _vm.inputValue,
              placeholder: _vm.placeholder,
              handleBlur: _vm.handleBlur,
              handleFocus: _vm.handleFocus,
              handleInput: _vm.handleInput,
              handleKeydownArrowUp: _vm.handleKeydownArrowUp,
              handleKeydownArrowDown: _vm.handleKeydownArrowDown,
              handleSubmitForm: _vm.handleSubmitForm,
            }
          ),
          _vm._v(" "),
          _vm.isShowMenu
            ? _c("SearchSiteDropdown", {
                attrs: {
                  "search-query": _vm.searchQueryForDropdown,
                  "search-results": _vm.searchResults,
                  "preview-text": _vm.previewText,
                  location: _vm.location,
                  "trigger-keydown-input": _vm.triggerKeydownInput,
                  "data-qa": "search_site_dropdown",
                },
                on: {
                  "dropdown:selected-item": _vm.handleSelectedItem,
                  "dropdown:mounted": _vm.handleDropdownMounted,
                  "search-site:submit-form": function ($event) {
                    return _vm.$emit("search-site:submit-form")
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }