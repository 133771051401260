import createDevNotice from 'utils/executors/createDevNotice'
import testRequiredOptions from 'utils/withDependences/testRequiredOptions'

/**
 * Возвращает узлы, в которых необходимо обрезать текст.
 * @param {HTMLElement|string} target
 * @returns {HTMLElement[]}
 */

const DEFAULT_TRUNC_CHAR = '...'
const getNodesToShave = target => (
  typeof target === 'string' ? [...document.querySelectorAll(target)] : [target]
)

/**
 * Получает максимальное количество слов в тексте.
 * @param {string[]} words
 * @param {HTMLElement} node
 * @param {number} maxHeight
 * @returns {number}
 */

const getMaxAmountOfWords = (words, node, maxHeight) => {
  let result = words.length - 1
  let min = 0
  let pivot

  while (min < result) {
    pivot = (min + result + 1) >> 1 // eslint-disable-line no-bitwise

    node.textContent = words.slice(0, pivot).join(' ') + DEFAULT_TRUNC_CHAR

    if (node.offsetHeight > maxHeight) {
      result = pivot - 1
    } else {
      min = pivot
    }
  }

  return result
}

/**
 * Возвращает укороченный текст.
 * @param {string[]} words
 * @param {number} maxAmountOfWords
 */

const getShavedText = (words, maxAmountOfWords) => words.slice(0, maxAmountOfWords).join(' ')

/**
 * Обрезает текст у элемента.
 * @param {HTMLElement} node
 * @param {Object} options
 */

const shaveNode = (node, options) => {
  const fullTextNode = node.querySelector(`.${options.customClass}_full`)

  if (fullTextNode) {
    return
  }

  const fullText = node.textContent
  const words = fullText.split(' ')

  if (words.length < 2 || node.offsetHeight <= options.maxHeight) {
    return
  }

  const maxAmountOfWords = getMaxAmountOfWords(words, node, options.maxHeight)
  const shavedText = getShavedText(words, maxAmountOfWords) + DEFAULT_TRUNC_CHAR

  node.innerHTML = `<span class="${options.customClass}_full">${fullText}</span>
                      <span class="${options.customClass}_shaved" aria-hidden="true">${shavedText}</span>`
}

/**
 * Обрезает текст, основываясь на переданной максимальной высоте.
 * @param {Object} options
 * @param {HTMLElement|target} options.target - селектор либо конечный элемент, у которого необходимо обрезать текст
 * @param {number} options.maxHeight - максимальная высота текстового элемента
 * @param {string} options.customClass - модификатор класса для пространства имен
 * @param {boolean=} options.isAsync - `true`, если обрезка текста должна быть асинхронной (отложенной)
 * @description Сделано на основе:
 * @see https://github.com/yowainwright/shave
 */

const toggleTextShave = (options = {}) => {
  testRequiredOptions({
    module: 'toggleTextShave',
    requiredOptions: {
      target: options.target,
      maxHeight: options.maxHeight,
      customClass: options.customClass,
      isAsync: true,
    },
  })

  if (!options.maxHeight) {
    createDevNotice({
      module: 'toggleTextShave',
      description: 'Не передан обязательный параметр maxHeight',
    })
  }

  if (!options.target || !options.maxHeight) {
    return
  }

  getNodesToShave(options.target).forEach(node => {
    if (options.isAsync) {
      setTimeout(() => shaveNode(node, options))
    } else {
      shaveNode(node, options)
    }
  })
}

export default toggleTextShave
