var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-field",
      class: {
        "text-field_active": _vm.isFocused,
        "text-field_gray": _vm.gray,
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-field__body" },
        [
          _c("fieldset", {
            staticClass: "text-field__fieldset",
            class: {
              "text-field__fieldset_no-top": !_vm.borders.top,
              "text-field__fieldset_no-right": !_vm.borders.right,
              "text-field__fieldset_no-bottom": !_vm.borders.bottom,
              "text-field__fieldset_no-left": !_vm.borders.left,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-field__input-slot" }, [
            _c("input", {
              ref: "input",
              staticClass: "text-field__input ui-text ui-text_body-1",
              attrs: {
                type: "text",
                placeholder: _vm.placeholder,
                "data-role": "none",
              },
              domProps: { value: _vm.internalValue },
              on: {
                focus: _vm.handleFocus,
                blur: _vm.handleBlur,
                change: _vm.handleChange,
                input: _vm.handleInput,
              },
            }),
          ]),
          _vm._v(" "),
          _vm._t("append"),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("append-outer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }