import isProd from 'utils/is/isProd'

/**
 * Выводит информационное сообщение в консоли для разработчиков. На проде сообщения не выводятся!
 *
 * @param { Object } options
 * @param { String } options.module - *Обязательный. Название модуля, из которого выводится сообщение в консоль.
 * @param { String } options.title - *Необязательный. Заголовок сообщения.
 * @param { String } options.message - *Обязательный. Сообщение, которое будет отображено в консоли.
 *
 * @example javascript
 * createDevLog({
 *     module: 'YandexGoals',
 *     title: 'Цель:',
 *     message: 'my_custom_goal_name',
 * });
 *
 * Вывод в консоли:
 * >>>: YandexGoals. Цель: my_custom_goal_name
 * */

function createDevLog({
  module = '',
  title = '',
  message = '',
} = {}) {
  if (isProd) {
    return
  }

  const titleFormatted = title.length ? `${title} ` : ''

  // eslint-disable-next-line no-console
  console.log(
    `%c ${module} %c ${titleFormatted}%c${message}`,
    'background-color: #1A5DD0; color: #FFFFFF',
    'background-color: initial; color: initial; font-weight: bold;',
    'font-weight: normal;',
  )
}

export default createDevLog
