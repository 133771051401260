(async () => {
  const mountElement = document.querySelector('.snackbar-notification-2')

  if (!mountElement) {
    return
  }

  const { default: Vue } = await import(/* webpackMode: "eager" */'components')
  const { default: vuetify } = await import(/* webpackMode: "eager" */'modules/vuetify-plugin-without-reset-scss')
  const { default: SnackbarNotification } = await import(/* webpackMode: "eager" */'./SnackbarNotification2')

  new Vue({
    vuetify,
    el: mountElement,
    render: h => h(SnackbarNotification),
  })
})()
