import { getPhoneValidation } from 'utils'

export const PHONE_RULES = Object.values(getPhoneValidation())

export const PHONE_PREFIX = '+7 '

export const PHONE_MASK_OPTIONS = {
  mask: '+7 %## ###-##-##',
  tokens: {
    '%': { pattern: /9/ }, // 9 не должна выводиться, но пользователь может ввести только её
    '#': { pattern: /\d/ },
  },
}

export const WAITING_TIME_RE_REQUEST = 30

export const CONFIRMATION_METHODS = {
  sms: 'sms',
  call: 'call',
  vk: 'vk',
}

export const AUTH_ERRORS = {
  error_code: {
    [CONFIRMATION_METHODS.call]: 'Неверные цифры',
    [CONFIRMATION_METHODS.sms]: 'Неверный СМС код',
    [CONFIRMATION_METHODS.vk]: 'Неверный код',
  },
  obsolete: {
    [CONFIRMATION_METHODS.call]: 'Эти цифры устарели. Повторите запрос',
    [CONFIRMATION_METHODS.sms]: 'Этот код устарел, нужно получить новый',
    [CONFIRMATION_METHODS.vk]: 'Этот код устарел, нужно получить новый',
  },
  bad_code: 'Неверный СМС код',
  wrong_code: 'Неверный СМС код',
  code_expired: 'Лимит вводов превышен',
  invalid: 'Некорректный номер телефона',
  error_limit: 'Достигнут лимит сообщений на заданный номер',
  error_limit_total: 'Отправить новый код можно через сутки',
  error_fields: 'Неверно заполненное поле',
  attempts_out: 'Слишком много попыток. Повторите запрос',
  deleting_in_progress: 'С этого номера пока нельзя записаться на приём или оставить отзыв',
}

export const CONFIRMATION_BUTTON_STATUSES = {
  confirm: 'Подтвердить',
  vkConfirm: 'Получить код ВКонтакте',
  vkConfirmAgain: 'Получить код ВКонтакте повторно',
  callConfirm: 'Подтвердить через звонок',
  callOrSmsConfirm: 'Подтвердить через звонок или СМС',
  callConfirmAgain: 'Позвонить повторно',
  smsConfirm: 'Подтвердить через СМС',
  smsConfirmAgain: 'Отправить СМС повторно',
  pleaseWait: 'Пожалуйста, подождите',
}

export const DIALOG_ERROR_NETWORK_CONFIG = {
  maxWidth: 304,
  title: 'Нет соединения с интернетом',
  text: 'Проверьте соединение и попробуйте снова.',
  closeText: 'Понятно',
}

export const CHECK_CODE_INPUT_LABELS = {
  [CONFIRMATION_METHODS.sms]: 'Введите код из СМС',
  [CONFIRMATION_METHODS.call]: 'Сейчас позвоним вам.\nВведите 4 последние цифры\nвходящего номера.',
  [CONFIRMATION_METHODS.vk]: 'Отправим сообщение с кодом\nподтверждения ВКонтакте',
}

export const CALL_CREATE_REQUEST_ERRORS = {
  invalid: {
    code: 'invalid',
    message: 'Некорректный номер телефона',
  },
  userHasPhone: {
    code: 'user_has_phone',
    message: 'Вы уже авторизованы. Пожалуйста, обновите страницу',
  },
}
