import YaGoals from 'modules/YandexGoals'
import { createDevNotice } from 'utils'

const ManageYandexGoals = {
  data: () => ({
    yaGoalsInstance: new YaGoals(),
    /**
         * yaGoalsList - Объект со списком целей, которые необходимо подключить в
         * каждом компоненте использующий текущий миксин
         *
         * yaGoalsList: {},
         * */
  }),
  methods: {
    sendYandexGoal(goal) {
      if (!this.yaGoalsInstance) {
        this.$_showYandexGoalError('Отсутствует инстанс "this.yaGoalsInstance"')
        return
      }

      if (!goal) {
        this.$_showYandexGoalError('В метод не передан ключ цели')
        return
      }

      const name = this.yaGoalsList[goal]

      if (!name) {
        this.$_showYandexGoalError('Не обнаружено ни одной цели в списке "this.yaGoalsList"')
        return
      }

      this.yaGoalsInstance.send({ name })
    },
    $_showYandexGoalError(description) {
      createDevNotice({
        module: 'mixins/ManageYandexGoals',
        method: 'sendYandexGoal',
        description,
      })
    },
  },
}

export default ManageYandexGoals
