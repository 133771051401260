<template>
  <VForm
    v-model="state.isValidForm"
    data-qa="card_getting_email_enter"
    @submit.prevent="$emit(events.club.cardGettingEmailSent, state.emailValue)"
  >
    <div class="ui-text ui-text_h6 ui-kit-color-text mb-2 text-left">
      Введите Email
    </div>
    <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary mb-4 text-left">
      Вышлем чек
    </div>
    <VTextField
      v-model="state.emailValue"
      :rules="componentData.emailValidationRules"
      :append-icon="componentData.vuetifyIcons.emailIcon"
      label="Email"
      data-qa="card_getting_email_enter_input"
      outlined
    />
    <div class="d-flex justify-content-end">
      <VBtn
        type="submit"
        text
        :disabled="isDisabledSendButton"
        :color="componentData.vuetifyColors.primary"
        data-qa="card_getting_email_enter_submit"
      >
        Отправить
      </VBtn>
    </div>
  </VForm>
</template>

<script>
import { getEmailValidation } from 'utils'
import { eventsData } from 'components/common/Club/components/core/ClubCardGetting/interfaces'

const {
  regex,
  onlyEn,
  required,
  maxLength,
} = getEmailValidation()

export default {
  name: 'ClubCardGettingEmailEnter',
  data: vm => ({
    events: eventsData,
    state: {
      emailValue: '',
      isValidForm: false,
    },
    componentData: {
      vuetifyIcons: vm.$vuetify.icons.values,
      vuetifyColors: vm.$vuetify.theme.themes.light,
      emailValidationRules: [
        required,
        maxLength,
        onlyEn,
        regex,
      ],
    },
  }),
  computed: {
    isDisabledSendButton() {
      return !this.state.isValidForm || !this.state.emailValue.length
    },
  },
}
</script>
