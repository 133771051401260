import Vue from 'components'
import Search from 'components/common/Search/Search'

const mountElement = document.querySelector('.search-placeholder')

if (mountElement) {
  new Vue({
    el: mountElement,
    components: {
      Search,
    },
  })
}
