import createDevNotice from 'utils/executors/createDevNotice'
import dispatchCustomEventGlobally from 'utils/withDependences/dispatchCustomEventGlobally'
import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'

function showCommonErrorDialog(errorEvent, options = {}) {
  const errorData = {
    ...errorEvent.toJSON(),
    stack: undefined,
    config: undefined,
  }

  const errorCode = errorEvent?.response?.status || `${errorData.message}`.replace(/[^\d]/g, '')
  const errorAdditionalText = errorCode ? `Если не сработает, напишите в поддержку, мы поможем.\n\nКод ошибки: ${errorCode}.` : ''

  if (options.sentryModule || options.sentryMethod) {
    createDevNotice({
      module: options.sentryModule,
      method: options.sentryMethod,
      description: JSON.stringify(errorData),
      sentryPayload: errorData,
    })
  }

  dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
    maxWidth: 304,
    title: 'У нас что-то сломалось',
    text: `Попробуйте ещё раз. ${errorAdditionalText}`,
    closeText: 'Понятно',
  })
}

export default showCommonErrorDialog
