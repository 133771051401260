var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapperComponent,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          attrs: { value: _vm.isVisible },
          on: { input: _vm.emitChangeVisible },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (props) {
                  return [_vm._t("activator", null, null, props)]
                },
              },
            ],
            null,
            true
          ),
        },
        "component",
        {
          ..._vm.defaultProps,
          ..._vm.$attrs,
        },
        false
      ),
      _vm.$listeners
    ),
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }