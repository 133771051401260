import createDevNotice from 'utils/executors/createDevNotice'

/**
 * Функция следит за удалением/добавлением в DOM потомков родительского элемента, и вызывает функции из массива
 * коллбэков. Если родитель не найден по селектору, то ничего не происходит, ошибка выбрасываться не должна.
 * @param {Object} options
 * @param {string} options.selectorParent - селектор контейнера, на который навешивается наблюдатель
 * @param {function[]} options.callbacks - массив функций коллбэков
 * @param {Object} options.config - объект конфигурации MutationObserver, по умолчанию "{ childList: true }"
 */
function observeDOM({ selectorParent, callbacks = [], config = { childList: true } }) {
  const targetNode = document.querySelector(selectorParent)

  if (!targetNode) {
    return
  }

  if (!callbacks.length) {
    createDevNotice({
      module: 'observeDOM',
      description: 'В массиве "callbacks" должна быть хотя бы одна функция',
      mode: 'error',
    })

    return
  }

  const callback = () => {
    callbacks.forEach(fn => setTimeout(fn))
  }

  const observer = new MutationObserver(callback)

  observer.observe(targetNode, config)
}

export default observeDOM
