import { getGoalsList } from 'utils'

/** @type {{
  getCodeVk: string
  switchType: string
  limitErrorConfirm: string
  vkCodeInputSuccess: string
  switchTypeAfterProblemVk: string
  callCodeInputSuccess: string
  switchTypeAfterProblemCall: string
}} */
const AUTHORIZATION_VK_GOALS = getGoalsList({
  block: 'authorizationVk',
  goals: [
    'getCodeVk', // Получить код ВКонтакте
    'switchType', // Подтвердить через звонок или СМС. Отказ от ВК
    'limitErrorConfirm', // Не получается подтвердить телефон через ВКонтакте. Подтвердить
    'vkCodeInputSuccess', // Ввёл код ВКонтакте
    'switchTypeAfterProblemVk', // Подтвердить через звонок или СМС. Проблема с кодом ВК, переход на звонок
    'callCodeInputSuccess', // Ввел последние цифры номера телефона входящего звонка. Успешный дозвон
    'switchTypeAfterProblemCall', // Подтвердить через СМС. Проблема с дозвоном, переход на СМС подтверждение
  ],
})

export default AUTHORIZATION_VK_GOALS
