<template>
  <div
    :class="{ 'text-center': !isMobile }"
    data-qa="user_authorization_phone_to_call"
  >
    <div class="ui-text ui-text_body-1 ui-kit-color-text mb-4">
      Для подтверждения позвоните нам.
      Вам не придётся ничего говорить: система сама завершит звонок.
    </div>
    <VBtn
      v-if="isMobile"
      block
      class="mb-4"
      elevation="0"
      color="primary"
      href="tel:88005000066"
      data-qa="user_authorization_phone_to_call_button"
    >
      Позвонить 8 800 500-00-66
    </VBtn>
    <div
      v-else
      class="ui-text ui-text_h4 ui-kit-color-text mb-2"
      data-qa="user_authorization_phone_to_call_number"
    >
      8 800 500-00-66
    </div>
    <div class="ui-text ui-text_body-1 ui-kit-color-text text-center">
      Звонок бесплатный
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAuthorizationByUserCallContent',
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
}
</script>
