import createDevNotice from 'utils/executors/createDevNotice'

/**
 * @param { Object } options
 * @param { String } options.module - название модуля, в котором происходит проверка
 * @param { Object } options.requiredOptions - список полей, которые необходимо проверить
 * @param { String } [options.mode] - режим работы(warn, error, throw)
 * */

function testRequiredOptions({ module, requiredOptions, mode = 'error' } = {}) {
  return Object.entries(requiredOptions)
    .every(([key, value]) => {
      if (value === null || value === undefined) {
        createDevNotice({
          mode,
          module,
          description: `Отсутствует обязательное поле "${key}"`,
        })

        return false
      }

      return true
    })
}

export default testRequiredOptions
