import { axiosClient, getCSRFToken } from 'utils'

/**
 * Создаёт бесплатную клубную карту
 * (если пользователь впервые в клубе, и он авторизован)
 *
 * @example
 * response = {
 *     id: 66577,
 *     dt_start: '06.02.2023',
 *     dt_end: '06.08.2023',
 *     mt_link: '#',
 * }
 *
 * @returns {Promise}
 * */

function createFreeClubCard() {
  return new Promise((resolve, reject) => {
    axiosClient({
      url: '/club/ajax/card/',
      method: 'POST',
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => resolve(data))
      .catch(({ response }) => reject(response))
  })
}

export default createFreeClubCard
