import { getGoalsList } from 'utils'

const AWARD_YANDEX_GOALS = getGoalsList({
  block: 'award',
  goals: [
    /* Desktop */
    'bannerClose', // Клик по кнопке "Закрыть"
    'bannerShowMore', // Клик по кнопке "Подробнее" - для вызова модалки с дополнительной информацией
    'modalClickRedirect', // Клик по кнопке "Зарегистрироваться" - для регистрации на онлайн-трансляцию
  ],
})

export default AWARD_YANDEX_GOALS
