/**
 * @description
 *
 * Transition функция - ускоряется до половины, потом замедляется.
 *
 * @param { Number } time - текущее время
 * @param { Number } start - позиция начала скролла
 * @param { Number } change - разница между координатой начала скроллинга и координатой окончания скроллинга
 * @param { Number } duration - продолжительность анимации
 *
 * @return { Number | String }
 */

function easeInOutQuad(time, start, change, duration) {
  let newTime = time / (duration / 2)

  if (newTime < 1) {
    return (change / 2) * newTime * newTime + start
  }

  newTime -= 1

  return (-change / 2) * (newTime * (newTime - 2) - 1) + start
}

/**
 * @description
 *
 * Скроллит страницу к указанному значению от верхнего края документа или родителя (element).
 *
 * @param { Number } to - до какой позиции производить скролл
 * @param { Object } options
 * @param { Number } options.offset - отступ сверху
 * @param { Number } options.duration - продолжительность анимации
 * @param { String= } options.dataAttr - название атрибута, который будет вешаться на body во время скролла (может понадобиться для корректной работы автотестов)
 * @param { HTMLElement } element - элемент, относительно которого производится скролл. По умолчанию относительно документа
 */

function scrollTo(to = 0, options = {}, element = undefined) {
  const {
    offset = 0,
    duration = 600,
    dataAttr = '',
  } = options
  const toWithOffset = to + offset
  const node = !element ? document.scrollingElement || document.documentElement : element
  const start = node.scrollTop
  const change = toWithOffset - start
  const startDate = new Date().getTime()

  if (dataAttr) {
    document.body.setAttribute(dataAttr, '')
  }

  function animateScroll() {
    const currentDate = new Date().getTime()
    const currentTime = currentDate - startDate

    node.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10)

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll)

      return
    }

    node.scrollTop = toWithOffset

    if (dataAttr) {
      document.body.removeAttribute(dataAttr)
    }
  }

  animateScroll()
}

export default scrollTo
