var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TheHeaderMobile", {
    attrs: {
      "site-logo": _vm.siteLogo,
      search: _vm.search,
      "data-qa": "the_header",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }