function getValidators() {
  return {
    required: message => val => !!val || message,
    containsLettersAndDigits: message => val => (/[A-z]/.test(val) && /\d/.test(val)) || message,
    minLength: length => val => val.length >= length || `Должно быть не меньше ${length} символов`,
    maxLength: length => val => val.length <= length || `Должно быть не больше ${length} символов`,
  }
}

export default getValidators
