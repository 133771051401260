/**
 * Возвращет длину найденных узлов на странице
 *
 * @param { Object } options - параметры
 * @param { String } options.selector - селектор, по которому будет осуществляться поиск элементов
 * @return { Number }
 * */

function getNodesLength({ selector }) {
  return document.querySelectorAll(selector).length
}

export default getNodesLength
