const mountElement = document.querySelector('.base-dialog')

if (mountElement) {
  (async () => {
    const { default: Vue } = await import(/* webpackMode: "eager" */'components')
    const { default: vuetify } = await import(/* webpackMode: "eager" */'modules/vuetify-plugin-without-reset-scss')
    const { default: BaseDialog } = await import(/* webpackMode: "eager" */'./BaseDialog')

    new Vue({
      vuetify,
      el: mountElement,
      components: {
        BaseDialog,
      },
    })
  })()
}
