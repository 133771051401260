/**
 * @description
 *
 * Возвращает проскролленное значение сверху и слева
 *
 * @return { Object } - данные прокрутки
 * */

function getScrollCoords() {
  const distance = document.scrollingElement || document.documentElement

  return {
    top: distance.scrollTop,
    left: distance.scrollLeft,
  }
}

export default getScrollCoords
